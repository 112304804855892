import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

const options: IDropdownOptions[] = [
    {
        optionValue: 'lowestPayment',
        optionLabel: 'Lowest Payment'
    },
    {
        optionValue: 'mostMarkup',
        optionLabel: 'Most Mark-up'
    }
];

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const consumerFacingCreditDecisionType = value;
        const newDealer = {
            ...oldDealer,
            consumerFacingCreditDecisionType
        };
        updateDealerCallback(null, newDealer);
    };
}

const ConsumerFacingCreditDecisionType: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = props.dealer.consumerFacingCreditDecisionType || options[0].optionValue;
    const isDisabled = !props.dealer.isConsumerFacingCreditDecisionEnabled;
    const tooltip =
        // eslint-disable-next-line max-len
        'Display a consumer-facing decision with the lowest payment or most markup to the user in AMD and Sales View. This functionality is disabled when the consumer-facing credit decision toggle is disabled.';

    return (
        <DR3DropdownField
            label={ToggleLabelMap.consumerFacingCreditDecisionType}
            value={value}
            disabled={isDisabled}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={options}
            tooltip={tooltip}
        />
    );
};
export default ConsumerFacingCreditDecisionType;
