import { IDealerLeasing, IHandleUpdateFieldChange, IDealerTerms, IHandleFieldChange } from '../../store/manager/types';
import { Column, Options } from 'material-table';
import React, { FunctionComponent } from 'react';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { tableIcons } from './utils';
import { DR3TableWrapper } from './DR3TableWrapper';

interface IDR3LeasingMonthlyTermsTableProps {
    leasingData: IDealerLeasing;
    onUpdate: IHandleUpdateFieldChange<IDealerTerms>;
    onAdd: IHandleFieldChange<IDealerTerms>;
    onDelete: IHandleFieldChange<IDealerTerms>;
    label: string;
    columns: Column<IDealerTerms>[];
    tableOptions: Options<IDealerTerms>;
}
export const DR3LeasingMonthlyTermsTable: FunctionComponent<IDR3LeasingMonthlyTermsTableProps> = (props) => {
    return (
        <DR3FieldWrapper label={props.label} leasingData={props.leasingData}>
            <DR3TableWrapper
                options={props.tableOptions}
                icons={tableIcons}
                title={props.label}
                columns={props.columns}
                data={props.leasingData.terms}
                onUpdate={props.onUpdate}
                onAdd={props.onAdd}
                onDelete={props.onDelete}
            />
        </DR3FieldWrapper>
    );
};
