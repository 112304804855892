import React, { FunctionComponent, useEffect, useState } from 'react';
import { IClientContainerProps } from '../types';
import { FormHelperText } from '@material-ui/core';
import { DR3FieldWrapper } from '../../common/DR3FieldWrapper';
import { ValidationTextField } from '../../common/ValidationTextField';
import useStyles from '../../layout/styles';

const Url: FunctionComponent<IClientContainerProps> = (props) => {
    const classes = useStyles({});
    const [urlValue, setUrl] = useState('');

    useEffect(() => {
        setUrl(props.client.webhooks && props.client.webhooks.length > 0 ? props.client.webhooks[0].url : '');
    }, [props.client]);

    const handleChange = () => {
        const newValue: any = props.client.webhooks && props.client.webhooks.length > 0 ? { ...props.client.webhooks[0] } : {};
        newValue.url = urlValue;
        const newClient = {
            ...props.client,
            webhooks: [{ ...newValue }]
        };

        props.setClient(newClient);
    };
    const errorText = '';
    return (
        <DR3FieldWrapper label={'URL'}>
            <ValidationTextField
                error={!!errorText}
                variant="outlined"
                className={classes.textField}
                required={false}
                value={urlValue}
                onChange={(event) => {
                    setUrl(event.target.value);
                }}
                onBlur={handleChange}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
export default Url;
