/** A place for common utilities used throughout the application */

const validateEmail = (email: string): boolean => {
    const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(email);
};

export const commonUtils = {
    validateEmail
};
