import React, { FunctionComponent } from 'react';
import { IHandleFieldChange } from '../../store/manager/types';
import { validateIsNumber } from './utils';
import useStyles from '../layout/styles';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { ValidationTextField } from './ValidationTextField';
import { FormHelperText } from '@material-ui/core';

interface IDR3NumberTextFieldProps {
    label: string;
    sublabel?: string;
    value: number;
    handleChange: IHandleFieldChange<number>;
    maxLength?: number;
    errorText?: string;
    isRequired?: boolean;
    tooltip?: string;
    withMargin?: boolean;
    disabled?: boolean;
}
// fields that accept only integers and save a number value
export const DR3NumberTextField: FunctionComponent<IDR3NumberTextFieldProps> = (props) => {
    function handleChange(event: React.ChangeEvent<unknown>) {
        let value = (event.target as HTMLInputElement).value;
        if (value === '') {
            value = '0';
        }
        const isValid = validateIsNumber(value, props.maxLength);
        if (isValid) {
            const numberValue = parseInt(value, 10);
            return props.handleChange(numberValue);
        } else {
            return null;
        }
    }
    const classes = useStyles({});
    const error = !!props.errorText;
    const required = !!props.isRequired;
    const withMargin = props.withMargin ? classes.fieldWrapperWithMargin : classes.fieldWrapperWithoutMargin;
    return (
        <DR3FieldWrapper label={props.label} sublabel={props.sublabel} tooltip={props.tooltip} className={withMargin}>
            <div>
                <ValidationTextField
                    variant="outlined"
                    className={classes.textField}
                    error={error}
                    required={required}
                    value={props.value}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                <FormHelperText>{props.errorText}</FormHelperText>
            </div>
        </DR3FieldWrapper>
    );
};
