import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { formatPhoneNumber, validatePhoneNumber } from '../common/utils';
import { DR3TextField } from '../common/DR3TextField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        value = formatPhoneNumber(value);
        const trackingPhone = value;
        const contactOptions = {
            ...oldDealer.listingOverride.contactOptions,
            trackingPhone
        };
        const listingOverride = {
            ...oldDealer.listingOverride,
            contactOptions
        };
        updateDealerCallback(null, {
            ...oldDealer,
            listingOverride
        });
    };
}
const DealerAdminOptionsPhoneTrackingNumber: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.listingOverride.contactOptions.isPhoneEnabled) {
        return null;
    }
    let value = props.dealer.listingOverride.contactOptions.trackingPhone;
    let errorText = '';
    value = formatPhoneNumber(value);
    const valid = validatePhoneNumber(value, false);
    valid ? (errorText = '') : (errorText = 'Phone number must be 10 digits.');
    return (
        <DR3TextField
            label="Phone Tracking Number"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            errorText={errorText}
        />
    );
};
export default DealerAdminOptionsPhoneTrackingNumber;
