import React, { FunctionComponent } from 'react';
import { IHandleFieldChange } from '../../store/manager/types';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

interface IDR3RadioBooleanFieldProps {
    label: ToggleLabelMap;
    value: boolean;
    trueOption: string;
    falseOption: string;
    handleChange: IHandleFieldChange<boolean>;
    tooltipProps?: string;
    disabled?: boolean;
}

export const DR3RadioBooleanField: FunctionComponent<IDR3RadioBooleanFieldProps> = (props) => {
    const trueValue = props.trueOption.toLowerCase().replace(' ', '-');
    const falseValue = props.falseOption.toLowerCase().replace(' ', '-');
    const selected = props.value ? trueValue : falseValue;
    function handleChange(event: React.ChangeEvent<unknown>) {
        const value = (event.target as HTMLInputElement).value;
        const truthy = value === trueValue;
        return props.handleChange(truthy);
    }
    return (
        <DR3FieldWrapper label={props.label} tooltip={props.tooltipProps}>
            <FormControl component="fieldset">
                <RadioGroup name="position" value={selected} onChange={handleChange} row>
                    <FormControlLabel
                        style={{ marginLeft: '1em', paddingTop: '6px' }}
                        value={falseValue}
                        control={<Radio />}
                        label={props.falseOption}
                        labelPlacement="end"
                        disabled={props.disabled}
                    />
                    <FormControlLabel
                        style={{ marginLeft: '1em', paddingTop: '6px' }}
                        value={trueValue}
                        control={<Radio />}
                        label={props.trueOption}
                        labelPlacement="end"
                        disabled={props.disabled}
                    />
                </RadioGroup>
            </FormControl>
        </DR3FieldWrapper>
    );
};
