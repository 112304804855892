import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const newDealer = {
            ...oldDealer,
            isAccelerateCreditAppEnabled: value
        };
        updateDealerCallback(null, newDealer);
    };
}

const IsAccelerateCreditAppEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const isRedesignEnabled = !!props.dealer.listingOverride.isRedesignEnabled || !!props.dealer.isRedesignEnabled;

    if (!isRedesignEnabled) {
        return null;
    }

    const value = !!props.dealer.isAccelerateCreditAppEnabled;

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.isAccelerateCreditAppEnabled}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default IsAccelerateCreditAppEnabled;
