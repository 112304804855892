import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { validateURL } from '../common/utils';
import { DR3TextField } from '../common/DR3TextField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const ecommerceURL = value;
        updateDealerCallback(null, {
            ...oldDealer,
            ecommerceURL
        });
    };
}
const EcommerceURL: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.ecommerceURL;
    let errorText = '';
    // this field is not required, and can be left blank
    if (value !== '') {
        const valid = validateURL(value);
        valid ? (errorText = '') : (errorText = 'Invalid URL');
    }

    return (
        <DR3TextField
            label="Ecommerce URL"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            errorText={errorText}
        />
    );
};
export default EcommerceURL;
