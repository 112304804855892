import React, { FunctionComponent, useEffect, useState } from 'react';
import { IClientContainerProps } from '../types';
import { FormHelperText } from '@material-ui/core';
import useStyles from '../../layout/styles';
import { DR3FieldWrapper } from '../../common/DR3FieldWrapper';
import { ValidationTextField } from '../../common/ValidationTextField';

const Password: FunctionComponent<IClientContainerProps> = (props) => {
    const classes = useStyles({});
    const [passwordValue, setPassword] = useState('');

    useEffect(() => {
        setPassword(
            props.client.webhooks && props.client.webhooks.length > 0 && props.client.webhooks[0].basicAuth
                ? props.client.webhooks[0].basicAuth.password
                : ''
        );
    }, [props.client]);

    const handleChange = () => {
        let oldValue: any = props.client.webhooks && props.client.webhooks.length > 0 ? props.client.webhooks[0] : {};
        const newValue = { basicAuth: { password: passwordValue } };
        if (oldValue.basicAuth) {
            oldValue.basicAuth.password = passwordValue;
        } else {
            oldValue = { ...oldValue, ...newValue };
        }
        const newClient = {
            ...props.client,
            webhooks: [oldValue]
        };

        props.setClient(newClient);
    };

    const errorText = '';
    return (
        <DR3FieldWrapper label={'Password'}>
            <ValidationTextField
                error={!!errorText}
                variant="outlined"
                className={classes.textField}
                required={false}
                value={passwordValue}
                onChange={(event) => {
                    setPassword(event.target.value);
                }}
                onBlur={handleChange}
                type="password"
            />
            <FormHelperText>{errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
export default Password;
