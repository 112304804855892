import { Integrator } from '../../store/manager/types';

export const integratorsArrayToObject = (array: Integrator[]) => {
    return array.reduce((previous: any, current: Integrator) => {
        previous[current.integratorId] = current.integratorName;
        return previous;
    }, {});
};

export const getNestedProperty = (obj: any, properties: string): any => {
    if (!obj || !properties) return undefined;

    const arr = properties.split('.');
    const property = arr.shift();
    if (!property) return undefined;
    if (!arr.length) return obj[property];

    return getNestedProperty(obj[property], arr.join('.'));
};
