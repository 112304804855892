import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const tradeInValuationLeadForm = value;
        const newDealer = {
            ...oldDealer,
            tradeInValuationLeadForm
        };
        updateDealerCallback(null, newDealer);
    };
}
const tooltip =
    // eslint-disable-next-line max-len
    'The trade in lead form is the top performing lead form in the DR experience.  Turning this off will have a significant negative impact on deal conversion and the number of leads that the dealer will receive.';

const TradeInValuationLeadForm: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.tradeInValuationLeadForm;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.tradeInValuationLeadForm}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default TradeInValuationLeadForm;
