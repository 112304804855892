import React from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import GlobalFieldsListUI from '../components/GlobalFieldsListUI';
import DealerFieldsListUI from '../components/DealerFieldsListUI';
import ListingFieldsListUI from '../components/ListingFieldsListUI';
import {
    IDealerToManage,
    IValidator,
    IValidatorMap,
    IUploadBrandingExpCallbackFn,
    IUploadBrandingExpResponse,
    PartnerMapping,
    IUpdatePartnerMappingsCallbackFn,
    NewPartnerMapping,
    IdPartnerMapping
} from '../store/manager/types';
import { RootState } from '../store';
import { asDealerToManage } from '../store/manager/selectors';
import { updateDealerToManage, updateValidatorMap, updatePartnerMappings } from '../store/manager/actions';
import { IUpdateDealerCallbackFn, IValidateCallbackFn } from '../store/manager/types';
import GlobalWrapper from '../components/layout/GlobalWrapper';
import { IWithAuthProps } from '../utils/AuthTypes';
import withApi, { IWithApiProps } from '../utils/withApi';
import themeOverride from '../components/layout/theme';
import Snackbar from '@material-ui/core/Snackbar';
import { ThemeProvider } from '@material-ui/styles';
import { Config } from '../config';
import splunkLogger from '../utils/splunkLogger';
import { RouteComponentProps } from 'react-router-dom';
import { IUploadBrandingExpRequest } from '../utils/Api';
import DealerManagerTabs from '../components/common/DR3DealerManagerTabs';
import { cloneDeep } from 'lodash';
import { TabSelected } from '../store/manager/types';
import { AppConstants } from '../constants/AdminConstants';
import { schema } from '../store/manager/schema';
import MySnackbarContentWrapper from './components/MySnackbarContentWrapper';
import { integratorsArrayToObject } from '../components/utilities/Utils';
import { getIdMappings, getIntegratorsForIds } from '../components/utilities/getIdMappings';
import { getFeatureToggles } from '../utils/getFeatureToggles';
let oldDealer: IDealerToManage;

interface IDealerManagerContainerProps extends IWithApiProps, IWithAuthProps {
    dealer: IDealerToManage | null;
    validatorMap: IValidatorMap;
    updateValidatorMap: typeof updateValidatorMap;
    updateDealerToManageAction: typeof updateDealerToManage;
    updatePartnerMappingsAction: typeof updatePartnerMappings;
    partnerMappings: PartnerMapping[];
}

interface IDealerManagerParams {
    dealerId: string;
    admin?: string;
}

type DealerManagerContainerProps = IDealerManagerContainerProps & RouteComponentProps<IDealerManagerParams>;
class DealerManagerContainer extends React.Component<DealerManagerContainerProps, any> {
    constructor(props: DealerManagerContainerProps) {
        super(props);
        this.state = {
            openSuccessAlert: false,
            openFailureAlert: false,
            openValidationFailureAlert: false,
            openPartnerMappingErrorAlert: false,
            openResetListingOverridesAlert: false,
            tabSelected: TabSelected.GLOBAL,
            tabSelectedValue: 0,
            validationErrors: [],
            partenerMappingError: [],
            featureToggles: {}
        };
        // TODO: turn these binds into arrow functions
        this.updateDealerCallback = this.updateDealerCallback.bind(this);
        this.loadDealerToManage = this.loadDealerToManage.bind(this);
        this.handleOpenSuccessAlert = this.handleOpenSuccessAlert.bind(this);
        this.handleCloseSuccessAlert = this.handleCloseSuccessAlert.bind(this);
        this.handleOpenFailureAlert = this.handleOpenFailureAlert.bind(this);
        this.handleCloseFailureAlert = this.handleCloseFailureAlert.bind(this);
        this.handleOpenValidationFailureAlert = this.handleOpenValidationFailureAlert.bind(this);
        this.handleCloseValidationFailureAlert = this.handleCloseValidationFailureAlert.bind(this);
    }

    public handleOpenSuccessAlert() {
        this.setState({ openSuccessAlert: true });
    }

    public handleCloseSuccessAlert() {
        this.setState({ openSuccessAlert: false });
    }

    public handleOpenFailureAlert() {
        this.setState({ openFailureAlert: true });
    }

    public handleOpenValidationFailureAlert() {
        this.setState({ openValidationFailureAlert: true });
    }

    handleOpenPartnerMappingErrorAlert = (message: string[]) => {
        this.setState({ openPartnerMappingErrorAlert: true });
        this.setState({ partenerMappingError: message });
    };

    public handleCloseFailureAlert() {
        this.setState({ openFailureAlert: false });
    }

    public handleCloseValidationFailureAlert() {
        this.setState({ openValidationFailureAlert: false });
    }

    handleClosePartnerMappingErrorAlert = () => {
        this.setState({ openPartnerMappingErrorAlert: false });
    };

    handleOpenResetListingOverridesAlert = (message: string[]) => {
        this.setState({ openResetListingOverridesAlert: true });
        this.setState({ resetListingOverridesAlertMsg: message });
    };

    handleCloseResetListingOverridesAlert = () => {
        this.setState({ openResetListingOverridesAlert: false });
    };

    public validateCallback: IValidateCallbackFn = (validator: IValidator) => {
        this.props.updateValidatorMap(validator);
    };

    public uploadBrandingExp: IUploadBrandingExpCallbackFn = async (
        err: string | null,
        dealerId: IUploadBrandingExpRequest,
        imageList: FileList
    ): Promise<IUploadBrandingExpResponse> => {
        const accessToken = await this.props.auth.getAccessToken();
        let response: IUploadBrandingExpResponse = {
            Bucket: '',
            Key: '',
            Location: ''
        };
        if (accessToken) {
            response = await this.props.api.asyncUploadBrandingExp(dealerId, accessToken, imageList);
        }
        return response;
    };

    public updateDealerCallback: IUpdateDealerCallbackFn = (err, dealer?) => {
        if (err || !dealer) {
            // TODO add error handling
        } else {
            this.props.updateDealerToManageAction(dealer);
        }
    };

    public updatePartnerMappingsCallback: IUpdatePartnerMappingsCallbackFn = (err, partnerMappings) => {
        if (err || !partnerMappings) {
            // TODO add error handling
        } else {
            this.props.updatePartnerMappingsAction(partnerMappings);
        }
    };

    public async componentDidMount() {
        const accessToken = await this.props.auth.getAccessToken();

        if (!accessToken) {
            return;
        }
        try {
            const featureTogglesResponse = await this.props.api.asyncGetFeatureToggles(
                this.props.match.params.dealerId,
                accessToken
            );
            const featureToggles = getFeatureToggles(featureTogglesResponse);
            this.setState({ featureToggles });
        } catch (error) {
            this.setState({ error });
        }

        // read the path parameters using this block of code
        const {
            match: {
                params: { dealerId, admin }
            }
        } = this.props;

        // add params to state so we can use them in rendering
        this.setState({
            params: {
                dealerId,
                admin: admin === AppConstants.ADMIN_MODE || admin === AppConstants.ADMIN_INTERNAL_MODE,
                adminInternal: admin === AppConstants.ADMIN_INTERNAL_MODE
            }
        });

        if (dealerId) {
            try {
                const intDealerId = parseInt(dealerId, 10);
                await this.loadDealerToManage(intDealerId, accessToken);
                await this.loadPartnerMappingsToManage();
                const integratorsList = await this.getIntegratorsListWithIds(accessToken);
                this.setState({ params: { ...this.state.params, integratorsList } });
            } catch (error) {
                this.setState({ error });
            }
        }
    }

    public showGlobalSiteFields() {
        return (
            <div>
                <GlobalFieldsListUI
                    featureToggles={this.state.featureToggles}
                    dealer={this.props.dealer}
                    validatorMap={this.props.validatorMap}
                    updateDealerCallback={this.updateDealerCallback}
                    validateCallback={this.validateCallback}
                    saveDealerCallback={() => null}
                    uploadBrandingExpCallback={this.uploadBrandingExp}
                    admin={this.state.params.admin}
                    adminInternal={this.state.params.adminInternal}
                    dealerFieldsListSelected={this.state.tabSelected === TabSelected.GLOBAL}
                    updatePartnerMappingsCallback={this.updatePartnerMappingsCallback}
                    integrators={this.state.params.integratorsList}
                    partnerMappings={this.props.partnerMappings}
                />
            </div>
        );
    }

    public showDealerSiteFields() {
        return (
            <div>
                <DealerFieldsListUI
                    dealer={this.props.dealer}
                    validatorMap={this.props.validatorMap}
                    updateDealerCallback={this.updateDealerCallback}
                    validateCallback={this.validateCallback}
                    saveDealerCallback={() => null}
                    uploadBrandingExpCallback={this.uploadBrandingExp}
                    admin={this.state.params.admin}
                    dealerFieldsListSelected={this.state.tabSelected === TabSelected.DEALER}
                />
            </div>
        );
    }

    public showListingSiteFields() {
        return (
            <div>
                <ListingFieldsListUI
                    dealer={this.props.dealer}
                    validatorMap={this.props.validatorMap}
                    updateDealerCallback={this.updateDealerCallback}
                    resetListingOverridesCallback={this.handleOpenResetListingOverridesAlert}
                    validateCallback={this.validateCallback}
                    saveDealerCallback={() => null}
                    uploadBrandingExpCallback={this.uploadBrandingExp}
                    admin={this.state.params.admin}
                    dealerFieldsListSelected={this.state.tabSelected === TabSelected.LISTING}
                />
            </div>
        );
    }

    public getSiteFields() {
        const { tabSelected } = this.state;
        if (tabSelected === TabSelected.GLOBAL) {
            return this.showGlobalSiteFields();
        }
        if (tabSelected === TabSelected.DEALER) {
            return this.showDealerSiteFields();
        }
        if (tabSelected === TabSelected.LISTING) {
            return this.showListingSiteFields();
        }
    }

    public render() {
        if (this.state.error) {
            return (
                <div>
                    <h1>{this.state.error.statusCode}</h1>
                    <h2>{this.state.error.message}</h2>
                </div>
            );
        }
        if (!this.props.dealer) {
            return null;
        }
        const dealer: IDealerToManage = this.props.dealer;
        return (
            <div>
                <GlobalWrapper
                    dealer={this.props.dealer}
                    onClickSave={() => this.saveDealer(dealer)}
                    onClickHome={() => this.navigateHome()}
                    isDealerManagerPage={true}
                    tabSelected={this.state.tabSelected}
                >
                    <DealerManagerTabs
                        value={this.state.tabSelectedValue}
                        handleChange={this.handleClickTab}
                        sponsorSetting={this.props.dealer.sponsorSetting}
                    >
                        {this.getSiteFields()}
                    </DealerManagerTabs>
                </GlobalWrapper>

                {/* ALERTS */}
                <ThemeProvider theme={themeOverride}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        open={this.state.openSuccessAlert}
                        autoHideDuration={6000}
                        onClose={this.handleCloseSuccessAlert}
                    >
                        <MySnackbarContentWrapper
                            variant="success"
                            className={'alertSuccess'}
                            message={['Changes saved successfully!']}
                            onClose={this.handleCloseSuccessAlert}
                        />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        open={this.state.openValidationFailureAlert}
                        autoHideDuration={6000}
                        onClose={this.handleCloseValidationFailureAlert}
                    >
                        <MySnackbarContentWrapper
                            variant="error"
                            className={'alertError'}
                            message={this.state.validationErrors}
                            onClose={this.handleCloseValidationFailureAlert}
                        />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        open={this.state.openFailureAlert}
                        autoHideDuration={20000}
                        onClose={this.handleCloseFailureAlert}
                    >
                        <MySnackbarContentWrapper
                            variant="error"
                            className={'alertError'}
                            message={['Error encountered. Please try again.']}
                            onClose={this.handleCloseFailureAlert}
                        />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        open={this.state.openPartnerMappingErrorAlert}
                        autoHideDuration={10000}
                        onClose={this.handleClosePartnerMappingErrorAlert}
                    >
                        <MySnackbarContentWrapper
                            variant="error"
                            className={'alertError mappingError'}
                            message={this.state.partenerMappingError}
                            onClose={this.handleClosePartnerMappingErrorAlert}
                        />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        open={this.state.openResetListingOverridesAlert}
                        autoHideDuration={6000}
                        onClose={this.handleCloseResetListingOverridesAlert}
                    >
                        <MySnackbarContentWrapper
                            variant="success"
                            className={'alertSuccess'}
                            message={[
                                // eslint-disable-next-line max-len
                                'Listing Overrides have been reset to the default Website Settings. Please ensure they are correct and save your changes.'
                            ]}
                            onClose={this.handleCloseResetListingOverridesAlert}
                        />
                    </Snackbar>
                </ThemeProvider>
            </div>
        );
    }

    public loadDealerToManage = async (dealerId: number, accessToken: string): Promise<void> => {
        const request = {
            dealerId
        };
        const response = await this.props.api.asyncGetDealer(request, accessToken);
        const dealerToManage = asDealerToManage(response);
        oldDealer = asDealerToManage(response);
        this.props.updateDealerToManageAction(dealerToManage);
    };

    public loadPartnerMappingsToManage = async (): Promise<void> => {
        const newPartnerMappings = this.transformPartnerMappingsWithIds(this.props.dealer?.partnerMappings ?? []);
        this.props.updatePartnerMappingsAction(newPartnerMappings);
    };

    private transformPartnerMappingsWithIds = (partnerMapping: NewPartnerMapping[]): PartnerMapping[] => {
        const partnerMappings: PartnerMapping[] = getIdMappings(this.props.dealer as Record<keyof IDealerToManage, any>);

        partnerMappings.push(
            ...partnerMapping.map(
                (map): PartnerMapping => {
                    return {
                        integratorId: map.name,
                        partnerDealerId: map.value,
                        status: 'original'
                    };
                }
            )
        );

        return partnerMappings;
    };

    private getIntegratorsListWithIds = async (accessToken: string) => {
        const integratorsList = await this.props.api.asyncGetIntegratorsList(accessToken);

        integratorsList.unshift(...getIntegratorsForIds());
        return integratorsArrayToObject(integratorsList);
    };

    private loadPartnerMappingsToDealer = (dealer: IDealerToManage, partnerMappings: PartnerMapping[]): IDealerToManage => {
        const idPartnerMappings = partnerMappings.filter((mapping) => !!(mapping as IdPartnerMapping).fieldToSave);
        const partnerMappingsToSave = partnerMappings.slice(idPartnerMappings.length);

        return {
            ...dealer,
            partnerMappings: [
                ...partnerMappingsToSave
                    .filter((map) => map.status !== 'deleted')
                    .map((map) => {
                        return {
                            name: map.integratorId,
                            value: map.partnerDealerId
                        };
                    })
            ]
        };
    };

    public saveDealer = async (_dealer: IDealerToManage): Promise<void> => {
        const accessToken = await this.props.auth.getAccessToken();
        const dealer = this.format(cloneDeep(_dealer));
        let response = null;
        if (this.validate(dealer)) {
            if (accessToken) {
                try {
                    const dealerToSave = this.loadPartnerMappingsToDealer(dealer, this.props.partnerMappings);
                    response = await this.props.api.asyncSaveDealer(dealerToSave, accessToken);
                    if (dealer.ownerId) {
                        // oldDealer (current) - Has value changed?.
                        if (
                            dealer.sponsorSetting === 'dealerSite' &&
                            (dealer.products.hasWidgetNew !== oldDealer.products.hasWidgetNew ||
                                dealer.products.hasWidgetUsed !== oldDealer.products.hasWidgetUsed)
                        ) {
                            await this.props.api.asyncSaveDealerATC(dealer, accessToken);
                        }
                        // oldDealer (current) - Has value changed? Look at listing override.
                        if (
                            dealer.sponsorSetting !== 'dealerSite' &&
                            (dealer.listingOverride.products.hasWidgetNew !== oldDealer.listingOverride.products.hasWidgetNew ||
                                dealer.listingOverride.products.hasWidgetUsed !== oldDealer.listingOverride.products.hasWidgetUsed)
                        ) {
                            await this.props.api.asyncSaveDealerATC(dealer, accessToken);
                        }
                    }
                    oldDealer = this.captureOldDealer(dealer);
                } catch (error) {
                    if (error.statusCode === 409 && error.error?.message?.length) {
                        this.handleOpenPartnerMappingErrorAlert(error.error?.message);
                    } else {
                        this.handleOpenFailureAlert();
                    }
                    this.props.updateDealerToManageAction(dealer);
                    return;
                }
            }
            if (response && response.dealerId) {
                this.handleOpenSuccessAlert();
            } else {
                this.handleOpenFailureAlert();
            }
        } else {
            this.handleOpenValidationFailureAlert();
        }
        this.props.updateDealerToManageAction(dealer);
    };

    public captureOldDealer(dealer: IDealerToManage) {
        oldDealer = cloneDeep(dealer);
        return oldDealer;
    }

    public handleClickTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue !== this.state.tabSelectedValue) {
            this.setState({
                tabSelectedValue: newValue,
                tabSelected: newValue
            });
        }
    };

    public navigateHome = () => {
        window.location.replace(Config.services.api.protocol + Config.services.api.host);
    };

    public format(dealer: IDealerToManage) {
        // dealer site fields
        if (dealer.name) {
            dealer.name = dealer.name.trim();
        }
        if (dealer.address.street) {
            dealer.address.street = dealer.address.street.trim();
        }
        if (dealer.address.city) {
            dealer.address.city = dealer.address.city.trim();
        }
        if (dealer.address.address2) {
            dealer.address.address2 = dealer.address.address2.trim();
        }
        if (dealer.address.zip) {
            dealer.address.zip = dealer.address.zip.trim();
        }
        if (dealer.address.state) {
            dealer.address.state = dealer.address.state.trim();
        }
        if (dealer.phone) {
            dealer.phone = dealer.phone.trim();
        }
        if (dealer.website) {
            dealer.website = dealer.website.trim();
        }
        if (dealer.creditApplicationURL) {
            dealer.creditApplicationURL = dealer.creditApplicationURL.trim();
        }
        if (dealer.creditApplicationButtonText) {
            dealer.creditApplicationButtonText = dealer.creditApplicationButtonText.trim();
        }
        if (dealer.shopperEducationVideoURL) {
            dealer.shopperEducationVideoURL = dealer.shopperEducationVideoURL.trim();
        }
        if (dealer.shortCustomDisclaimer) {
            dealer.shortCustomDisclaimer = dealer.shortCustomDisclaimer.trim();
        }
        if (dealer.longCustomDisclaimer) {
            dealer.longCustomDisclaimer = dealer.longCustomDisclaimer.trim();
        }
        if (dealer.dnaAccountId) {
            dealer.dnaAccountId = dealer.dnaAccountId.trim();
        }
        if (dealer.contactOptions.smsPhone) {
            dealer.contactOptions.smsPhone = dealer.contactOptions.smsPhone.trim();
        }
        if (dealer.contactOptions.trackingPhone) {
            dealer.contactOptions.trackingPhone = dealer.contactOptions.trackingPhone.trim();
        }
        if (dealer.reservation.accountId) {
            dealer.reservation.accountId = dealer.reservation.accountId.trim();
        }
        if (dealer.reservation.terminalId) {
            dealer.reservation.terminalId = dealer.reservation.terminalId.trim();
        }
        if (dealer.reservation.acceptorId) {
            dealer.reservation.acceptorId = dealer.reservation.acceptorId.trim();
        }
        if (dealer.reservation.accountToken) {
            dealer.reservation.accountToken = dealer.reservation.accountToken.trim();
        }
        if (dealer.tradeInValuationICOProviderUrl) {
            dealer.tradeInValuationICOProviderUrl = dealer.tradeInValuationICOProviderUrl.trim();
        }
        if (dealer.TCPAConsentText) {
            dealer.TCPAConsentText = dealer.TCPAConsentText.trim();
        }
        if (dealer.overrideDRSAccountId) {
            dealer.overrideDRSAccountId = dealer.overrideDRSAccountId.trim();
        }

        if (!dealer.leadRoutingOverrideSettings.leadRoutingOverrideEnabled) {
            dealer.leadRoutingOverrideSettings = {
                leadRoutingOverrideEnabled: dealer.leadRoutingOverrideSettings.leadRoutingOverrideEnabled,
                dealertrackOverrideId: Number(dealer.leadRoutingOverrideSettings.dealertrackOverrideId || '')
            };
        }
        if (dealer.privacyNoticeUrl) {
            dealer.privacyNoticeUrl = dealer.privacyNoticeUrl.trim();
        }

        // Listing site fields
        if (dealer.listingOverride.tradeInValuationICOProviderUrl) {
            dealer.listingOverride.tradeInValuationICOProviderUrl = dealer.listingOverride.tradeInValuationICOProviderUrl.trim();
        }
        if (dealer.listingOverride.contactOptions.trackingPhone) {
            dealer.listingOverride.contactOptions.trackingPhone = dealer.listingOverride.contactOptions.trackingPhone.trim();
        }
        if (dealer.listingOverride.contactOptions.smsPhone) {
            dealer.listingOverride.contactOptions.smsPhone = dealer.listingOverride.contactOptions.smsPhone.trim();
        }
        return dealer;
    }
    public validate(dealer: IDealerToManage) {
        try {
            return schema.validateSync(dealer, { abortEarly: false });
        } catch (error) {
            this.setState({ validationErrors: ['Please enter all required fields.', ...error.errors] });
            splunkLogger.log(`Dealer ID: ${dealer.dealerId} --- erros: ${error.errors} --- values: ${error.value}`, '');
            return false;
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    dealer: state.manager.dealerToManage || null,
    validatorMap: state.manager.validatorMap,
    partnerMappings: state.manager.partnerMappings
});
const mapReducersToProps = {
    updateValidatorMap,
    updateDealerToManageAction: updateDealerToManage,
    updatePartnerMappingsAction: updatePartnerMappings
};

export default connect(mapStateToProps, mapReducersToProps)(withAuth(withApi(DealerManagerContainer)));
