import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const isDownPaymentFixedAmount = value;
        const financing = {
            ...oldDealer.financing,
            isDownPaymentFixedAmount
        };
        const newDealer = {
            ...oldDealer,
            financing
        };
        updateDealerCallback(null, newDealer);
    };
}

const IsDownPaymentFixedAmount: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.financing.isDownPaymentFixedAmount;
    return (
        <DR3RadioBooleanField
            trueOption="Fixed Amount"
            falseOption="Percentage"
            label={ToggleLabelMap.isDownPaymentFixedAmount}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default IsDownPaymentFixedAmount;
