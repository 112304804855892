import React, { FunctionComponent } from 'react';
import { IHandleFieldChange } from '../../store/manager/types';
import { convertToCamelCase } from './utils';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

interface IDR3RadioStringFieldProps {
    label: string;
    value: string;
    trueOption: string;
    falseOption: string;
    handleChange: IHandleFieldChange<string>;
    tooltip?: string;
    disabled?: boolean;
}

// saves a string value
export const DR3RadioStringField: FunctionComponent<IDR3RadioStringFieldProps> = (props) => {
    const trueValue = convertToCamelCase(props.trueOption);
    let falseValue = convertToCamelCase(props.falseOption);
    // ensures fields save with naming consistent to what's in Mongo
    if (falseValue === 'retail') {
        falseValue = 'retailRatePrice';
    } else if (falseValue === 'non-Refundable') {
        falseValue = 'non-refundable';
    }
    const selected = props.value;
    function handleChange(event: React.ChangeEvent<unknown>) {
        const value = (event.target as HTMLInputElement).value;
        return props.handleChange(value);
    }
    return (
        <DR3FieldWrapper label={props.label} tooltip={props.tooltip}>
            <div>
                <FormControl component="fieldset">
                    <RadioGroup name="position" value={selected} onChange={handleChange} row>
                        <FormControlLabel
                            style={{ marginLeft: '1em', paddingTop: '6px' }}
                            value={falseValue}
                            control={<Radio />}
                            label={props.falseOption}
                            labelPlacement="end"
                            disabled={props.disabled}
                        />
                        <FormControlLabel
                            style={{ marginLeft: '1em', paddingTop: '6px' }}
                            value={trueValue}
                            control={<Radio />}
                            label={props.trueOption}
                            labelPlacement="end"
                            disabled={props.disabled}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
        </DR3FieldWrapper>
    );
};
