import React from 'react';
import { FunctionComponent } from 'react';
import {
    IDealerToManage,
    IInputAdornmentProps,
    IUpdateDealerCallbackFn,
    ICommonManagerFieldProps
} from '../../store/manager/types';
import { DR3TextFieldWithAdornment } from '../common/DR3TextFieldWithAdornment';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const downPaymentFixedAmount = value;
        const leasing = {
            ...oldDealer.leasing,
            downPaymentFixedAmount
        };
        updateDealerCallback(null, {
            ...oldDealer,
            leasing
        });
    };
}

const inputProps: IInputAdornmentProps = {
    position: 'start',
    unit: '$'
};

const DownPaymentFixedAmount: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.leasing.isDownPaymentFixedAmount) {
        return null;
    }
    const value = props.dealer.leasing.downPaymentFixedAmount;
    return (
        <DR3TextFieldWithAdornment
            label="Cash Down Value"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            InputProps={inputProps}
            maxLength={7}
        />
    );
};
export default DownPaymentFixedAmount;
