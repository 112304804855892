import { IDealerToManage, IdPartnerMapping, Integrator, PartnerMapping } from '../../store/manager/types';
import {
    validateDNAAccountID,
    validateIsNumber,
    validateRouteOneDealerId,
    validateEsntialPartnerId,
    validateCommonOrgID
} from '../common/utils';
import { IDealerMap } from '../dealxgClientSetup/types';
import { getNestedProperty } from './Utils';

const idPartnerMappings: IdPartnerMapping[] = [
    {
        integratorId: 'dealerId',
        status: 'original',
        partnerDealerId: '',
        fieldToSave: 'dealerId',
        isEditable: false,
        type: 'numeric'
    },
    {
        integratorId: 'dnaAccountId',
        status: 'original',
        partnerDealerId: '',
        type: 'string',
        fieldToSave: 'dnaAccountId',
        quickLinks: [
            {
                linkName: 'User Manager',
                linkValue: (id: string) => `https://apps.dealer.com/configuration/as/${id}/${id}-admin/manage/users`
            },
            {
                linkName: 'Lead Distribution',
                linkValue: (id: string) => `https://apps.dealer.com/leads/as/${id}/${id}-admin/configure/leaddistribution`
            },
            {
                linkName: 'Analytics',
                linkValue: (id: string) =>
                    `https://apps.dealer.com/analytics/as/${id}/${id}-admin/report/accelerate-digital-retailing`
            }
        ]
    },
    {
        integratorId: 'svocId',
        status: 'original',
        partnerDealerId: '',
        type: 'numeric',
        fieldToSave: 'ownerId',
        quickLinks: [
            {
                linkName: 'Fastlane',
                linkValue: (id: string) =>
                    `https://fastlane.autotrader.com/freedom/eso/eso-feature-manager.seam?dealerIdParam=${id || ''}`
            },
            {
                linkName: 'ATC Listings',
                linkValue: (id: string) => `https://www.autotrader.com/car-dealers/city+st-00000/${id || ''}/`
            },
            {
                linkName: 'KBB Listings',
                linkValue: (id: string) => `https://www.kbb.com/dealers/city_vt/dealership/${id || ''}/`
            }
        ]
    },
    {
        integratorId: 'dealerTrackID',
        status: 'original',
        partnerDealerId: '',
        fieldToSave: 'dealerTrackID',
        type: 'numeric'
    },
    {
        integratorId: 'dealertrackIdOverride',
        status: 'original',
        partnerDealerId: '',
        type: 'numeric',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore This field is additional
        fieldToSave: 'leadRoutingOverrideSettings.dealertrackOverrideId'
    },
    {
        integratorId: 'overrideDRSAccountId',
        status: 'original',
        partnerDealerId: '',
        type: 'string',
        fieldToSave: 'overrideDRSAccountId'
    },
    {
        integratorId: 'routeOnePartyId',
        status: 'original',
        partnerDealerId: '',
        type: 'string',
        fieldToSave: 'routeOnePartyId'
    },
    {
        integratorId: 'vinSolutionsAccountId',
        status: 'original',
        partnerDealerId: '',
        type: 'numeric',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore This field is additional
        fieldToSave: 'crmIntegration.vinSolutionsAccountId'
    },
    {
        integratorId: 'activEngageMmdId',
        status: 'original',
        partnerDealerId: '',
        type: 'string',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore This field is additional
        fieldToSave: 'contactOptions.activEngageMmdId'
    },
    {
        integratorId: 'esntialPartnerId',
        status: 'original',
        partnerDealerId: '',
        type: 'string',
        fieldToSave: 'esntialPartnerId'
    },
    {
        integratorId: 'esntialPartnerDealerId',
        status: 'original',
        partnerDealerId: '',
        fieldToSave: 'esntialPartnerDealerId',
        type: 'numeric'
    },
    {
        integratorId: 'commonOrgId',
        status: 'original',
        partnerDealerId: '',
        type: 'string',
        fieldToSave: 'commonOrgId'
    }
];

export const getIntegratorsForIds = (): Integrator[] => [
    {
        integratorId: 'dealerId',
        integratorName: 'Dealer ID'
    },
    {
        integratorId: 'dnaAccountId',
        integratorName: 'DNA Account ID'
    },
    {
        integratorId: 'svocId',
        integratorName: 'SVOC (Gearbox Owner ID)'
    },
    {
        integratorId: 'dealertrackIdOverride',
        integratorName: 'Dealertrack MMD ID Override'
    },
    {
        integratorId: 'overrideDRSAccountId',
        integratorName: 'eBiz Account ID'
    },
    {
        integratorId: 'routeOnePartyId',
        integratorName: 'RouteOne Party ID'
    },
    {
        integratorId: 'vinSolutionsAccountId',
        integratorName: 'VinSolutions Account ID'
    },
    {
        integratorId: 'dealerTrackID',
        integratorName: 'Dealertrack Dealer ID'
    },
    {
        integratorId: 'activEngageMmdId',
        integratorName: 'ActivEngage MMD ID'
    },
    {
        integratorId: 'esntialPartnerId',
        integratorName: 'Esntial Partner ID'
    },
    {
        integratorId: 'esntialPartnerDealerId',
        integratorName: 'Esntial Partner Dealer ID'
    },
    {
        integratorId: 'commonOrgId',
        integratorName: 'Common Org ID'
    }
];

const nonDeletableIntegrators = [
    'dealerId',
    'dnaAccountId',
    'svocId',
    'dealerTrackID',
    'dealertrackIdOverride',
    'overrideDRSAccountId',
    'routeOnePartyId',
    'vinSolutionsAccountId',
    'activEngageMmdId',
    'esntialPartnerId',
    'esntialPartnerDealerId',
    'commonOrgId'
];
export const isRowDeletable = (rowData: IdPartnerMapping) => !nonDeletableIntegrators.includes(rowData?.integratorId);
export const isClientRowDeletable = (rowData: IDealerMap) => !nonDeletableIntegrators.includes(rowData?.value);

export const getIdMappings = (dealer: Record<keyof IDealerToManage, any> | null): PartnerMapping[] => {
    if (!dealer) return [];

    return idPartnerMappings.map((mapping) => {
        mapping.partnerDealerId = getNestedProperty(dealer, mapping.fieldToSave)?.toString() || '';

        return mapping as PartnerMapping;
    });
};

export const getQuickLinkValue = (integratorId: string, linkName: string, id: string) => {
    const partnerMapping = idPartnerMappings.find((mapping) => mapping.integratorId === integratorId);
    if (!partnerMapping) return undefined;

    const quickLink = partnerMapping.quickLinks?.find((ql) => ql.linkName === linkName);
    if (!quickLink) return undefined;

    return quickLink.linkValue(id);
};

// Add a validate function if you want the Id to be validated
export const getValidateForId = (integratorId: string) => {
    const onlyNumbersValidator = (rowData: IdPartnerMapping) => {
        const isValid = validateIsNumber(rowData.partnerDealerId?.toString() || '');

        return isValid ? true : 'Must be a number';
    };

    const validators = [
        {
            integratorId: 'svocId',
            validateFn: onlyNumbersValidator
        },
        {
            integratorId: 'dnaAccountId',
            validateFn: (rowData: IdPartnerMapping) => {
                const isValid = validateDNAAccountID(rowData.partnerDealerId?.toString() || '');

                return isValid ? true : 'Must be alphanumeric with no spaces';
            }
        },
        {
            integratorId: 'dealerTrackID',
            validateFn: onlyNumbersValidator
        },
        {
            integratorId: 'dealertrackIdOverride',
            validateFn: onlyNumbersValidator
        },
        {
            integratorId: 'overrideDRSAccountId',
            validateFn: onlyNumbersValidator
        },
        {
            integratorId: 'routeOnePartyId',
            validateFn: (rowData: IdPartnerMapping) => {
                const isValid = validateRouteOneDealerId(rowData.partnerDealerId?.toString() || '');

                return isValid ? true : 'Invalid format';
            }
        },
        {
            integratorId: 'vinSolutionsAccountId',
            validateFn: onlyNumbersValidator
        },
        {
            integratorId: 'esntialPartnerId',
            validateFn: (rowData: IdPartnerMapping) => {
                const isValid = validateEsntialPartnerId(rowData.partnerDealerId?.toString() || '');

                return isValid ? true : 'Must be alphanumeric with maximum three characters';
            }
        },
        {
            integratorId: 'esntialPartnerDealerId',
            validateFn: onlyNumbersValidator
        },
        {
            integratorId: 'commonOrgId',
            validateFn: (rowData: IdPartnerMapping) => {
                const isValid = validateCommonOrgID(rowData.partnerDealerId?.toString() || '');

                return isValid ? true : 'Must be alphanumeric with no spaces';
            }
        }
    ];

    const validator = validators.find((v) => v.integratorId === integratorId);

    return validator?.validateFn;
};
