import { IGetDealerResponse, IListingOverride } from '../types';
import { asSafeContactOptions } from './asSafeContactOptions';
import { asSafeDealerListingOverrideProducts } from './asSafeDealerListingOverrideProducts';
import { asSafeDealerReservation } from './asSafeDealerReservation';
import { asSafeListingOverrideSiteToggles } from './asSafeListingOverrideSiteToggles';
import { asSafeBoolean, asSafeEmptyString } from './selectorUtils';
import { asSafeDealerIncentives } from './asSafeDealerIncentives';

export function asSafeListingOverride(response: IGetDealerResponse): IListingOverride {
    if (!response.listingOverride || Object.keys(response.listingOverride).length === 0) {
        const defaultListing: IListingOverride = {
            products: asSafeDealerListingOverrideProducts(response.products),
            isTradeInBranded: asSafeBoolean(response.isTradeInBranded),
            tradeInHideExcellent: asSafeBoolean(response.tradeInHideExcellent),
            tradeInValuationLeadForm: asSafeBoolean(response.tradeInValuationLeadForm),
            tradeInValuationProvider: asSafeEmptyString(response.tradeInValuationProvider),
            tradeInValuationICOProviderUrl: asSafeEmptyString(response.tradeInValuationICOProviderUrl),
            canNegotiateNew: asSafeBoolean(response.canNegotiateNew),
            canNegotiateUsed: asSafeBoolean(response.canNegotiateUsed),
            dealerSiteToggles: asSafeListingOverrideSiteToggles(response.dealerSiteToggles),
            incentives: asSafeDealerIncentives(response.incentives),
            excludeStdCashIncsLease: asSafeBoolean(response.excludeStdCashIncsLease),
            excludeStdCashIncsLoan: asSafeBoolean(response.excludeStdCashIncsLoan),
            excludeStdCashIncsCash: asSafeBoolean(response.excludeStdCashIncsCash),
            contactOptions: asSafeContactOptions(response.contactOptions),
            enablePrePurchaseAgreement: asSafeBoolean(response.enablePrePurchaseAgreement),
            reservation: asSafeDealerReservation(response.reservation),
            isMenuPricingBasedOn: asSafeEmptyString(response.isMenuPricingBasedOn),
            reviewDealUrlEnabled: asSafeBoolean(response.reviewDealUrlEnabled),
            isRedesignEnabled: asSafeBoolean(response.isRedesignEnabled),
            isDIOEnabled: asSafeBoolean(response.isDIOEnabled),
            leadGatePaymentOnDS: asSafeBoolean(response.leadGatePaymentOnDS)
        };
        return defaultListing;
    }
    const existingListing: IListingOverride = {
        products: asSafeDealerListingOverrideProducts(response.listingOverride.products),
        isTradeInBranded: asSafeBoolean(response.listingOverride.isTradeInBranded),
        tradeInHideExcellent: asSafeBoolean(response.listingOverride.tradeInHideExcellent),
        tradeInValuationLeadForm: asSafeBoolean(response.listingOverride.tradeInValuationLeadForm),
        tradeInValuationProvider: asSafeEmptyString(response.listingOverride.tradeInValuationProvider),
        tradeInValuationICOProviderUrl: asSafeEmptyString(response.listingOverride.tradeInValuationICOProviderUrl),
        canNegotiateNew: asSafeBoolean(response.listingOverride.canNegotiateNew),
        canNegotiateUsed: asSafeBoolean(response.listingOverride.canNegotiateUsed),
        dealerSiteToggles: asSafeListingOverrideSiteToggles(response.listingOverride.dealerSiteToggles),
        incentives: asSafeDealerIncentives(response.listingOverride.incentives),
        excludeStdCashIncsLease: asSafeBoolean(response.listingOverride.excludeStdCashIncsLease),
        excludeStdCashIncsLoan: asSafeBoolean(response.listingOverride.excludeStdCashIncsLoan),
        excludeStdCashIncsCash: asSafeBoolean(response.listingOverride.excludeStdCashIncsCash),
        contactOptions: asSafeContactOptions(response.listingOverride.contactOptions),
        enablePrePurchaseAgreement: asSafeBoolean(response.listingOverride.enablePrePurchaseAgreement),
        reservation: asSafeDealerReservation(response.listingOverride.reservation),
        isMenuPricingBasedOn: asSafeEmptyString(response.listingOverride.isMenuPricingBasedOn),
        reviewDealUrlEnabled: asSafeBoolean(response.listingOverride.reviewDealUrlEnabled),
        isRedesignEnabled: asSafeBoolean(response.listingOverride.isRedesignEnabled),
        isDIOEnabled: asSafeBoolean(response.listingOverride.isDIOEnabled),
        leadGatePaymentOnDS: asSafeBoolean(response.listingOverride.leadGatePaymentOnDS)
    };
    return existingListing;
}
