import React, { FunctionComponent } from 'react';
import { DealXGClientMap, IClientContainerProps } from '../types';

import { DR3DropdownField } from '../../common/DR3DropdownField';
import { IDropdownOptions } from '../../../store/manager/types';

function handleChange(oldClient: DealXGClientMap, updateClientCallback: React.Dispatch<React.SetStateAction<any>>) {
    return (value: string): void => {
        const newValue: any = oldClient.webhooks ? { ...oldClient.webhooks[0] } : {};
        newValue.httpMethod = value;
        const newClient = {
            ...oldClient,
            webhooks: [{ ...newValue }]
        };
        updateClientCallback(newClient);
    };
}

const options: IDropdownOptions[] = [
    {
        optionValue: 'POST',
        optionLabel: 'POST'
    },
    {
        optionValue: 'PUT',
        optionLabel: 'PUT'
    },
    {
        optionValue: 'PATCH',
        optionLabel: 'PATCH'
    }
];

const HttpMethod: FunctionComponent<IClientContainerProps> = (props) => {
    const value = props.client.webhooks && props.client.webhooks.length > 0 ? props.client.webhooks[0].httpMethod : '';
    return (
        <DR3DropdownField
            label="HTTP Method"
            value={value || ''}
            handleChange={handleChange(props.client, props.setClient)}
            options={options}
        />
    );
};
export default HttpMethod;
