import React from 'react';
import { IUploadBrandingExpResponse, IImageUploadDimensions } from '../../store/manager/types';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IDealerFieldsListUIProps } from '../DealerFieldsListUI';
import { IUploadBrandingExpRequest } from '../../utils/Api';
import { DR3FieldWrapper } from '../common/DR3FieldWrapper';
import { FormControl } from '@material-ui/core';
import { Config } from '../../config';

let brandingExperienceURL = '';
const warningDialogNegativeSelection = 'CANCEL';
const warningDialogAffirmativeSelection = 'CONFIRM';
let imageForUploadType = '';
let imageForUploadDimensions = '';
let warningDialogMessage = '';
const jpegImageAcceptedDimensions: IImageUploadDimensions = {
    width: 400,
    height: 92
};
const pngImageAcceptedDimensions: IImageUploadDimensions = {
    width: 244,
    height: 55
};
const svgImageAcceptedDimensions: IImageUploadDimensions = {
    width: 262,
    height: 59
};
export default class BrandingExperienceDropzone extends React.Component<IDealerFieldsListUIProps, any> {
    constructor(props: IDealerFieldsListUIProps) {
        super(props);
        this.state = {
            openDropzone: false,
            files: [],
            openWarningDialog: false,
            confirmUpload: false
        };
        this.handleCloseDropzone = this.handleCloseDropzone.bind(this);
        this.handleSaveDropzone = this.handleSaveDropzone.bind(this);
        this.handleOpenDropzone = this.handleOpenDropzone.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);

        this.handleOpenWarningDialog = this.handleOpenWarningDialog.bind(this);
        this.handleCloseWarningDialog = this.handleCloseWarningDialog.bind(this);
    }

    public handleCloseDropzone() {
        this.setState({
            openDropzone: false
        });
    }

    public handleOpenDropzone() {
        this.setState({
            openDropzone: true
        });
    }

    public handleRemoveImage() {
        if (this.props.dealer) {
            const oldDealer = this.props.dealer;
            const brandingExperience = '';
            this.props.updateDealerCallback(null, {
                ...oldDealer,
                brandingExperience
            });
        }
    }

    public handleCloseWarningDialog() {
        this.setState({
            openWarningDialog: false
        });
    }

    public handleOpenWarningDialog() {
        this.setState({
            openWarningDialog: true
        });
    }

    public async handleWarningDialogSelection(buttonSelection: any) {
        this.setState({
            openWarningDialog: false,
            confirmUpload: true
        });
        if (buttonSelection.currentTarget.innerText === warningDialogAffirmativeSelection) {
            this.handleImageUpload();
        }
    }

    public async handleSaveDropzone(filesToSave: any) {
        // Saving files to state for further use and closing Modal.
        this.setState({
            files: filesToSave,
            openDropzone: false
        });
        if (filesToSave[0]) {
            await this.areImageDimensionsValid(filesToSave).then(async (areDimensionsValid) => {
                if (areDimensionsValid === false) {
                    let acceptedDimensionsForFileType = '';
                    if (imageForUploadType === 'JPEG') {
                        // eslint-disable-next-line max-len
                        acceptedDimensionsForFileType = `${jpegImageAcceptedDimensions.width}x${jpegImageAcceptedDimensions.height}`;
                        warningDialogMessage = this.craftWarningDialogMessageJPGAndPNG(
                            imageForUploadDimensions,
                            imageForUploadType,
                            acceptedDimensionsForFileType
                        );
                    }
                    if (imageForUploadType === 'PNG') {
                        acceptedDimensionsForFileType = `${pngImageAcceptedDimensions.width}x${pngImageAcceptedDimensions.height}`;
                        warningDialogMessage = this.craftWarningDialogMessageJPGAndPNG(
                            imageForUploadDimensions,
                            imageForUploadType,
                            acceptedDimensionsForFileType
                        );
                    }
                    if (imageForUploadType === 'SVG') {
                        acceptedDimensionsForFileType = `${svgImageAcceptedDimensions.width}x${svgImageAcceptedDimensions.height}`;
                        warningDialogMessage = this.craftWarningDialogMessageSVG(imageForUploadType, acceptedDimensionsForFileType);
                    }
                    this.setState({
                        openWarningDialog: true
                    });
                } else {
                    this.handleImageUpload();
                }
            });
        }
    }

    public craftWarningDialogMessageJPGAndPNG(
        imageForUploadDimensions: string,
        imageForUploadType: string,
        acceptedDimensionsForFileType: string
    ) {
        // eslint-disable-next-line max-len
        return `Image dimensions of ${imageForUploadDimensions} are not consistent with the standard for ${imageForUploadType} (${acceptedDimensionsForFileType}). Are you sure you want to upload?`;
    }

    public craftWarningDialogMessageSVG(imageForUploadType: string, acceptedDimensionsForFileType: string) {
        return `Please confirm your image dimensions are consistent with the
                    standard for ${imageForUploadType} (${acceptedDimensionsForFileType}).
                    Proceed with upload?`;
    }

    public async handleImageUpload() {
        let uploadBrandingExpResponse: IUploadBrandingExpResponse = {
            Bucket: '',
            Key: '',
            Location: ''
        };
        if (this.props.uploadBrandingExpCallback && this.props.dealer) {
            const uploadId: IUploadBrandingExpRequest = {
                dealer: this.props.dealer
            };
            uploadBrandingExpResponse = await this.props.uploadBrandingExpCallback(null, uploadId, this.state.files);
            brandingExperienceURL = uploadBrandingExpResponse.Location;
            const oldDealer = this.props.dealer;
            const brandingExperienceName = uploadBrandingExpResponse.Key;
            const brandingExperience = brandingExperienceName.substring(brandingExperienceName.lastIndexOf('/') + 1);

            this.props.updateDealerCallback(null, {
                ...oldDealer,
                brandingExperience
            });
        }
    }

    public async areImageDimensionsValid(imageList: FileList) {
        const reader = new FileReader();
        const imageToUpload = imageList[0];
        reader.readAsDataURL(imageToUpload);
        return new Promise((resolve) => {
            reader.onload = function (e: any) {
                const imageToRead = new Image();
                if (e.target.result) {
                    if (typeof e.target.result === 'string') {
                        imageToRead.src = e.target.result;
                        const width = imageToRead.width;
                        const height = imageToRead.height;
                        if (imageToUpload.type === 'image/jpeg') {
                            if (width === jpegImageAcceptedDimensions.width && height === jpegImageAcceptedDimensions.height) {
                                resolve(true);
                            } else {
                                imageForUploadType = 'JPEG';
                                imageForUploadDimensions = `${width}x${height}`;
                                resolve(false);
                            }
                        }
                        if (imageToUpload.type === 'image/png') {
                            if (width === pngImageAcceptedDimensions.width && height === pngImageAcceptedDimensions.height) {
                                resolve(true);
                            } else {
                                imageForUploadType = 'PNG';
                                imageForUploadDimensions = `${width}x${height}`;
                                resolve(false);
                            }
                        }
                        if (imageToUpload.type === 'image/svg+xml') {
                            imageForUploadType = 'SVG';
                            // no width or height available for .svg - resolving false to continue to warning dialog
                            resolve(false);
                        }
                    }
                }
            };
        });
    }

    public getRemoveImageButton() {
        if (this.props.dealer && this.props.dealer.brandingExperience) {
            return (
                <Button onClick={this.handleRemoveImage.bind(this)} id="remove-image-button">
                    Remove Image
                </Button>
            );
        }
    }

    public render() {
        if (!this.props.dealer) {
            return null;
        }
        brandingExperienceURL = '';
        if (this.props.dealer.brandingExperience) {
            brandingExperienceURL = `${Config.urls.s3BrandingExpUrl}${this.props.dealer.brandingExperience}`;
        }
        return (
            <DR3FieldWrapper label={'Branding Experience'}>
                <FormControl fullWidth component="fieldset">
                    <img alt="Branding Experience" src={brandingExperienceURL} className="brandingExperienceImage" />
                    <Button onClick={this.handleOpenDropzone.bind(this)}>Add Image</Button>
                    {this.getRemoveImageButton()}
                    <div className="brandingExperienceMessage">
                        File type must be .png, .jpg, or .svg
                        <br></br>
                        File size must be less than 50MB
                        <br></br>
                        Image dimensions for JPGs must be 400 x 92
                        <br></br>
                        Image dimensions for PNGs must be 244 x 55
                        <br></br>
                        Image dimensions for SVGs must be 262 x 59
                    </div>
                    <DropzoneDialog
                        open={this.state.openDropzone}
                        onSave={this.handleSaveDropzone.bind(this)}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/svg+xml']}
                        showPreviews={true}
                        maxFileSize={50000000}
                        onClose={this.handleCloseDropzone.bind(this)}
                        filesLimit={1}
                    />
                    <Dialog
                        open={this.state.openWarningDialog}
                        onClose={this.state.handleCloseWarningDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{'Warning'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{warningDialogMessage}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleWarningDialogSelection.bind(this)} color="primary">
                                {warningDialogNegativeSelection}
                            </Button>
                            <Button onClick={this.handleWarningDialogSelection.bind(this)} color="primary" autoFocus>
                                {warningDialogAffirmativeSelection}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FormControl>
            </DR3FieldWrapper>
        );
    }
}
