import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const newDealer = {
            ...oldDealer,
            isConsumerFacingCreditDecisionEnabled: value,
            consumerFacingCreditDecisionType: 'lowestPayment'
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip = `Display a consumer-facing decision with details of lender and monthly payment to the user in AMD and Sales View. 
 This functionality is disabled when the redesign credit decision toggle is disabled`;

const IsConsumerFacingCreditDecisionEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = !!props.dealer.isConsumerFacingCreditDecisionEnabled;
    const isAccelerateCreditDecisionEnabled = props.dealer.isAccelerateCreditDecisionEnabled;

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.isConsumerFacingCreditDecisionEnabled}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
            disabled={!isAccelerateCreditDecisionEnabled}
        />
    );
};
export default IsConsumerFacingCreditDecisionEnabled;
