import { BaseDealXGClient, DealXGClientMap, DealXGClientMapDisplay, IDealerMap, IWebhookInfo, ServiceResponse } from './types';
import * as api from './api';
import { get, uniqWith, isEqual, isEmpty } from 'lodash';
import { usePapaParse } from 'react-papaparse';

const { readString } = usePapaParse();

export const loadDealXGClients = async (accessToken: string) => {
    const clientsList = await api.getClientsList(accessToken);
    return clientsList;
};

export const displayFormatDealXGClients = (clients: DealXGClientMap[]): DealXGClientMapDisplay[] => {
    return clients.map((client) => {
        const webhooks = client.webhooks?.length ? client.webhooks[0] : { url: '', httpMethod: '' };
        return {
            clientId: client.clientId,
            clientName: client.clientName || '',
            defaultAppName: client.defaultChangedBy?.applicationName || '',
            defaultPersona: client.defaultChangedBy?.persona || '',
            webhookHttpMethod: webhooks.httpMethod,
            webhookUrl: webhooks.url,
            disable: client.disable
        };
    });
};

export const loadDealXGClientById = async (accessToken: string, clientId: string): Promise<DealXGClientMap> => {
    return api.getClientById(accessToken, clientId);
};

export const showDisabledClients = (clients: DealXGClientMapDisplay[], checked: boolean): DealXGClientMapDisplay[] => {
    return clients.filter((client: DealXGClientMapDisplay) => client.disable === checked);
};

export const saveDealXGClient = async (
    accessToken: string,
    client: DealXGClientMap,
    isNew: boolean
): Promise<ServiceResponse<DealXGClientMap>> => {
    return !isNew ? await api.updateClient(accessToken, client) : await api.saveClient(accessToken, client);
};

export const buildServiceResponse = <T = any>(props: { statusCode: number; data?: T; error?: any }) => {
    const { statusCode, data, error } = props;
    let result: ServiceResponse<T>;
    if (![200, 204].includes(statusCode)) {
        result = {
            ok: false,
            error,
            statusCode
        };
    } else {
        result = {
            ok: true,
            data
        };
    }
    return result;
};

export const isIntegratorAlreadyOnPartnerMapping = (rowData: IDealerMap, dealerMap: IDealerMap[]) => {
    return !!dealerMap.find(
        (pm) => pm.name.toLowerCase() === rowData.name.toLowerCase() && pm.value.toLowerCase() === rowData.value.toLowerCase()
    );
};
export const isDealerMapIncorrectly = (dealer: IDealerMap): boolean => {
    const { name, value } = dealer || {};
    return !name || !value;
};

export const removeEmptyObjects = (detailsObj: any): any => {
    Object.keys(detailsObj).forEach((k) => {
        if (
            detailsObj[k] === '' ||
            (detailsObj[k] && typeof detailsObj[k] === 'object' && removeEmptyObjects(detailsObj[k]) === null)
        ) {
            delete detailsObj[k];
        }
    });
    if (!Object.keys(detailsObj).length) {
        return null;
    }
};

export const isMissingRequiredFields = (client: any): string[] => {
    const messages = ' * The {0} is required. ';
    const messagesWebHooklRequired = ' * The Field {0} are required.';
    const requiredFields = [
        { key: 'clientId', value: messages.replace('{0}', 'Client Id') },
        { key: 'clientName', value: messages.replace('{0}', 'Client Name') },
        { key: 'dealerMap', value: messages.replace('{0}', 'Deal Access Permissions') },
        { key: 'defaultChangedBy.persona', value: messages.replace('{0}', 'Default Persona') },
        { key: 'defaultChangedBy.applicationName', value: messages.replace('{0}', 'Default Application Name') }
    ];
    const errorMessage = [] as string[];
    requiredFields.map((x) => {
        const fieldValue = get(client, x.key);
        if (!fieldValue || isEmpty(fieldValue)) {
            errorMessage.push(x.value);
        }
    });

    if (client.clientId) {
        const validClientRegexPattern = /^[a-zA-Z0-9-]+$/;
        if (!validClientRegexPattern.test(client.clientId)) {
            errorMessage.push(' * Special characters are not allowed for Client ID');
        }
    }

    if (client.webhooks) {
        const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;
        client.webhooks?.map((webhookData: IWebhookInfo) => {
            if (webhookData.url && !urlPattern.test(webhookData.url)) {
                errorMessage.push(' * The Field Url field must be in a valid URL format.');
            }
            if (!webhookData.url && webhookData.httpMethod) {
                errorMessage.push(messagesWebHooklRequired.replace('{0}', 'Url'));
            } else if (webhookData.url && !webhookData.httpMethod) {
                errorMessage.push(messagesWebHooklRequired.replace('{0}', 'Http Method'));
            }
            if (webhookData.basicAuth) {
                if (!webhookData.basicAuth.username && webhookData.basicAuth.password) {
                    errorMessage.push(messagesWebHooklRequired.replace('{0}', 'User Name'));
                } else if (webhookData.basicAuth.username && !webhookData.basicAuth.password) {
                    errorMessage.push(messagesWebHooklRequired.replace('{0}', 'Password'));
                }
            }
        });
    }

    return errorMessage;
};

export const clientCleanUp = (client: DealXGClientMap): DealXGClientMap => {
    const { clientId, clientName, dealerMap, defaultChangedBy, note, obfuscationRuleName, disable } = client;
    let { webhooks } = client;
    if (webhooks && webhooks.length) {
        removeEmptyObjects(webhooks[0]);
        if (!Object.keys(webhooks[0]).length) {
            webhooks = undefined;
        }
    }
    return {
        clientId,
        clientName,
        dealerMap: dealerMap.map((map) => {
            return { name: map.name, value: map.value };
        }),
        ...(webhooks?.length && { webhooks }),
        defaultChangedBy,
        note,
        obfuscationRuleName,
        disable
    };
};

export const processDealAccessPermissionsFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
    client: DealXGClientMap,
    setClient: React.Dispatch<React.SetStateAction<any>>
): Promise<void> => {
    event.preventDefault();
    if (!event.target.files) {
        return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        const csvContent = atob(base64String);
        readString(csvContent, {
            worker: true,
            complete: (results: any) => {
                const loadedData = results.data
                    .map((dealerMap: string[]) => {
                        const [name, value] = dealerMap;
                        return { name, value };
                    })
                    .filter((map: IDealerMap) => map.name !== '' && map.value !== '');
                loadedData.shift();
                if (loadedData.length) {
                    const newClient = {
                        ...client,
                        dealerMap: uniqWith(loadedData, isEqual)
                    };
                    setClient(newClient);
                }
            }
        });
    };
    reader.readAsDataURL(file);
};

export const toggleClientData = (
    clientId: string,
    clientsList: BaseDealXGClient[],
    toggle: boolean,
    setData: React.Dispatch<React.SetStateAction<any>>
) => {
    const position = clientsList.findIndex((client) => client.clientId === clientId);
    if (position > -1) {
        const list = [...clientsList];
        list[position].disable = toggle;
        setData(list);
    }
};
