import React, { FunctionComponent, useEffect, useState } from 'react';
import { IClientContainerProps } from '../types';
import { FormHelperText } from '@material-ui/core';
import useStyles from '../../layout/styles';
import { DR3FieldWrapper } from '../../common/DR3FieldWrapper';
import { ValidationTextField } from '../../common/ValidationTextField';

const UserName: FunctionComponent<IClientContainerProps> = (props) => {
    const classes = useStyles({});
    const [UserNameValue, setUserName] = useState('');

    useEffect(() => {
        setUserName(
            props.client.webhooks && props.client.webhooks.length > 0 && props.client.webhooks[0].basicAuth
                ? props.client.webhooks[0].basicAuth.username
                : ''
        );
    }, [props.client]);

    const handleChange = () => {
        let oldValue: any = props.client.webhooks && props.client.webhooks.length > 0 ? props.client.webhooks[0] : {};
        const newValue = { basicAuth: { username: UserNameValue } };
        if (oldValue.basicAuth) {
            oldValue.basicAuth.username = UserNameValue;
        } else {
            oldValue = { ...oldValue, ...newValue };
        }

        const newClient = {
            ...props.client,
            webhooks: [oldValue]
        };
        props.setClient(newClient);
    };
    const errorText = '';
    return (
        <DR3FieldWrapper label={'User Name'}>
            <ValidationTextField
                error={!!errorText}
                variant="outlined"
                className={classes.textField}
                required={false}
                value={UserNameValue}
                onChange={(event) => {
                    setUserName(event.target.value);
                }}
                onBlur={handleChange}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
export default UserName;
