import React, { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import FlatButton from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import './DealerMetricsCustomSearchField.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addToggleButton: {
            right: '-1rem',
            backgroundColor: '#3f51b5',
            color: '#f9f1e7',
            fontSize: '2rem',
            width: '8rem',
            position: 'relative',
            transition: 'background-color 300ms ease-in-out'
        },
        customToggleInputForm: {
            backgroundColor: theme.palette.background.paper,
            marginTop: '2rem',
            padding: '1rem'
        },
        toggleNameHeader: {
            width: '100%'
        },
        toggleNameTextField: {
            width: '50%',
            margin: '1rem 1rem 1rem 0'
        },
        toggleValueOrBooleanSelect: {
            margin: '.77rem 0 0 0'
        },
        toggleStatusLabel: {
            margin: '1rem 0'
        },
        toggleValueInput: {
            width: '60%'
        }
    })
);

interface CustomSearchFieldProps {
    submitCustomToggle: (toggleName: string, toggleState: boolean, toggleValue: string, useValueOrBoolean: boolean) => void;
}

export const BOOLEAN = 'boolean';
export const VALUE = 'value';

const validateToggleName = (toggleName: string) => {
    const isToggleNameValid = new RegExp(/^[a-zA-Z][a-zA-Z\d]*(?:\.[a-zA-Z\d]+)*$/);
    return toggleName === '' || isToggleNameValid.test(toggleName);
};

const validateToggleValue = (toggleValue: string) => {
    const isToggleValueValid = new RegExp(/^[a-zA-Z\s\d.,()_/\\]+$/);
    return toggleValue === '' || isToggleValueValid.test(toggleValue);
};

const DealerMetricsCustomSearchField: FunctionComponent<CustomSearchFieldProps> = ({ submitCustomToggle }) => {
    // Styles

    const {
        addToggleButton,
        customToggleInputForm,
        toggleNameHeader,
        toggleNameTextField,
        toggleValueOrBooleanSelect,
        toggleStatusLabel,
        toggleValueInput
    } = useStyles();

    // State
    const [customToggleName, setCustomToggleName] = useState('');
    const [customToggleState, setToggleState] = useState(true);
    const [useValueOrBoolean, setUseValueOrBoolean] = useState(BOOLEAN);
    const [isToggleNameValid, setIsToggleNameValid] = useState(true);
    const [isToggleValueValid, setIsToggleValueValid] = useState(true);
    const [customToggleValue, setCustomToggleValue] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);

    // Handlers
    const toggleNameChangeHandler = (event: any) => {
        const toggleName = event.target.value;
        setCustomToggleName(toggleName);
        setIsToggleNameValid(validateToggleName(toggleName));
    };

    const toggleValueChangeHandler = (event: any) => {
        const toggleValue = event.target.value;
        setCustomToggleValue(toggleValue);
        setIsToggleValueValid(validateToggleValue(toggleValue));
    };

    const toggleStateHandler = (event: any) => {
        const toggleState = event.target.value === 'true' ? true : false;
        setToggleState(toggleState);
    };

    const toggleBooleanValueHandler = () => {
        if (useValueOrBoolean === BOOLEAN) {
            setUseValueOrBoolean(VALUE);
        } else {
            setUseValueOrBoolean(BOOLEAN);
        }
    };

    const toggleSubmitHandler = (event: any) => {
        event.preventDefault();

        if (!canSubmit) {
            return;
        }
        const useBoolean = useValueOrBoolean === BOOLEAN;
        submitCustomToggle(customToggleName, customToggleState, customToggleValue, useBoolean);
        setCustomToggleValue('');
        setCustomToggleName('');
    };

    useEffect(() => {
        const toggleNameValid = Boolean(isToggleNameValid && customToggleName);
        const toggleValueValid = Boolean(
            (isToggleValueValid && useValueOrBoolean === VALUE && customToggleValue) || useValueOrBoolean === BOOLEAN
        );
        setCanSubmit(toggleNameValid && toggleValueValid);
    });

    const valueORBooleanField =
        useValueOrBoolean === BOOLEAN ? (
            <React.Fragment>
                <InputLabel className={toggleStatusLabel} htmlFor="dealer-metrics-custom-toggle-boolean">
                    Toggle Status
                </InputLabel>
                <Select onChange={toggleStateHandler} defaultValue={customToggleState} id="dealer-metrics-custom-toggle-boolean">
                    <option value={'true'}>Enabled</option>
                    <option value={'false'}>Disabled</option>
                </Select>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <InputLabel htmlFor="dealer-metrics-custom-toggle-value">Toggle Value</InputLabel>
                <TextField
                    className={toggleValueInput}
                    error={!isToggleValueValid}
                    value={customToggleValue}
                    onChange={toggleValueChangeHandler}
                    id="dealer-metrics-custom-toggle-value"
                />
            </React.Fragment>
        );

    const toggleHelperText = !isToggleNameValid
        ? 'The dealer setting name may only contain letters, numbers and periods, and begin with a letter'
        : '';
    const toolTipText = 'The dealer setting name can be the dealer toggle name or a path in the form `name1.name2.name3...`';
    return (
        <form className={customToggleInputForm} onSubmit={toggleSubmitHandler}>
            <Tooltip placement="top-start" TransitionComponent={Fade} title={toolTipText}>
                <Typography className={toggleNameHeader} variant="h5" align="left">
                    Dealer Setting Name or Path
                </Typography>
            </Tooltip>
            <TextField
                className={toggleNameTextField}
                value={customToggleName}
                error={!isToggleNameValid}
                onChange={toggleNameChangeHandler}
                helperText={toggleHelperText}
            />
            <Select
                className={toggleValueOrBooleanSelect}
                onChange={toggleBooleanValueHandler}
                defaultValue={BOOLEAN}
                id="dealer-metrics-custom-value"
            >
                <option value={BOOLEAN}>Boolean</option>
                <option value={VALUE}>Value</option>
            </Select>
            {valueORBooleanField}
            <FlatButton disabled={!canSubmit} className={addToggleButton} type="submit" onClick={toggleSubmitHandler}>
                Add Toggle
            </FlatButton>
        </form>
    );
};

export default DealerMetricsCustomSearchField;
