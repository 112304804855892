import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (isDIOEnabled: boolean): void => {
        const newDealer = {
            ...oldDealer,
            isDIOEnabled
        };

        updateDealerCallback(null, newDealer);
    };
}

const IsDIOEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const toolTip = `When set to Yes, this feature will display any dealer installed options &
                     their prices within the DR experience as part of the vehicle details`;

    const value = Boolean(props.dealer.isDIOEnabled);
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.isDIOEnabled}
            tooltipProps={toolTip}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};

export default IsDIOEnabled;
