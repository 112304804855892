import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const isTCPACheckboxDisabled = value;
        const newDealer = {
            ...oldDealer,
            isTCPACheckboxDisabled
        };
        updateDealerCallback(null, newDealer);
    };
}

const IsTCPACheckboxDisabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.isTCPAEnabled) {
        return null;
    }
    let disabled = false;
    if (
        props.dealer.TCPAProvider === 'hyundai' ||
        props.dealer.TCPAProvider === 'genesis' ||
        props.dealer.TCPAProvider === 'bmw' ||
        props.dealer.TCPAProvider === 'mini'
    ) {
        disabled = true;
    }
    const value = !!props.dealer.isTCPACheckboxDisabled;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.isTCPACheckboxDisabled}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            disabled={disabled}
        />
    );
};
export default IsTCPACheckboxDisabled;
