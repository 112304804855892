import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const isMenuFitToHeightEnabled = value;
        const newDealer = {
            ...oldDealer,
            isMenuFitToHeightEnabled
        };
        updateDealerCallback(null, newDealer);
    };
}

const IsMenuFitToHeightEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    let isDisabled = true;
    const value = !!props.dealer.isMenuFitToHeightEnabled;
    const toolTip =
        'Fit to width will resize the image to cover the entire container. Fit to height will make the image fully visible.';

    if (props.dealer.products.hasFi || props.dealer.listingOverride.products.hasFi) {
        isDisabled = false;
    }

    return (
        <DR3RadioBooleanField
            trueOption="Fit to Height"
            falseOption="Fit to Width"
            label={ToggleLabelMap.isMenuFitToHeightEnabled}
            value={value}
            disabled={isDisabled}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={toolTip}
        />
    );
};

export default IsMenuFitToHeightEnabled;
