import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';

interface IDealerFieldsSubSectionProps {
    title: string;
}

export const DealerFieldsSubSubSection: FunctionComponent<IDealerFieldsSubSectionProps> = ({ title, children }) => {
    return (
        <Box paddingTop={'1.5em'}>
            <Typography align="center" gutterBottom variant="h6">
                <b>{title}</b>
            </Typography>
            {children}
        </Box>
    );
};
