import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const areDealerSettingOverridesEnabled = value;
        updateDealerCallback(null, {
            ...oldDealer,
            areDealerSettingOverridesEnabled
        });
    };
}

const AreDealerSettingOverridesEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3RadioBooleanField
            trueOption="Enabled"
            falseOption="Disabled"
            label={ToggleLabelMap.areDealerSettingOverridesEnabled}
            value={props.dealer.areDealerSettingOverridesEnabled || false}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default AreDealerSettingOverridesEnabled;
