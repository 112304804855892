/* eslint-disable react/jsx-no-target-blank */
import React, { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3TextField } from '../common/DR3TextField';
import { Box } from '@material-ui/core';

export function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const dnaAccountId = value;

        const newDealer = {
            ...oldDealer,
            dnaAccountId
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip = 'Links below the DNA ID will open each section for the dealer in Control Center.';

const DnaAccountId: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = props.dealer.dnaAccountId;
    const toolTipText = value ? tooltip : '';

    return (
        <>
            <DR3TextField
                label="DNA Account ID"
                value={value}
                handleChange={handleChange(props.dealer, props.updateDealerCallback)}
                isRequired={false}
                isDNAAccountIdField={true}
                tooltip={toolTipText}
                withMargin={true}
            />
            {toolTipText && (
                <Box width="50%" alignItems="right" marginTop="-2%" marginBottom="1%" marginLeft="auto">
                    <a
                        href={`https://apps.dealer.com/configuration/as/${value}/${value}-admin/manage/users`}
                        target="_blank"
                        style={{ marginRight: '20px' }}
                    >
                        User Manager
                    </a>
                    <a
                        href={`https://apps.dealer.com/leads/as/${value}/${value}-admin/configure/leaddistribution`}
                        target="_blank"
                        style={{ marginRight: '20px' }}
                    >
                        Lead Distribution
                    </a>
                    <a
                        href={`https://apps.dealer.com/analytics/as/${value}/${value}-admin/report/accelerate-digital-retailing`}
                        target="_blank"
                    >
                        Analytics
                    </a>
                </Box>
            )}
        </>
    );
};

export default DnaAccountId;
