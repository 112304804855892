import React from 'react';
import { withAuth } from '@okta/okta-react';
import withApi from '../../utils/withApi';
import CopyProductionDealerForm from './CopyProductionDealerForm';
import { IAuthFn } from '../../utils/AuthTypes';
import { IApi } from '../../utils/Api';
import { Box, Grid, makeStyles, Paper, ThemeProvider } from '@material-ui/core';
import themeOverride from '../layout/theme';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(4),
        color: theme.palette.text.primary
    }
}));
interface IUtilitiesContainerProps {
    auth: IAuthFn;
    api: IApi;
}

const UtilitiesContainer = (props: IUtilitiesContainerProps) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={themeOverride}>
            <Box paddingTop={'6em'}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CopyProductionDealerForm {...props} />
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default withAuth(withApi(UtilitiesContainer));
