import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { HashLink as Link } from 'react-router-hash-link';
import useStyles from './layout/styles';
import { IDealerToManage } from '../store/manager/types';
import { TabSelected } from '../../src/store/manager/types';

interface IAdminMenuProps {
    dealer: IDealerToManage | null;
    tabSelected: TabSelected;
}

export default function AdminMenu(props: IAdminMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event: any) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const classes = useStyles({});

    function getLeasingMenuItem() {
        if (
            !props.dealer ||
            (!props.dealer.products.hasLeasing &&
                (!props.dealer.products.hasNewCashOffer || !props.dealer.products.hasUsedCashOffer))
        ) {
            return null;
        }
        return (
            <Link to="#Leasing">
                <MenuItem onClick={handleClose}>
                    <div className={classes.menuItem}>Leasing</div>
                </MenuItem>
            </Link>
        );
    }

    function showListingSiteMenuOptions() {
        return (
            <div>
                <Button className={classes.button} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Menu
                </Button>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <Link to="#BaseFeatures">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Base Features</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToAdminOptionsGeneral">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Admin Options General</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToPaymentAndCredit">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Payment and Credit</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToIncentives">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Incentives</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToVehicleTradeIn">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Vehicle Trade In</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToConsumerExperienceOptions">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Consumer Experience Options</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToDealDefaultValues">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Deal Default Values</div>
                        </MenuItem>
                    </Link>
                    <Link to="#AdditionalFeaturesOptions">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Additional Features Options</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToContactOptions">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Contact Options</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToCRM">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>CRM</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToPrePurchaseAgreement/Reservation">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>PrePurchase Agreement/Reservation</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToVehicleProtection">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Vehicle Protection</div>
                        </MenuItem>
                    </Link>
                </Menu>
            </div>
        );
    }

    function showGlobalSiteMenuOptions() {
        return (
            <div>
                <Button className={classes.button} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Menu
                </Button>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <Link to="#SponsorSetting">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Inherit Settings From</div>
                        </MenuItem>
                    </Link>
                    <Link to="#DealershipInfo">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Dealership Info</div>
                        </MenuItem>
                    </Link>
                    <Link to="#AdminOptions">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Admin Options</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToPaymentandCredit">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Payment and Credit</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToGlobalReservations">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Reservations</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToGlobalVPP">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Vehicle Protection</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToVehicleTestDrive">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Vehicle Test Drive</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToCRM">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>CRM</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToConsumerExperienceOptions">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Consumer Experience Options</div>
                        </MenuItem>
                    </Link>
                    <Link to="#CreditandFinancing">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Credit and Financing</div>
                        </MenuItem>
                    </Link>
                    {getLeasingMenuItem()}
                    <Link to="#JumpToGlobalAccessories">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Accessories</div>
                        </MenuItem>
                    </Link>
                </Menu>
            </div>
        );
    }

    function showDealerSiteMenuOptions() {
        return (
            <div>
                <Button className={classes.button} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Menu
                </Button>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <Link to="#BaseFeatures">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Base Features</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToAdminOptionsGeneral">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Admin Options General</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToPaymentAndCredit">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Payment and Credit</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToIncentives">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Incentives</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToVehicleTradeIn">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Vehicle Trade-In</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToConsumerExperienceOptions">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Consumer Experience Options</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToDealDefaultValues">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Deal Default Values</div>
                        </MenuItem>
                    </Link>
                    <Link to="#AdditionalFeaturesOptions">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Additional Features Options</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToContactOptions">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Contact Options</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToCRM">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>CRM</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToPrePurchaseAgreement/Reservation">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>PrePurchase Agreement/Reservation</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToVehicleProtection">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuSubItem}>Vehicle Protection</div>
                        </MenuItem>
                    </Link>
                    <Link to="#JumpToAccessories">
                        <MenuItem onClick={handleClose}>
                            <div className={classes.menuItem}>Accessories</div>
                        </MenuItem>
                    </Link>
                </Menu>
            </div>
        );
    }

    function getMenuOptions() {
        switch (props.tabSelected) {
            case TabSelected.GLOBAL:
                return showGlobalSiteMenuOptions();
            case TabSelected.DEALER:
                return showDealerSiteMenuOptions();
            case TabSelected.LISTING:
                return showListingSiteMenuOptions();
        }
    }
    return <div>{getMenuOptions()}</div>;
}
