import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3NumberTextField } from '../common/DR3NumberTextField';

export function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const dealerTrackID = value;
        updateDealerCallback(null, {
            ...oldDealer,
            dealerTrackID
        });
    };
}

// tslint:disable-next-line:variable-name
const DealerTrackID: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.dealerTrackID;
    return (
        <DR3NumberTextField
            label="Dealertrack Dealer ID"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default DealerTrackID;
