import { IDealerLeasing } from '../types';
import { asSafeTerms } from './asSafeTerms';
import { asSafeMileages } from './asSafeMileages';
import { asSafeBoolean, asSafeNumber } from './selectorUtils';

export function asSafeDealerLeasing(leasing?: IDealerLeasing): IDealerLeasing {
    if (!leasing) {
        const defaultLeasing: IDealerLeasing = {
            allowMultipleSecurityDepositPayments: asSafeBoolean(),
            enableUnifyDealSummary: asSafeBoolean(),
            calculateOnMSRP: asSafeBoolean(),
            isDueAtSigninAmountDeduct: asSafeBoolean(),
            isDownPaymentFixedAmount: asSafeBoolean(),
            downPaymentFixedAmount: asSafeNumber(),
            downPaymentPercent: asSafeNumber(),
            isMonthlyPaymentDueAtSigning: asSafeBoolean(),
            mileages: asSafeMileages(),
            terms: asSafeTerms()
        };
        return defaultLeasing;
    }
    const newLeasing: IDealerLeasing = {
        allowMultipleSecurityDepositPayments: asSafeBoolean(leasing.allowMultipleSecurityDepositPayments),
        enableUnifyDealSummary: asSafeBoolean(leasing.enableUnifyDealSummary),
        calculateOnMSRP: asSafeBoolean(leasing.calculateOnMSRP),
        isDueAtSigninAmountDeduct: asSafeBoolean(leasing.isDueAtSigninAmountDeduct),
        isDownPaymentFixedAmount: asSafeBoolean(leasing.isDownPaymentFixedAmount),
        downPaymentFixedAmount: asSafeNumber(leasing.downPaymentFixedAmount),
        downPaymentPercent: asSafeNumber(leasing.downPaymentPercent),
        isMonthlyPaymentDueAtSigning: asSafeBoolean(leasing.isMonthlyPaymentDueAtSigning),
        mileages: asSafeMileages(leasing.mileages),
        terms: asSafeTerms(leasing.terms)
    };
    return newLeasing;
}
