import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import RuleList from './RuleList';
import { IClientContainerProps } from '../types';

const ObfuscationContainer: FunctionComponent<IClientContainerProps> = (props) => {
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <Typography align="center" gutterBottom variant="h4">
                        Obfuscation Setup
                    </Typography>
                </Grid>
            </Grid>
            <RuleList {...props} />
        </div>
    );
};

export default ObfuscationContainer;
