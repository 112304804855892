import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { ErrorMessages } from '../common/utils';
import { DR3NumberStringTextField } from '../common/DR3NumberStringTextField';

export function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        updateDealerCallback(null, {
            ...oldDealer,
            overrideDRSAccountId: value
        });
    };
}
function isRequiredFn(dealer: IDealerToManage) {
    return !!dealer.overrideDRSActive;
}

const tooltip = 'Enter your override account ID';

const OverrideDRSAccountId: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.overrideDRSActive) {
        return null;
    }
    const value = props.dealer.overrideDRSAccountId;
    const isRequired = isRequiredFn(props.dealer);
    let errorText = '';
    if (isRequired && !value) {
        errorText = ErrorMessages.MISSING_FIELD;
    }
    return (
        <DR3NumberStringTextField
            label="eBiz Account ID"
            sublabel="required"
            value={value}
            isRequired={isRequiredFn(props.dealer)}
            errorText={errorText}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltip={tooltip}
        />
    );
};
export default OverrideDRSAccountId;
