import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
    IDealerToManage,
    IValidatorMap,
    IUpdateDealerCallbackFn,
    IValidateCallbackFn,
    ISaveDealerCallbackFn,
    IUploadBrandingExpCallbackFn
} from '../store/manager/types';
import { Box, Typography } from '@material-ui/core';
import Theme from './dealerSiteFields/Theme';
import BrandingExperienceDropzone from './dealerSiteFields/BrandingExperienceDropzone';
import DealerVideoDropZone from './dealerSiteFields/DealerVideoDropZone';
import DealerPosterDropZone from './dealerSiteFields/DealerPosterDropZone';
import LabelSet from './dealerSiteFields/LabelSet';
import IsChatEnabled from './dealerSiteFields/IsChatEnabled';
import IsDIOEnabled from './dealerSiteFields/IsDIOEnabled';
import IsPhoneEnabled from './dealerSiteFields/IsPhoneEnabled';
import PhoneTrackingNumberField from './dealerSiteFields/TrackingPhone';
import IsSmsEnabled from './dealerSiteFields/IsSmsEnabled';
import SmsPhone from './dealerSiteFields/SmsPhone';
import PartnerId from './dealerSiteFields/PartnerID';
import IsTradeInBranded from './dealerSiteFields/IsTradeInBranded';
import VehicleProtectionActive from './dealerSiteFields/VehicleProtectionActive';
import EnablePrePurchaseAgreement from './dealerSiteFields/EnablePrePurchaseAgreement';
import ShowReservationDetail from './dealerSiteFields/ShowReservationDetail';
import TradeInSubaruGtp from './dealerSiteFields/TradeInSubaruGtp';
import TradeInValuationLeadFrom from './dealerSiteFields/TradeInValuationLeadForm';
import TradeInHideExcellent from './dealerSiteFields/KbbHideExcellent';
import KbbIcoURL from './dealerSiteFields/KbbIcoURL';
import IsVDPLeadFormEnabled from './dealerSiteFields/IsVDPLeadFormEnabled';
import CanNegotiateNew from './dealerSiteFields/CanNegotiateNew';
import CanNegotiateUsed from './dealerSiteFields/CanNegotiateUsed';
import themeOverride from './layout/theme';
import ExcludeStdCashIncsLoan from './dealerSiteFields/ExcludeStdCashIncsLoan';
import ExcludeSpecialAPRLoanOffersNewCar from './dealerSiteFields/ExcludeSpecialAPRLoanOffersNewCar';
import ExcludeSpecialAPRLoanOffersUsedCar from './dealerSiteFields/ExcludeSpecialAPRLoanOffersUsedCar';
import ExcludeStdCashIncsLease from './dealerSiteFields/ExcludeStdCashIncsLease';
import ExcludeStdCashIncsCash from './dealerSiteFields/ExcludeStdCashIncsCash';
import ExcludeSpecialAPRLeaseOffersNewCar from './dealerSiteFields/ExcludeSpecialAPRLeaseOffersNewCar';
import EnableMaxCashIncentives from './dealerSiteFields/EnableMaxCashIncentives';
import IsWidgetHeightDynamic from './dealerSiteFields/IsWidgetHeightDynamic';
import HasWidgetNew from './dealerSiteFields/HasWidgetNew';
import HasWidgetUsed from './dealerSiteFields/HasWidgetUsed';
import HasUsedCashOffer from './dealerSiteFields/HasUsedCashOffer';
import HasNewCashOffer from './dealerSiteFields/HasNewCashOffer';
import ReviewDealUrlEnabled from './dealerSiteFields/ReviewDealUrlEnabled';
import IsRedesignEnabled from './dealerSiteFields/IsRedesignEnabled';
import AccessoriesActive from './dealerSiteFields/AccessoriesActive';
import LeadGatePaymentOnDS from './dealerSiteFields/LeadGatePaymentOnDS';
import AMDTradeInMethodsContainer from './AMDTradeInMethods/AMDTradeInMethodsContainer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(4),
        color: theme.palette.text.primary
    },
    adminMessage: {
        paddingTop: theme.spacing(4)
    }
}));

export interface IDealerFieldsListUIProps {
    dealer: IDealerToManage | null;
    validatorMap: IValidatorMap;
    updateDealerCallback: IUpdateDealerCallbackFn;
    validateCallback: IValidateCallbackFn;
    saveDealerCallback: ISaveDealerCallbackFn;
    uploadBrandingExpCallback: IUploadBrandingExpCallbackFn;
    admin: boolean;
    dealerFieldsListSelected: boolean;
    classes?: any;
}
interface IDealerFieldsWrapperProps {
    classes: ReturnType<typeof useStyles>;
}
// tslint:disable-next-line:variable-name
const DealerFieldsWrapper: FunctionComponent<IDealerFieldsWrapperProps> = ({ classes, children }) => {
    return (
        <div className={`${classes.root} globalFieldsWrapper`}>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};
interface IDealerFieldsSectionProps {
    title: string;
    hidden?: boolean;
    subtitle?: string;
    paddingTopProp?: string;
}
const DealerFieldsSection: FunctionComponent<IDealerFieldsSectionProps> = ({ title, hidden, subtitle, children }) => {
    const id = title.split(' ').join('');
    if (hidden) {
        return null;
    } else {
        return (
            <Box id={id} paddingTop={'3.5em'}>
                <Typography align="center" gutterBottom variant="h4">
                    {title}
                </Typography>
                <Typography align="center" gutterBottom variant="h5" color="inherit">
                    {subtitle}
                </Typography>
                {children}
            </Box>
        );
    }
};

interface IDealerFieldsSubSectionProps {
    title: string;
}
// tslint:disable-next-line:variable-name
const DealerFieldsSubSection: FunctionComponent<IDealerFieldsSubSectionProps> = ({ title, children }) => {
    return (
        <Box paddingTop={'2em'}>
            <Typography align="center" gutterBottom variant="h5">
                {title}
            </Typography>
            {children}
        </Box>
    );
};

export default function DealerFieldsListUI(props: IDealerFieldsListUIProps): JSX.Element {
    const classes = useStyles();
    const dealer = props.dealer;
    if (!dealer || !props.dealerFieldsListSelected) {
        return <div />;
    }

    const pilotFeaturesText = 'This section is only to be used by product and readiness teams!';

    const shouldShowTradeInHideExcellent =
        !!dealer && (dealer.tradeInValuationProviderKBB || dealer.tradeInValuationProviderKBBSalesView);

    return (
        <ThemeProvider theme={themeOverride}>
            <DealerFieldsWrapper classes={classes}>
                {props.admin && (
                    <Typography align="center" color="secondary" className={classes.adminMessage}>
                        ADMIN MODE
                        <br />
                        For end-to-end testing of hidden fields.
                    </Typography>
                )}
                <div id={'JumpToAdminOptionsGeneral'}></div>

                <DealerFieldsSection title={'Base Features'}>
                    <DealerFieldsSubSection title={'Admin Options General'} />
                    <HasWidgetNew {...props} />
                    <HasWidgetUsed {...props} />
                    <Theme {...props} />
                    <BrandingExperienceDropzone {...props} />

                    <DealerVideoDropZone {...props} />
                    <DealerPosterDropZone {...props} />

                    <LabelSet {...props} />
                    <PartnerId {...props} />
                    <div id={'JumpToPaymentAndCredit'}></div>
                    <IsWidgetHeightDynamic {...props} />

                    <DealerFieldsSubSection title={'Payment and Credit'} />
                    <HasUsedCashOffer {...props} />
                    <div id={'JumpToIncentives'}></div>
                    <HasNewCashOffer {...props} />
                    <LeadGatePaymentOnDS {...props} />

                    <DealerFieldsSubSection title="Incentives" />
                    <ExcludeStdCashIncsLoan {...props} />
                    <ExcludeSpecialAPRLoanOffersNewCar {...props} />
                    <ExcludeSpecialAPRLoanOffersUsedCar {...props} />
                    <ExcludeStdCashIncsLease {...props} />
                    <ExcludeSpecialAPRLeaseOffersNewCar {...props} />
                    <ExcludeStdCashIncsCash {...props} />
                    <div id={'JumpToVehicleTradeIn'}></div>
                    <EnableMaxCashIncentives {...props} />

                    <DealerFieldsSubSection title="Vehicle Trade-In" />

                    <AMDTradeInMethodsContainer {...props} />

                    <KbbIcoURL {...props} />
                    <IsTradeInBranded {...props} />
                    <TradeInValuationLeadFrom {...props} />
                    <div id={'JumpToConsumerExperienceOptions'}></div>
                    <TradeInSubaruGtp {...props} />
                    {shouldShowTradeInHideExcellent && <TradeInHideExcellent {...props} />}

                    <DealerFieldsSubSection title="Consumer Experience Options" />
                    <div id={'JumpToDealDefaultValues'}></div>
                    <IsVDPLeadFormEnabled {...props} />

                    <DealerFieldsSubSection title="Deal Default Values" />
                    <CanNegotiateNew {...props} />
                    <CanNegotiateUsed {...props} />
                    <div id={'JumpToContactOptions'}></div>
                </DealerFieldsSection>

                <DealerFieldsSection title={'Additional Features Options'}>
                    <DealerFieldsSubSection title={'Contact Options'} />
                    <IsChatEnabled {...props} />
                    <IsPhoneEnabled {...props} />
                    <PhoneTrackingNumberField {...props} />
                    <div id={'JumpToCRM'}></div>
                    <IsSmsEnabled {...props} />
                    <SmsPhone {...props} />

                    <DealerFieldsSubSection title="CRM" />
                    <div id={'JumpToPrePurchaseAgreement/Reservation'}></div>
                    <ReviewDealUrlEnabled {...props} />

                    <DealerFieldsSubSection title="Pre-Purchase Agreement/Reservation" />
                    <EnablePrePurchaseAgreement {...props} />
                    <ShowReservationDetail {...props} />
                    <div id={'JumpToVehicleProtection'}></div>

                    <DealerFieldsSubSection title="Vehicle Protection" />
                    <VehicleProtectionActive {...props} />
                </DealerFieldsSection>

                <DealerFieldsSection title="Pilot Features">
                    <Typography align="center" color="error">
                        {pilotFeaturesText}
                    </Typography>
                    <IsRedesignEnabled {...props} />
                    <IsDIOEnabled {...props} />
                </DealerFieldsSection>

                <DealerFieldsSection title="Accessories">
                    <div id={'JumpToAccessories'}></div>
                    <AccessoriesActive {...props} />
                </DealerFieldsSection>
            </DealerFieldsWrapper>
        </ThemeProvider>
    );
}
