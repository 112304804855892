import axios, { AxiosError, AxiosResponse } from 'axios';
import { Config } from '../../config';
import { DealXGClientMap, DealXGSuccesResponse, ToggleDealXGClient } from './types';
import { buildServiceResponse } from './utils';

const { protocol, host, base } = Config.services.api;
const basePath = `${protocol}${host}${base}dealxg/client`;

export const getClientsList = async (accessToken: string): Promise<DealXGClientMap[]> => {
    const uri = basePath;

    try {
        const response: { data: { clients: DealXGClientMap[] } } = await axios.get(uri, {
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            responseType: 'json'
        });
        return response.data.clients;
    } catch (error) {
        console.error(`Error: ${error}`);
    }

    return [];
};

export const getClientById = async (accessToken: string, clientId: string): Promise<DealXGClientMap> => {
    const uri = `${basePath}/${clientId}`;

    try {
        const response: AxiosResponse<DealXGSuccesResponse> = await axios.get(uri, {
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            responseType: 'json'
        });
        return response.data.client;
    } catch (error) {
        console.error(`Error: ${error}`);
    }

    return { clientId, clientName: 'NA' } as DealXGClientMap;
};

export const saveClient = async (accessToken: string, body: DealXGClientMap) => {
    const uri = basePath;
    try {
        const response: AxiosResponse<DealXGSuccesResponse> = await axios.post(uri, body, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        return buildServiceResponse({ statusCode: response.status, data: response.data.client });
    } catch (error) {
        console.error(`Error: ${error}`);
        const axiosErrorResponse = (error as AxiosError).response as AxiosResponse;
        return buildServiceResponse({ statusCode: axiosErrorResponse.status, error: axiosErrorResponse.data });
    }
};

export const updateClient = async (accessToken: string, body: DealXGClientMap) => {
    const { clientId } = body;
    const uri = `${basePath}/${clientId}`;
    try {
        const response: AxiosResponse<DealXGSuccesResponse> = await axios.put(uri, body, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        return buildServiceResponse({ statusCode: response.status, data: response.data.client });
    } catch (error) {
        console.error(`Error: ${error}`);
        const axiosErrorResponse = (error as AxiosError).response as AxiosResponse;
        return buildServiceResponse({ statusCode: axiosErrorResponse.status, error: axiosErrorResponse.data });
    }
};

export const toggleClient = async (accessToken: string, clientId: string, disable: boolean) => {
    const body: ToggleDealXGClient = {
        disable,
        changedBy: {
            user: ''
        }
    };
    const uri = `${basePath}/${clientId}/toggle`;
    try {
        const response: AxiosResponse<DealXGSuccesResponse> = await axios.put(uri, body, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        return buildServiceResponse({ statusCode: response.status });
    } catch (error) {
        console.error(`Error: ${error}`);
        const axiosErrorResponse = (error as AxiosError).response as AxiosResponse;
        return buildServiceResponse({ statusCode: axiosErrorResponse.status, error: axiosErrorResponse.data });
    }
};
