import React from 'react';
import { Provider } from 'react-redux';
import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';
import { Router, Route } from 'react-router';
import { RootStore } from '../store';
import { createBrowserHistory } from 'history';
import DealerManagerContainer from '../containers/DealerManagerContainer';
import DealerSearchContainer from '../containers/DealerSearchContainer';
import DealXGClientByIdManagerContainer from './dealxgClientSetup/DealXGClientByIdManagerContainer';

interface IRootProps {
    store: RootStore;
}
const history = createBrowserHistory();
const oktaOpts = {
    issuer: 'https://coxauto.okta.com/oauth2/default',
    clientId: '0oa1hk5i3yeUTysdp0h8',
    redirectUri: `${window.location.origin}/implicit/callback`
};

export default function Root(props: IRootProps) {
    return (
        <Provider store={props.store}>
            <Router history={history}>
                <Security issuer={oktaOpts.issuer} client_id={oktaOpts.clientId} redirect_uri={oktaOpts.redirectUri}>
                    <SecureRoute path="/:accessories?" exact component={DealerSearchContainer} />
                    <Route path="/implicit/callback" component={ImplicitCallback} />
                    <SecureRoute path={'/dealers/:dealerId/manage/:admin?'} exact component={DealerManagerContainer} />
                    <SecureRoute path={'/dealxg/client/:clientId/manage'} exact component={DealXGClientByIdManagerContainer} />
                </Security>
            </Router>
        </Provider>
    );
}
