import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    spinner: {
        position: 'relative',
        bottom: '-200%',
        left: '49%'
    },
    info: {
        paddingTop: '20%',
        paddingLeft: '42%'
    },
    partnerMappingsSection: {
        height: 'auto'
    }
});
