import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

type Condition = 'new' | 'used';

const noDefaultOption = {
    optionLabel: 'No Default',
    optionValue: '0'
};

const tooltip = 'If no default is chosen, the system will display the best possible payment for the customer';

const mapping = {
    new: 'defaultTermNew',
    used: 'defaultTermUsed'
};

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn, condition: Condition) {
    return (value: string): void => {
        const updated = {
            [mapping[condition]]: Number(value)
        };
        updateDealerCallback(null, {
            ...oldDealer,
            financing: {
                ...oldDealer.financing,
                ...updated
            }
        });
    };
}

function getSortedDistinctTermsForCondition(dealer: IDealerToManage, condition: Condition) {
    const distinctTerms: Set<number> = new Set();
    const tiersForCondition = dealer.creditTiers.filter(({ type }) => type === condition);
    tiersForCondition.forEach((tier) => {
        tier.aprs.forEach((apr) => {
            distinctTerms.add(apr.term);
        });
    });
    return Array.from(distinctTerms).sort();
}

function generateOptions(terms: number[]) {
    const dynamicOptions: IDropdownOptions[] = terms.map(
        (term): IDropdownOptions => ({
            optionLabel: term.toString(),
            optionValue: term.toString()
        })
    );
    return [noDefaultOption, ...dynamicOptions];
}

const FinancingDefaultTermDropdowns: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer?.creditTiers) {
        return null;
    }

    const newTerms = getSortedDistinctTermsForCondition(props.dealer, 'new');
    const newOptions = generateOptions(newTerms);
    const currentNewValueIsValid = newTerms.includes(props.dealer.financing.defaultTermNew);
    const newValue = currentNewValueIsValid ? props.dealer.financing.defaultTermNew : noDefaultOption.optionValue;

    const usedTerms = getSortedDistinctTermsForCondition(props.dealer, 'used');
    const usedOptions = generateOptions(usedTerms);
    const currentUsedValueIsValid = usedTerms.includes(props.dealer.financing.defaultTermUsed);
    const usedValue = currentUsedValueIsValid ? props.dealer.financing.defaultTermUsed : noDefaultOption.optionValue;

    return (
        <>
            <DR3DropdownField
                label="Default Term New"
                tooltip={tooltip}
                value={newValue.toString()}
                handleChange={handleChange(props.dealer, props.updateDealerCallback, 'new')}
                options={newOptions}
            />
            <DR3DropdownField
                label="Default Term Used"
                tooltip={tooltip}
                value={usedValue.toString()}
                handleChange={handleChange(props.dealer, props.updateDealerCallback, 'used')}
                options={usedOptions}
            />
        </>
    );
};
export default FinancingDefaultTermDropdowns;
