import { Button, Grid, Typography } from '@material-ui/core';
import { IClientContainerProps } from '../types';
import React, { FunctionComponent } from 'react';
import { CSVLink } from 'react-csv';
import { processDealAccessPermissionsFile } from '../utils';

export const DealAccessPermissionsCsv: FunctionComponent<IClientContainerProps> = ({ client, setClient }) => {
    const fileName = 'deal-access-permissions';

    const dealerMapData = [
        ...(client.dealerMap?.map((dealerMap) => {
            const { name, value } = dealerMap;
            return { partnerName: name, partnerDealerId: value };
        }) || [])
    ];

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        processDealAccessPermissionsFile(event, client, setClient);
    };

    return (
        <div>
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <Typography align="center" gutterBottom variant="h4">
                        Deal Access Permissions
                    </Typography>
                </Grid>
                <Grid container xs={12} justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <CSVLink data={dealerMapData} filename={fileName}>
                            Download Template
                        </CSVLink>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" component="label" color="primary">
                            Upload Permissions
                            <input id="upload-input" accept="text/csv" type="file" hidden onChange={handleFileUpload} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
