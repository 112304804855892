import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

const tooltip =
    'Disclaimer copy will show on both the VDP and SDP lead forms - ' +
    '"This is not an online credit application." This copy WILL NOT show on the credit application.';

const handleChange = (oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) => {
    return (value: boolean): void => {
        const newDealer = {
            ...oldDealer,
            showCreditApplicationDisclaimer: value
        };
        updateDealerCallback(null, newDealer);
    };
};

export const ShowCreditApplicationDisclaimer: FunctionComponent<ICommonManagerFieldProps> = ({ dealer, updateDealerCallback }) => {
    if (!dealer) {
        return null;
    }

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.showCreditApplicationDisclaimer}
            value={dealer.showCreditApplicationDisclaimer}
            handleChange={handleChange(dealer, updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
