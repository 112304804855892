import React, { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3TextField } from '../common/DR3TextField';
import { validateURL } from '../common/utils';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const privacyNoticeUrl = value;
        updateDealerCallback(null, {
            ...oldDealer,
            privacyNoticeUrl
        });
    };
}

const tooltip =
    'Clicking the "Privacy Notice" link on the Accelerate Credit Application will open the url provided here.' +
    ' If there is no url provided, then it will link to a default AMD-provided Privacy Notice. ' +
    'http:// or https:// must be included.';

const CustomizedPrivacyNotice: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.privacyNoticeUrl;
    let errorText = '';
    // this field is not required, and can be left blank
    if (value !== '') {
        const valid = validateURL(value);
        valid ? (errorText = '') : (errorText = 'Invalid URL');
    }
    return (
        <DR3TextField
            label="Credit App Privacy Notice URL"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            isRequired={false}
            tooltip={tooltip}
            errorText={errorText}
        />
    );
};

export default CustomizedPrivacyNotice;
