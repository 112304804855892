import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, ICommonManagerFieldProps } from '../../store/manager/types';
import { ToggleLabelMap } from '../../constants/ToggleConstants';
import { DR3CheckboxField } from '../common/DR3CheckboxField';
import { DR3FieldWrapper } from '../common/DR3FieldWrapper';
import { Box } from '@material-ui/core';

type showFlexiblePaymentFields = 'showFlexiblePaymentOptionsAMD' | 'showFlexiblePaymentOptionsSalesView';

export const ShowFlexiblePaymentOptions: FunctionComponent<ICommonManagerFieldProps> = ({ dealer, updateDealerCallback }) => {
    if (!dealer) {
        return null;
    }

    const tooltip = 'This feature allows the shopper to see multiple payment options simultaneously.';

    const handleChange = (fieldName: showFlexiblePaymentFields, checked: boolean): void => {
        const oldDealer = dealer as IDealerToManage;
        const newDealer = {
            ...oldDealer,
            [fieldName]: checked
        };

        updateDealerCallback(null, newDealer);
    };

    return (
        <DR3FieldWrapper label={ToggleLabelMap.showFlexiblePaymentOptions} tooltip={tooltip}>
            <Box margin={'8px 25px'}>
                <span className="field-wrapper" style={{ marginRight: '10px' }}>
                    <DR3CheckboxField
                        name="amd"
                        label={ToggleLabelMap.amd}
                        checked={dealer.showFlexiblePaymentOptionsAMD}
                        handleChange={(checked) => handleChange('showFlexiblePaymentOptionsAMD', checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </span>
                <DR3CheckboxField
                    name="salesView"
                    label={ToggleLabelMap.salesView}
                    checked={dealer.showFlexiblePaymentOptionsSalesView}
                    handleChange={(checked) => handleChange('showFlexiblePaymentOptionsSalesView', checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </Box>
        </DR3FieldWrapper>
    );
};
