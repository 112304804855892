import React, { FunctionComponent, useEffect, useState } from 'react';
import { IClientContainerProps } from '../types';
import useStyles from '../../layout/styles';
import { FormHelperText } from '@material-ui/core';
import { DR3FieldWrapper } from '../../common/DR3FieldWrapper';
import { ValidationTextField } from '../../common/ValidationTextField';

const Note: FunctionComponent<IClientContainerProps> = ({ client, setClient }) => {
    const [noteValue, setNote] = useState('');
    const classes = useStyles({});
    const errorText = '';

    useEffect(() => {
        setNote(client.note ?? '');
    }, [client]);

    const handleChange = () => {
        const newClient = {
            ...client,
            note: noteValue
        };
        setClient(newClient);
    };

    return (
        <DR3FieldWrapper label={''}>
            <ValidationTextField
                error={!!errorText}
                variant="outlined"
                className={classes.textField}
                required={false}
                value={noteValue}
                onChange={(event) => {
                    setNote(event.target.value);
                }}
                onBlur={handleChange}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
export default Note;
