import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { ErrorMessages } from '../common/utils';
import { DR3NullableNumberTextField } from '../common/DR3NullableNumberTextField';

export function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const dealertrackOverrideId = value;
        const leadRoutingOverrideSettings = {
            ...oldDealer.leadRoutingOverrideSettings,
            dealertrackOverrideId
        };
        const newDealer = {
            ...oldDealer,
            leadRoutingOverrideSettings
        };
        updateDealerCallback(null, newDealer);
    };
}
function isRequiredFn(dealer: IDealerToManage) {
    return !!dealer.leadRoutingOverrideSettings.leadRoutingOverrideEnabled;
}

const tooltip = 'This field requires MMD ID, the DT lead & Credit App will be sent to this location';

const DealertrackOverrideId: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.leadRoutingOverrideSettings.leadRoutingOverrideEnabled) {
        return null;
    }
    const value = props.dealer.leadRoutingOverrideSettings.dealertrackOverrideId;
    const isRequired = isRequiredFn(props.dealer);
    let errorText = '';
    if (isRequired && !value) {
        errorText = ErrorMessages.MISSING_FIELD;
    }
    return (
        <DR3NullableNumberTextField
            label="Dealertrack MMD ID Override"
            sublabel="required"
            value={value}
            isRequired={isRequiredFn(props.dealer)}
            errorText={errorText}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltip={tooltip}
        />
    );
};
export default DealertrackOverrideId;
