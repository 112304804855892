import React from 'react';
import { FunctionComponent } from 'react';
import { ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3ReadOnlyTextField } from '../common/DR3ReadOnlyTextField';

const buildStandaloneCreditAppUrlForDealer = (dealerId: number) => {
    if (!dealerId) {
        return '';
    }

    switch (process.env.REACT_APP_ENV) {
        case 'prod':
            return `https://secure.accelerate.dealer.com/dealer/${dealerId}`;

        case 'uat':
            return `https://secure-uat.accelerate.dealer.com/dealer/${dealerId}`;

        case 'qa':
            return `https://secure-qa.accelerate.dealer.com/dealer/${dealerId}`;

        case 'dev':
            return `https://secure-dev.accelerate.dealer.com/dealer/${dealerId}`;

        default:
            return `https://secure-dev.accelerate.dealer.com/dealer/${dealerId}`;
    }
};

const StandaloneAccelerateCreditAppUrl: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const showField = !!props.dealer.productSet.standaloneCreditApp;

    if (!showField) {
        return null;
    }

    return (
        <DR3ReadOnlyTextField
            label="Standalone Credit App URL"
            value={buildStandaloneCreditAppUrlForDealer(props.dealer.dealerId)}
        />
    );
};
export default StandaloneAccelerateCreditAppUrl;
