import React from 'react';
import { FunctionComponent } from 'react';
import {
    IDealerToManage,
    IDropdownOptions,
    IDealerCreditTier,
    IUpdateDealerCallbackFn,
    ICommonManagerFieldProps
} from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const lowScore = value;
        oldDealer.creditTiers.forEach((tier) => {
            if ((tier.name === 'Poor' || tier.name === 'Low') && tier.type === 'new') {
                tier.lowScore = parseInt(lowScore, 10);
            }
        });
        updateDealerCallback(null, {
            ...oldDealer,
            ...oldDealer.creditTiers
        });
    };
}

const scoreOptions: IDropdownOptions[] = [
    {
        optionValue: '580',
        optionLabel: '580'
    },
    {
        optionValue: '530',
        optionLabel: '530'
    },
    {
        optionValue: '480',
        optionLabel: '480'
    },
    {
        optionValue: '430',
        optionLabel: '430'
    },
    {
        optionValue: '380',
        optionLabel: '380'
    },
    {
        optionValue: '330',
        optionLabel: '330'
    },
    {
        optionValue: '280',
        optionLabel: '280'
    },
    {
        optionValue: '230',
        optionLabel: '230'
    },
    {
        optionValue: '180',
        optionLabel: '180'
    },
    {
        optionValue: '130',
        optionLabel: '130'
    },
    {
        optionValue: '80',
        optionLabel: '80'
    },
    {
        optionValue: '30',
        optionLabel: '30'
    },
    {
        optionValue: '0',
        optionLabel: '0'
    }
];

const NewVehiclePoorCreditDropdown: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    let creditTier: IDealerCreditTier = props.dealer.creditTiers[0];
    props.dealer.creditTiers = Object.values(props.dealer.creditTiers);
    props.dealer.creditTiers.forEach((tier) => {
        if ((tier.name === 'Poor' || tier.name === 'Low') && tier.type === 'new') {
            creditTier = tier;
        }
    });

    return (
        <DR3DropdownField
            label={'Poor Credit Low Score - New Vehicles'}
            value={creditTier.lowScore.toString()}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={scoreOptions}
        />
    );
};
export default NewVehiclePoorCreditDropdown;
