import React from 'react';
import { FunctionComponent } from 'react';
import { IDropdownOptions } from '../../../store/manager/types';
import { IClientContainerProps, DealXGClientMap } from '../types';
import { DR3DropdownField } from '../../common/DR3DropdownField';

const ClientDefaultPersona: FunctionComponent<IClientContainerProps> = ({ client, setClient }) => {
    function handleChange(oldClient: DealXGClientMap, updateClientCallback: React.Dispatch<React.SetStateAction<any>>) {
        return (value: string): void => {
            const name = value;
            const newClient = {
                ...oldClient,
                defaultChangedBy: {
                    ...oldClient.defaultChangedBy,
                    persona: name
                }
            };
            updateClientCallback(newClient);
        };
    }

    const options: IDropdownOptions[] = [
        {
            optionValue: 'Shopper',
            optionLabel: 'Shopper'
        },
        {
            optionValue: 'Dealer',
            optionLabel: 'Dealer'
        },
        {
            optionValue: 'Dealmaker',
            optionLabel: 'Dealmaker'
        },
        {
            optionValue: 'System',
            optionLabel: 'System'
        }
    ];

    return (
        <DR3DropdownField
            label="Default Persona"
            value={client.defaultChangedBy?.persona ?? 'Shopper'}
            handleChange={handleChange(client, setClient)}
            options={options}
        />
    );
};
export default ClientDefaultPersona;
