import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioStringField } from '../common/DR3RadioStringField';
import { convertToCamelCase } from '../common/utils';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const locationToSendLeadsUsed = value;
        const newDealer = {
            ...oldDealer,
            locationToSendLeadsUsed
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip =
    'This will only affect Used Vehicles. Select Vehicle Owner if you want to use settings and send leads to the dealer where ' +
    'vehicle is physically located. Select Website Owner if you want to use settings and send leads to dealer associated ' +
    'with the website from where lead is sent.';

const LocationToSendLeadsUsed: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.locationToSendLeadsUsed || convertToCamelCase('Vehicle Owner');
    return (
        <DR3RadioStringField
            trueOption="Website Owner"
            falseOption="Vehicle Owner"
            label="USED VEHICLES: Lead, payment and other defaults based on dealer Id of"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltip={tooltip}
        />
    );
};
export default LocationToSendLeadsUsed;
