import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

// constants
import { ToggleLabelMap } from '../../constants/ToggleConstants';
import { DealCentralCMSToggle } from '../../constants/AdminConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const dealCentralCMSToggle = value;

        updateDealerCallback(null, {
            ...oldDealer,
            dmsIntegration: dealCentralCMSToggle
        });
    };
}

const defaultOptions: IDropdownOptions[] = [
    {
        optionValue: DealCentralCMSToggle.OFF,
        optionLabel: DealCentralCMSToggle.OFF
    },
    {
        optionValue: DealCentralCMSToggle.CDMS,
        optionLabel: DealCentralCMSToggle.CDMS
    },
    {
        optionValue: DealCentralCMSToggle.CMDPlus,
        optionLabel: DealCentralCMSToggle.CMDPlus
    }
];

const DealCentralCMSIntegration: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = props.dealer.dmsIntegration || DealCentralCMSToggle.OFF;
    const tooltip = 'This feature allows Dealers to push deals to DMS+ or CDMS from Deal Central';

    return (
        <DR3DropdownField
            label={ToggleLabelMap.enableDMSIntegration}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={defaultOptions}
            tooltip={tooltip}
        />
    );
};
export default DealCentralCMSIntegration;
