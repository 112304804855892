import * as React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { ToggleLabelMap } from '../../constants/ToggleConstants';
import { DR3RadioStringField } from '../common/DR3RadioStringField';

type ValueOption = 'yes' | 'no';

const valueMappings: Record<ValueOption, { hasAccessories: boolean; isAccessoriesCatalogEnabled: boolean }> = {
    yes: { hasAccessories: false, isAccessoriesCatalogEnabled: true },
    no: { hasAccessories: false, isAccessoriesCatalogEnabled: false }
};

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const newDealer = {
            ...oldDealer,
            products: {
                ...oldDealer.products,
                ...valueMappings[value as ValueOption]
            }
        };
        updateDealerCallback(null, newDealer);
    };
}

const toolTip = `Setting "Is Accessories Catalog Active?" to "Yes" will enable accessories from the PartSites catalog; 
  to "No" - accessories catalogues are disabled (only possible to add accessories manually via MV)`;

const AccessoriesActive: FunctionComponent<ICommonManagerFieldProps> = ({ dealer, updateDealerCallback }) => {
    if (!dealer) {
        return null;
    }

    let value: ValueOption = 'no';
    if (dealer.products.isAccessoriesCatalogEnabled) {
        value = 'yes';
    }

    return (
        <DR3RadioStringField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.hasAccessories}
            value={value}
            tooltip={toolTip}
            handleChange={handleChange(dealer, updateDealerCallback)}
        />
    );
};

export default AccessoriesActive;
