import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const enableDarwin = value;
        const newDealer = {
            ...oldDealer,
            enableDarwin
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip =
    'Turning on "Enable Darwin" will display additional calls to action (CTAs) on the Manager View screen. ' +
    'Those CTAs allow a user to send a MV deal to Darwin so they can upsell aftermarket products, ' +
    'save a new version back to MV, and generate AM contracts that can be saved to the proper Dealertrack deal jacket.';

const EnableDarwin: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.enableDarwin;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.enableDarwin}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default EnableDarwin;
