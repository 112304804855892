import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const enableCheckoutAnywhereInSV = value;
        const newDealer = {
            ...oldDealer,
            enableCheckoutAnywhereInSV
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip =
    'F&I Intelligence & Automation: Turning this feature on will enable the user in Sales View to begin an automated, ' +
    'online checkout process, including credit, ID verification, doc uploads, and digital contract signing.';

const EnableCheckoutAnywhereInSV: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.enableCheckoutAnywhereInSV;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.enableCheckoutAnywhereInSV}
            value={value}
            tooltipProps={tooltip}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default EnableCheckoutAnywhereInSV;
