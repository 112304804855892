import { HttpVerb } from '@makemydeal/dr-auth-utilities';
import { IAuthFn, IWithAuthProps } from '../../utils/AuthTypes';
import { RouteComponentProps } from 'react-router-dom';
import { Options } from 'material-table';

export type ChangedBy = {
    applicationName?: string;
    persona: 'Shopper' | 'Dealer' | 'Dealmaker' | 'System';
    dealerUser?: string;
};

export interface IDealXGClientManagerProps extends IWithAuthProps {
    auth: IAuthFn;
}

export type DealXGClientManagerByIdProps = { clientId: string };

export type IDealXGClientManagerByIdProps = IWithAuthProps & RouteComponentProps<DealXGClientManagerByIdProps>;

export type ClientChangedBy = { user: string; timeStamp: Date };

export type BaseDealXGClient = {
    clientId: string;
    clientName?: string;
    disable: boolean;
};

export type DealXGClientMap = BaseDealXGClient & {
    dealerMap: IDealerMap[];
    webhooks?: IWebhookInfo[];
    defaultChangedBy?: ChangedBy;
    meta?: { changedBy: ClientChangedBy[] };
    note?: string;
    obfuscationRuleName?: string;
};

export type DealXGClientMapDisplay = BaseDealXGClient & {
    defaultAppName: string;
    defaultPersona: string;
    webhookUrl: string;
    webhookHttpMethod: string;
};

export type IDealerMap = {
    name: string;
    value: string;
};

export type IWebhookInfo = {
    url: string;
    httpMethod: HttpVerb;
    basicAuth?: {
        username: string;
        password: string;
    };
};

export type IClientContainerProps = {
    isNew: boolean;
    client: DealXGClientMap;
    setClient: React.Dispatch<React.SetStateAction<any>>;
    setShowAlert?: React.Dispatch<React.SetStateAction<any>>;
};

export type ServiceResponse<T = any> = {
    data?: T;
    error?: any;
    ok: boolean;
    statusCode?: number;
};

export type DealXGSuccesResponse = { client: DealXGClientMap };

export type Alert = {
    show: boolean;
    variant: 'error' | 'info' | 'success' | 'warning';
    message: string[];
    className: 'alertSuccess' | 'alertError';
};

export const defaultAlert: Alert = {
    show: false,
    variant: 'success',
    message: ['Changes saved successfully!'],
    className: 'alertSuccess'
};
export const errorAlert: Alert = { show: true, variant: 'error', message: [''], className: 'alertError' };

export type ToggleDealXGClient = {
    disable: boolean;
    changedBy: {
        user: string;
    };
};

export interface IDR3DealXGClientSearchTableProps {
    clients: DealXGClientMap[];
    displayClients: DealXGClientMapDisplay[];
    searchTableOptions: Options<any>;
    checked: boolean;
    setClients: React.Dispatch<React.SetStateAction<any>>;
    setDisplayClients: React.Dispatch<React.SetStateAction<any>>;
    auth: IAuthFn;
}
