import { IDealerReservation } from '../types';
import { asSafeBoolean, asSafeEmptyString, asSafeNumber } from './selectorUtils';

export function asSafeDealerReservation(reservation?: IDealerReservation): IDealerReservation {
    if (!reservation) {
        const defaultReservation: IDealerReservation = {
            showReservationDetail: asSafeBoolean(),
            accountToken: asSafeEmptyString(),
            acceptorId: asSafeEmptyString(),
            accountId: asSafeEmptyString(),
            amount: asSafeNumber(),
            depositType: asSafeEmptyString(),
            holdPeriod: asSafeNumber(),
            reservationType: asSafeEmptyString(),
            terminalId: asSafeEmptyString()
        };
        return defaultReservation;
    }
    const newReservation: IDealerReservation = {
        showReservationDetail: asSafeBoolean(reservation.showReservationDetail),
        accountToken: asSafeEmptyString(reservation.accountToken),
        acceptorId: asSafeEmptyString(reservation.acceptorId),
        accountId: asSafeEmptyString(reservation.accountId),
        amount: asSafeNumber(reservation.amount),
        depositType: asSafeEmptyString(reservation.depositType),
        holdPeriod: asSafeNumber(reservation.holdPeriod),
        reservationType: asSafeEmptyString(reservation.reservationType),
        terminalId: asSafeEmptyString(reservation.terminalId)
    };
    return newReservation;
}
