import React, { FunctionComponent } from 'react';
import debounce from 'lodash/debounce';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import {
    IDealerToManage,
    IUpdateSearchedDealersCallbackFn,
    ILoadSearchedDealersFn,
    ICloneDealerCallbackFn
} from '../store/manager/types';
import { IWithAuthProps, IAuthFn } from '../utils/AuthTypes';
import { Box, Typography } from '@material-ui/core';
// tslint:disable-next-line:no-var-requires
import { Options } from 'material-table';
import { DR3FieldWrapper } from './common/DR3FieldWrapper';
import { DR3DealerSearchTable } from './common/DR3DealerSearchTable';
import * as queryString from 'query-string';
require('./layout/css/styles.scss');

const dealerSearchFields = [
    'creditProvider',
    'routeOnePartyId',
    'dealerId',
    'crmIntegration',
    'ownerId',
    'dnaAccountId',
    'dealerTrackID',
    'esntialPartnerId',
    'esntialPartnerDealerId',
    'name',
    'website',
    'theme',
    'sponsorSetting',
    'leadRoutingOverrideSettings.leadRoutingOverrideEnabled',
    'products',
    'listingOverride.products'
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(4),
        color: theme.palette.text.primary
    }
}));
interface IDealerSearchDisplayProps extends IWithAuthProps {
    dealers: IDealerToManage[] | null;
    updateSearchedDealersCallback: IUpdateSearchedDealersCallbackFn;
    loadSearchedDealers: ILoadSearchedDealersFn;
    auth: IAuthFn;
    cloneDealer: ICloneDealerCallbackFn;
}
interface IDealerSearchWrapperProps {
    classes: ReturnType<typeof useStyles>;
}
const DealerSearchWrapper: FunctionComponent<IDealerSearchWrapperProps> = ({ classes, children }) => {
    return (
        <Box paddingTop={'6em'}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography align="center" gutterBottom variant="h4">
                        Dealer Search
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Box>
    );
};

const pageNumber = 1;
const pageSize = 100;
let dealerSearchString = '';
let showActiveDealersOnly = false;
let showDealersIntegratedWithVinSolutionsOnly = false;

const dealerSearchTableOptions: Options<any> = {
    pageSize: 100,
    pageSizeOptions: [10, 25, 50, 100],
    showFirstLastPageButtons: false,
    search: true,
    showSelectAllCheckbox: true
};
export const constructSearchQuery = (
    searchText: string,
    limit: number,
    page: number,
    activeDealers: boolean,
    dealersIntegratedWithVinSolutions: boolean
) => {
    const request = {
        searchText,
        limit,
        page,
        activeDealers,
        dealersIntegratedWithVinSolutions,
        fields: dealerSearchFields.join(','),
        sort: 'dealerId'
    };
    const query = queryString.stringify(request, { skipNull: true, encode: false });

    return `?${query}`;
};
const DR3DealerSearchPage: FunctionComponent<IDealerSearchDisplayProps> = (props) => {
    if (!props.dealers) {
        return null;
    }
    const cloneDealer = async (dealer: IDealerToManage): Promise<void> => {
        props.cloneDealer(dealer);
    };
    const handleSearchChange = debounce(async (searchText: string): Promise<void> => {
        const accessToken = await props.auth.getAccessToken();
        dealerSearchString = searchText;
        const urlQueryAddition = constructSearchQuery(
            dealerSearchString,
            pageSize,
            pageNumber,
            showActiveDealersOnly,
            showDealersIntegratedWithVinSolutionsOnly
        );
        dealerSearchTableOptions.pageSize = pageSize;
        if (accessToken) {
            props.loadSearchedDealers(accessToken, urlQueryAddition);
        }
    }, 700);
    const handleActiveDealersCheckboxChange = async (checked: boolean): Promise<void> => {
        showActiveDealersOnly = checked;
        handleSearchChange(dealerSearchString);
    };
    const handleIntegratedWithVinSolutionsCheckboxChange = async (checked: boolean): Promise<void> => {
        showDealersIntegratedWithVinSolutionsOnly = checked;
        handleSearchChange(dealerSearchString);
    };
    const getSponsorSettingString = (sponsorSetting: string, dealerWidget: string, listingWidget: string) => {
        let sponsorSettingString = '';
        switch (sponsorSetting) {
            case 'dealerSite':
                sponsorSettingString = `Website: ${dealerWidget}`;
                break;
            case 'listingSite':
                sponsorSettingString = `Listing: ${listingWidget}`;
                break;
            case 'dealerAndListingSites':
                sponsorSettingString = `Website: ${dealerWidget} \n Listing: ${listingWidget}`;
                break;
        }
        return sponsorSettingString;
    };
    if (!props.dealers) {
        return null;
    }
    const dealers = Object.values(props.dealers);
    dealers.forEach((dealer) => {
        // Since these variables are not on the root of the dealer object,
        // you cannot directly set the columns' "field" values to map to them.
        // This does not affect getting / saving dealers whatsoever since fresh GET and POST calls are made
        // upon selecting, cloning, or saving a dealer.
        const dealerWidgetNew = dealer.products.hasWidgetNew ? 'Y' : 'N';
        const dealerWidgetUsed = dealer.products.hasWidgetUsed ? 'Y' : 'N';
        const listingWidgetNew = dealer.listingOverride.products.hasWidgetNew ? 'Y' : 'N';
        const listingWidgetUsed = dealer.listingOverride.products.hasWidgetUsed ? 'Y' : 'N';
        const dealerWidget = `${dealerWidgetNew}|${dealerWidgetUsed}`;
        const listingWidget = `${listingWidgetNew}|${listingWidgetUsed}`;
        dealer.creditApplicationURL = dealer.leadRoutingOverrideSettings.leadRoutingOverrideEnabled ? 'Yes' : 'No';
        dealer.sponsorSetting = getSponsorSettingString(dealer.sponsorSetting, dealerWidget, listingWidget);
        dealer.vinSolutionsAccountId = dealer.crmIntegration?.vinSolutionsAccountId?.toString();
    });

    return (
        <div>
            {
                <DR3FieldWrapper label={'Dealer Search'} dealers={props.dealers}>
                    <DR3DealerSearchTable
                        dealers={dealers}
                        label={''}
                        handleSearch={handleSearchChange}
                        handleActiveDealersCheckboxChange={handleActiveDealersCheckboxChange}
                        handleIntegratedWithVinSolutionsCheckboxChange={handleIntegratedWithVinSolutionsCheckboxChange}
                        dealerSearchTableOptions={dealerSearchTableOptions}
                        cloneDealer={cloneDealer}
                    />
                </DR3FieldWrapper>
            }
        </div>
    );
};

export const isNumber = (numberToTest: string) => {
    if (!numberToTest || numberToTest.trim().length === 0) {
        return true;
    }

    const pattern = /^\d+$/;

    return pattern.test(numberToTest);
};

export default function DealerSearchDisplay(props: IDealerSearchDisplayProps): JSX.Element {
    const classes = useStyles();
    const dealers = props.dealers;
    if (!dealers) {
        return <div />;
    }
    return (
        <DealerSearchWrapper classes={classes}>
            <DR3DealerSearchPage {...props} />
        </DealerSearchWrapper>
    );
}
