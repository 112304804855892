
class OktaAuth {
    /**
     *
     * @param {{claims:{sub:string}}} jwt
     */
    constructor(jwt = '') {
        if (jwt !== '')
            this.email = jwt.claims.sub;
    }
}

/**
 * @param {string} level
 * @param {string} message
 * @param {JWT} jwt
 * @param {object} date
 * @returns {string} audit message to log
 */
function asAuditMessage(level, message, jwt, date = Date.now()) {
    const auth = new OktaAuth(jwt);
    return `[${date}][${level.toUpperCase()}][${auth.email}][${message}]`;
}
const audit = {
    /**
     * @param {string} message
     * @param {JWT} jwt
     */
    log: function (message, jwt) {
        const line = asAuditMessage('log', message, jwt);
        console.log(line);
    },
    /**
     * @param {string} message
     * @param {JWT} jwt
     */
    error: function (message, jwt) {
        const line = asAuditMessage('error', message, jwt, new Date());
        console.log(line);
    }
};

export default audit;
