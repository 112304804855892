import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const calculateOnMSRP = value;
        const leasing = {
            ...oldDealer.leasing,
            calculateOnMSRP
        };
        const newDealer = {
            ...oldDealer,
            leasing
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip =
    'If your vehicles are merchandised to include Finance-only incentives, your lease payments should be ' +
    'based on MSRP. If you do not merchandise with Finance-only incentives, you may base your lease payments on Internet ' +
    'Price.';

const LeasePaymentBasedOnMsrp: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.leasing.calculateOnMSRP;
    return (
        <DR3RadioBooleanField
            trueOption="MSRP"
            falseOption="Internet Price"
            label={ToggleLabelMap.calculateOnMSRPAlternate}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default LeasePaymentBasedOnMsrp;
