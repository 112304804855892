import { IDealerToManage, IHandleFieldChange } from '../../store/manager/types';
import React, { FunctionComponent } from 'react';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { Checkbox } from '@material-ui/core';
import MaterialTable, { Options } from 'material-table';
import { tableIcons } from './utils';
import { Config } from '../../config';

interface IDR3DealerSearchTableProps {
    dealers: IDealerToManage[];
    label: string;
    handleSearch: IHandleFieldChange<string>;
    handleActiveDealersCheckboxChange: IHandleFieldChange<boolean>;
    handleIntegratedWithVinSolutionsCheckboxChange: IHandleFieldChange<boolean>;
    dealerSearchTableOptions: Options<any>;
    cloneDealer: IHandleFieldChange<IDealerToManage>;
}

export const DR3DealerSearchTable: FunctionComponent<IDR3DealerSearchTableProps> = (props) => {
    props.dealerSearchTableOptions.headerStyle = { color: '#191919', fontWeight: 'bold', overflowWrap: 'break-word' };
    // sets table size equal to number of dealers if < 100
    if (props.dealerSearchTableOptions.pageSize) {
        if (props.dealers.length < props.dealerSearchTableOptions.pageSize) {
            props.dealerSearchTableOptions.pageSize = props.dealers.length;
        }
    }
    return (
        <DR3FieldWrapper label={props.label} dealers={props.dealers}>
            Show Active Dealers Only
            <Checkbox
                title={'Show Active Dealers Only'}
                defaultChecked={false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    props.handleActiveDealersCheckboxChange(checked);
                }}
            />
            Show dealers integrated with VinSolutions
            <Checkbox
                title={'Show dealers integrated with VinSolutions'}
                defaultChecked={false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    props.handleIntegratedWithVinSolutionsCheckboxChange(checked);
                }}
            />
            <MaterialTable
                icons={tableIcons}
                title={props.label}
                columns={[
                    // Since these variables are not on the root of the dealer object,
                    // you cannot directly set the columns' "field" values to map to them.
                    // This does not affect getting / saving dealers whatsoever since fresh GET and POST calls are made
                    // upon selecting, cloning, or saving a dealer.
                    // Go to DealerSearchDisplay to find the mapping.
                    { title: 'Accelerate ID', field: 'dealerId' },
                    {
                        title: 'VinSolutions ID',
                        field: 'vinSolutionsAccountId',
                        render: (rowData) => {
                            if (rowData.crmIntegration?.vinSolutionsCrmToggle?.toLocaleUpperCase() === 'ADF/S3') {
                                return rowData.crmIntegration?.vinSolutionsAccountId;
                            }
                        }
                    },
                    {
                        title: 'RouteOne ID',
                        field: 'routeOnePartyId',
                        render: (rowData) => {
                            if (rowData.creditProvider?.toLocaleLowerCase() === 'r1_dealertrack') {
                                return rowData.routeOnePartyId;
                            }
                        }
                    },
                    { title: 'ATC ID', field: 'ownerId' },
                    { title: 'DealerTrack ID', field: 'dealerTrackID' },
                    { title: 'Esntial Partner ID', field: 'esntialPartnerId' },
                    { title: 'Esntial Partner Dealer ID', field: 'esntialPartnerDealerId' },
                    { title: 'DT Override Enabled', field: 'creditApplicationURL' },
                    { title: 'DNA ID', field: 'dnaAccountId', cellStyle: { columnWidth: '10rem', overflow: 'hidden' } },
                    {
                        title: 'Name',
                        field: 'name',
                        render: (rowData) => {
                            if (rowData.dealerId) {
                                return (
                                    <a
                                        href={`${
                                            Config.services.api.protocol + Config.services.api.host
                                        }/dealers/${rowData.dealerId.toString()}/manage`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {rowData.name}
                                    </a>
                                );
                            }
                            return null;
                        }
                    },
                    {
                        title: 'URL',
                        field: 'website',
                        cellStyle: { columnWidth: '10rem', overflow: 'hidden' },
                        render: (rowData) => {
                            return (
                                <a href={rowData.website} target="_blank" rel="noopener noreferrer">
                                    {rowData.website}
                                </a>
                            );
                        }
                    },
                    { title: 'Inherit Settings From', field: 'sponsorSetting' },
                    {
                        title: 'Clone',
                        field: 'name',
                        render: (rowData) => {
                            if (rowData.dealerId) {
                                return (
                                    <a
                                        href="#top"
                                        onClick={() => {
                                            props.cloneDealer(rowData);
                                        }}
                                    >
                                        Clone
                                    </a>
                                );
                            }
                        }
                    }
                ]}
                data={props.dealers}
                options={props.dealerSearchTableOptions}
                onSearchChange={(searchText) => {
                    props.handleSearch(searchText);
                }}
            />
        </DR3FieldWrapper>
    );
};
