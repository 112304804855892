import * as yup from 'yup';
import { VinSolutionsCRMToggle } from '../../../src/constants/AdminConstants';

const ERROR_MSG = 'Must select at least one trade-in valuation provider';
const ERROR_MSG_DEFAULT_PROVIDER = 'Must select a default trade-in valuation provider';
const ERROR_MSG_SALES_VIEW = 'Must select at least one trade-in valuation provider (Sales View)';
const ERROR_MSG_DEFAULT_PROVIDER_SALES_VIEW = 'Must select a default trade-in valuation provider (Sales View)';
const ERROR_MSG_SHOW_APPRAISAL_VALUE_SCREEN_SALES_VIEW = 'Select if appraisal value screen will appear (Sales View)';
const ERROR_MSG_ICO_URL = 'Must set a correct URL for ICO provider';

// eslint-disable-next-line max-len
const urlRE = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;

export const schema = yup.object().shape(
    {
        name: yup.string().required('- Global: Dealership Name'),
        address: yup.object().shape({
            street: yup.string().required('- Global: Street'),
            city: yup.string().required('- Global: City'),
            zip: yup.string().length(5, '- Global: Zip') // .required("- Global: Required Zip")
        }),
        phone: yup.string().length(12, '- Global: Phone'), // .required("- Global: Required Phone"),
        website: yup.string().matches(urlRE, '- Global: Website'), // .required("- Global: Website"),
        shopperEducationVideoURL: yup
            .string()
            .trim()
            .transform((value) => (value === '' ? undefined : value))
            .notRequired()
            .matches(urlRE, '- Global: Edu URL'),
        privacyNoticeUrl: yup
            .string()
            .trim()
            .transform((value) => (value === '' ? undefined : value))
            .notRequired()
            .matches(urlRE, '- Global: Privacy Notice URL'),
        isDealertrackCreditProvider: yup['boolean'](),
        creditProvider: yup.string(),
        routeOnePartyId: yup.string().when('creditProvider', {
            is: 'r1_dealertrack',
            then: yup.string().required('- Global: RouteOne Party ID')
        }),
        creditApplicationURL: yup.string().when('creditProvider', {
            is: 'external',
            then: yup.string().matches(urlRE, '- Global: Application URL')
        }), // .required("- Global: Application URL"),
        creditApplicationButtonText: yup.string().when('creditProvider', {
            is: 'external',
            then: yup.string().required('- Global: Credit Application Button')
        }),
        overrideDRSActive: yup['boolean'](),
        overrideDRSAccountId: yup.string().when('overrideDRSActive', {
            is: true,
            then: yup.string().required('- Global: Override Account ID')
        }),
        leadRoutingOverrideSettings: yup.object().shape({
            leadRoutingOverrideEnabled: yup['boolean'](),
            dealertrackOverrideId: yup.number().when('leadRoutingOverrideEnabled', {
                is: true,
                then: yup
                    .number()
                    .typeError('- Global: Override Dealertrack ID')
                    .required('- Global: Override Dealertrack ID - Required')
                    .notOneOf([0], '- Global: Override Dealertrack ID')
            })
        }),
        reservation: yup.object().shape({
            showReservationDetail: yup['boolean'](),
            reservationType: yup.string().when(['showReservationDetail'], {
                is: (showReservationDetail) => showReservationDetail === true,
                then: yup
                    .string()
                    .typeError('- Global: Reservation Type')
                    .required('- Global: Reservation Type - Required')
                    .notOneOf([0], '- Global: Reservation Type')
            }),
            holdPeriod: yup.number().when(['showReservationDetail', 'reservationType'], {
                is: (showReservationDetail, reservationType) => showReservationDetail === true && reservationType === 'vehicleHold',
                then: yup
                    .number()
                    .typeError('- Global: Hold Period')
                    .required('- Global: Hold Period - Required')
                    .notOneOf([0], '- Global: Hold Period')
            }),
            depositType: yup.string().when(['showReservationDetail', 'reservationType'], {
                is: (showReservationDetail, reservationType) => showReservationDetail === true && reservationType === 'paidDeposit',
                then: yup
                    .string()
                    .typeError('- Global: Deposit Type')
                    .required('- Global: Deposit Type - Required')
                    .notOneOf([0], '- Global: Deposit Type')
            }),
            amount: yup.number().when(['showReservationDetail', 'reservationType'], {
                is: (showReservationDetail, reservationType) => showReservationDetail === true && reservationType === 'paidDeposit',
                then: yup
                    .number()
                    .typeError('- Global: Reservation Amount')
                    .required('- Global: Reservation Amount - Required')
                    .notOneOf([0], '- Global: Reservation Amount')
            }),
            accountId: yup.string().when(['showReservationDetail', 'reservationType'], {
                is: (showReservationDetail, reservationType) => showReservationDetail === true && reservationType === 'paidDeposit',
                then: yup
                    .string()
                    .required('- Global: Account ID')
                    .typeError('- Global: Account ID')
                    .required('- Global: Account ID - Required')
                    .notOneOf([0], '- Global: Account ID')
            }),
            terminalId: yup.string().when(['showReservationDetail', 'reservationType'], {
                is: (showReservationDetail, reservationType) => showReservationDetail === true && reservationType === 'paidDeposit',
                then: yup
                    .string()
                    .required('- Global: Terminal ID')
                    .typeError('- Global: Terminal ID')
                    .required('- Global: Terminal ID - Required')
                    .notOneOf([0], '- Global: Terminal ID')
            }),
            acceptorId: yup.string().when(['showReservationDetail', 'reservationType'], {
                is: (showReservationDetail, reservationType) => showReservationDetail === true && reservationType === 'paidDeposit',
                then: yup
                    .string()
                    .required('- Global: Acceptor ID')
                    .typeError('- Global: Acceptor ID')
                    .required('- Global: Acceptor ID - Required')
                    .notOneOf([0], '- Global: Acceptor ID')
            }),
            accountToken: yup.string().when(['showReservationDetail', 'reservationType'], {
                is: (showReservationDetail, reservationType) => showReservationDetail === true && reservationType === 'paidDeposit',
                then: yup
                    .string()
                    .required('- Global: Acceptor ID')
                    .typeError('- Global: Account Token')
                    .required('- Global: Account Token - Required')
                    .notOneOf([0], '- Global: Account Token')
            })
        }),
        crmIntegration: yup.object().shape({
            vinSolutionsCrmToggle: yup.string(),
            vinSolutionsAccountId: yup.number().when('vinSolutionsCrmToggle', {
                is: VinSolutionsCRMToggle.ADF_S3,
                then: yup
                    .number()
                    .typeError('- Global: VinSolutions ID')
                    .required('- Global: VinSolutions ID - Required')
                    .notOneOf([0], '- Global: VinSolutions ID')
            })
        }),
        tradeInValuationProvider: yup.string(),
        contactOptions: yup.object().shape({
            isPhoneEnabled: yup['boolean'](),
            trackingPhone: yup.string().when('isPhoneEnabled', {
                is: true,
                then: yup.string().length(12, '- Website: Tracking Phone') // .required("- Website: Tracking Phone")
            }),
            isSmsEnabled: yup['boolean'](),
            smsPhone: yup.string().when('isSmsEnabled', {
                is: true,
                then: yup.string().length(12, '- Website: SMS Phone') // .required("- Website: Required SMS Phone").
            })
        }),
        listingOverride: yup.object().shape({
            tradeInValuationProvider: yup.string(),
            tradeInValuationICOProviderUrl: yup.string().when('tradeInValuationProvider', {
                is: 'kelley_blue_book_ico',
                then: yup.string().matches(urlRE, '- Listing: ICO Provider URL')
            }),
            contactOptions: yup.object().shape({
                isPhoneEnabled: yup['boolean'](),
                trackingPhone: yup.string().when('isPhoneEnabled', {
                    is: true,
                    then: yup.string().length(12, '- Listing: Tracking Phone') // .required("- Listing: Tracking Phone")
                }),
                isSmsEnabled: yup['boolean'](),
                smsPhone: yup.string().when('isSmsEnabled', {
                    is: true,
                    then: yup.string().length(12, '- Listing: SMS Phone') // .required("- Listing: SMS Phone")
                })
            })
        }),
        tradeInValuationProviderKBB: yup['boolean']().when(['tradeInValuationProviderKBBICO', 'tradeInValuationProviderManual'], {
            is: (kbbIco, dealerAppraisal) => kbbIco || dealerAppraisal,
            then: (schema: any) => schema['default'](false),
            otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG)
        }),
        tradeInValuationProviderKBBICO: yup['boolean']().when(['tradeInValuationProviderKBB', 'tradeInValuationProviderManual'], {
            is: (kbbGuide, dealerAppraisal) => kbbGuide || dealerAppraisal,
            then: (schema: any) => schema['default'](false),
            otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG)
        }),
        tradeInValuationProviderManual: yup['boolean']().when(['tradeInValuationProviderKBB', 'tradeInValuationProviderKBBICO'], {
            is: (kbbGuide, kbbIco) => kbbGuide || kbbIco,
            then: (schema: any) => schema['default'](false),
            otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG)
        }),
        tradeInValuationProviderDefault: yup.string().nullable().required(ERROR_MSG_DEFAULT_PROVIDER),
        tradeInValuationProviderKBBSalesView: yup['boolean']().when(
            ['tradeInValuationProviderKBBICOSalesView', 'tradeInValuationProviderManualSalesView'],
            {
                is: (kbbIco, dealerAppraisal) => kbbIco || dealerAppraisal,
                then: (schema: any) => schema['default'](false),
                otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG_SALES_VIEW)
            }
        ),
        tradeInValuationProviderKBBICOSalesView: yup['boolean']().when(
            ['tradeInValuationProviderKBBSalesView', 'tradeInValuationProviderManualSalesView'],
            {
                is: (kbbGuide, dealerAppraisal) => kbbGuide || dealerAppraisal,
                then: (schema: any) => schema['default'](false),
                otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG_SALES_VIEW)
            }
        ),
        tradeInValuationProviderManualSalesView: yup['boolean']().when(
            ['tradeInValuationProviderKBBSalesView', 'tradeInValuationProviderKBBICOSalesView'],
            {
                is: (kbbGuide, kbbIco) => kbbGuide || kbbIco,
                then: (schema: any) => schema['default'](false),
                otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG_SALES_VIEW)
            }
        ),
        tradeInValuationProviderDefaultSalesView: yup.string().nullable().required(ERROR_MSG_DEFAULT_PROVIDER_SALES_VIEW),
        showAppraisalValueScreenSalesView: yup.string().required(ERROR_MSG_SHOW_APPRAISAL_VALUE_SCREEN_SALES_VIEW),
        tradeInValuationICOProviderUrl: yup
            .string()
            .when(['tradeInValuationProviderKBBICOSalesView', 'tradeInValuationProviderKBBICO'], {
                is: (kbbIcoSales, kbbIco) => kbbIcoSales || kbbIco,
                then: yup.string().matches(urlRE, ERROR_MSG_ICO_URL)
            })
    },
    [
        ['tradeInValuationProviderKBBICO', 'tradeInValuationProviderManual'],
        ['tradeInValuationProviderKBB', 'tradeInValuationProviderManual'],
        ['tradeInValuationProviderKBB', 'tradeInValuationProviderKBBICO'],
        ['tradeInValuationProviderKBBICOSalesView', 'tradeInValuationProviderManualSalesView'],
        ['tradeInValuationProviderKBBSalesView', 'tradeInValuationProviderManualSalesView'],
        ['tradeInValuationProviderKBBSalesView', 'tradeInValuationProviderKBBICOSalesView'],
        ['tradeInValuationProviderKBBICOSalesView', 'tradeInValuationProviderKBBICO']
    ]
);
