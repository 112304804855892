import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';
import { VinSolutionsCRMToggle } from '../../../src/constants/AdminConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const vinSolutionsCrmToggle = value;
        const crmIntegration = {
            ...oldDealer.crmIntegration,
            vinSolutionsCrmToggle
        };
        updateDealerCallback(null, {
            ...oldDealer,
            crmIntegration
        });
    };
}

const defaultOptions: IDropdownOptions[] = [
    {
        optionValue: VinSolutionsCRMToggle.OFF,
        optionLabel: VinSolutionsCRMToggle.OFF
    },
    {
        optionValue: VinSolutionsCRMToggle.ADF_S3,
        optionLabel: VinSolutionsCRMToggle.ADF_S3
    }
];

const VinSolutionsCRMIntegration: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    let value = VinSolutionsCRMToggle.OFF;
    const tooltip = 'This feature allows VinSolutions dealers to have One Lead & Desking';

    if (props.dealer.crmIntegration && props.dealer.crmIntegration.vinSolutionsCrmToggle) {
        value = props.dealer.crmIntegration.vinSolutionsCrmToggle;
    }

    return (
        <DR3DropdownField
            label="Enable VinSolutions Integration"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={defaultOptions}
            tooltip={tooltip}
        />
    );
};
export default VinSolutionsCRMIntegration;
