import React, { FunctionComponent } from 'react';
import { IHandleFieldChange, IInputAdornmentProps } from '../../store/manager/types';
import { validateIsNumber } from './utils';
import useStyles from '../layout/styles';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { ValidationTextField } from './ValidationTextField';
import { InputAdornment, FormHelperText } from '@material-ui/core';

interface IDR3TextFieldWithAdornmentProps {
    label: string;
    sublabel?: string;
    value: number;
    handleChange: IHandleFieldChange<number>;
    InputProps: IInputAdornmentProps;
    maxLength?: number;
    errorText?: string;
    isRequired?: boolean;
    disabled?: boolean;
    tooltip?: string;
}
// renders fields with icons. ex: %, $
export const DR3TextFieldWithAdornment: FunctionComponent<IDR3TextFieldWithAdornmentProps> = (props) => {
    function handleChange(event: React.ChangeEvent<unknown>) {
        let value = (event.target as HTMLInputElement).value;
        // defaults value to '0'
        if (value === '') {
            value = '0';
        }
        const isValid = validateIsNumber(value, props.maxLength, props.InputProps.unit);
        if (isValid) {
            if (isValid === '100') {
                value = '100';
            }
            const numberValue = parseInt(value, 10);
            return props.handleChange(numberValue);
        } else {
            return null;
        }
    }
    const classes = useStyles({});
    const isDisabled = !!props.disabled;
    const error = !!props.errorText;
    const required = !!props.isRequired;

    return (
        <DR3FieldWrapper
            label={props.label}
            sublabel={props.sublabel}
            tooltip={props.tooltip}
            className={classes.fieldWrapperWithoutMargin}
        >
            <div>
                <ValidationTextField
                    variant="outlined"
                    className={classes.textField}
                    value={props.value}
                    onChange={handleChange}
                    error={error}
                    disabled={isDisabled}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={props.InputProps.position}>{props.InputProps.unit}</InputAdornment>
                        )
                    }}
                    required={required}
                />
                <FormHelperText>{props.errorText}</FormHelperText>
            </div>
        </DR3FieldWrapper>
    );
};
