import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3NumberTextField } from '../common/DR3NumberTextField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const sdpModalZIndex = value;

        const newDealer = {
            ...oldDealer,
            sdpModalZIndex
        };
        updateDealerCallback(null, newDealer);
    };
}

const SdpModalZIndex: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.sdpModalZIndex;
    const valid = value >= 1000 && value <= 2147483647;
    const errorText = valid ? '' : 'z-index must be at least 1000';

    return (
        <DR3NumberTextField
            label="z-index for SDP Modal"
            tooltip="Adjust this value to move the SDP modal forward 
            or backward on the screen. The minimum value is 1000 and max is 2147483647. Default value is 2147483647."
            value={value}
            errorText={errorText}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default SdpModalZIndex;
