import { IDealerFinancing } from '../types';
import { asSafeLimits } from './asSafeLimits';
import { asSafeBoolean, asSafeNumber } from './selectorUtils';

export function asSafeDealerFinancing(financing?: IDealerFinancing): IDealerFinancing {
    if (!financing) {
        const defaultFinancing: IDealerFinancing = {
            isDownPaymentFixedAmount: asSafeBoolean(),
            downPaymentFixedAmountNew: asSafeNumber(),
            downPaymentFixedAmountUsed: asSafeNumber(),
            downPaymentPercent: asSafeNumber(),
            downPaymentPercentUsed: asSafeNumber(),
            limits: asSafeLimits(),
            defaultTermNew: asSafeNumber(),
            defaultTermUsed: asSafeNumber()
        };
        return defaultFinancing;
    }
    const newFinancing: IDealerFinancing = {
        isDownPaymentFixedAmount: asSafeBoolean(financing.isDownPaymentFixedAmount),
        downPaymentFixedAmountNew: asSafeNumber(financing.downPaymentFixedAmountNew),
        downPaymentFixedAmountUsed: asSafeNumber(financing.downPaymentFixedAmountUsed),
        downPaymentPercent: asSafeNumber(financing.downPaymentPercent),
        downPaymentPercentUsed: asSafeNumber(financing.downPaymentPercentUsed),
        limits: asSafeLimits(financing.limits),
        defaultTermNew: asSafeNumber(financing.defaultTermNew),
        defaultTermUsed: asSafeNumber(financing.defaultTermUsed)
    };
    return newFinancing;
}
