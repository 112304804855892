import React, { FunctionComponent, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {
    createDealerKeyToggleMap,
    filterDealerKeyToggleMap,
    getDealerKeyToggleMap,
    getLongKeyList,
    getShortToggleName
} from './common/utils';
import DealerToggleMetricsTable from './DealerToggleMetricsTable';
import DealerMetricsCustomSearchField from './DealerMetricsCustomSearchField';

import { Box, Typography } from '@material-ui/core';
// tslint:disable-next-line:no-var-requires

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(4),
        color: theme.palette.text.primary
    },
    currentSearch: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '50%',
        marginBottom: '2rem'
    },
    mainTitle: {
        margin: '1rem 0'
    },
    searchTitle: {
        margin: '1rem 0 1rem 8rem'
    },
    resetbutton: {
        right: '-8rem',
        backgroundColor: '#3f51b5',
        color: '#f9f1e7',
        fontSize: '2rem',
        width: '8rem',
        position: 'relative',
        transition: 'background-color 300ms ease-in-out'
    }
}));
interface IDealerSearchDisplayProps {
    dealer: any;
    getDealerToggleMetrics: (toggles: any) => void;
    resetDealerMetricsSearch: () => void;
}
interface IDealerSearchWrapperProps {
    classes: ReturnType<typeof useStyles>;
}

const DealerMetricsWrapper: FunctionComponent<IDealerSearchWrapperProps> = ({ classes, children }) => {
    return (
        <Box paddingTop={'6em'}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography className={classes.mainTitle} align="center" variant="h3">
                        Dealer Metrics
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Box>
    );
};

const handleSearchChange = debounce(async (toggles: any = {}, props: IDealerSearchDisplayProps): Promise<void> => {
    if (!Object.keys(toggles).length) {
        return;
    }
    await props.getDealerToggleMetrics(toggles);
}, 500);

const DealerMetricsDisplay: FunctionComponent<IDealerSearchDisplayProps> = (props) => {
    const classes = useStyles();
    const [dealerKeyToggleMap, setDealerKeyToggleMap] = React.useState(getDealerKeyToggleMap(props.dealer));
    const [dealerTogglePayload, setDealerTogglePayload] = React.useState({} as any);
    const [shouldIgnoreTestDealers, setShouldIgnoreTestDealers] = React.useState(false);
    const [shouldIgnoreInactiveDealers, setShouldIgnoreInactiveDealers] = React.useState(false);
    const [filterToggleInput, setFilterToggleInput] = React.useState('');

    const onFilteredToggleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterToggleInput(event.target.value);
    };

    const handleReset = (dealerToggleMap: any) => {
        const newToggleKeys = getLongKeyList(dealerToggleMap);
        const newToggleMap = createDealerKeyToggleMap(newToggleKeys);
        setDealerKeyToggleMap(newToggleMap);
        setShouldIgnoreInactiveDealers(false);
        setShouldIgnoreTestDealers(false);
        props.resetDealerMetricsSearch();
    };

    const removeSearchItem = (toggleName: string) => {
        const toggle = dealerKeyToggleMap[toggleName];
        const newKeyToggleMap = {
            ...dealerKeyToggleMap,
            [toggleName]: {
                ...toggle,
                selected: false
            }
        };
        setDealerKeyToggleMap(newKeyToggleMap);
    };

    const addSearchItem = (toggleName: string, toggleState: boolean, toggleValue: string, useBoolean: boolean) => {
        if (dealerKeyToggleMap[toggleName]) {
            return;
        }

        const toggleKey = toggleName.includes('.') ? getShortToggleName(toggleName) : toggleName;

        const newKeyToggleMap = {
            ...dealerKeyToggleMap,
            [toggleKey]: {
                selected: true,
                fullKeyPath: toggleName,
                toggleState,
                useBoolean,
                toggleValue
            }
        };
        setDealerKeyToggleMap(newKeyToggleMap);
    };

    const toggleIgnoreInactiveDealers = () => {
        setShouldIgnoreInactiveDealers(!shouldIgnoreInactiveDealers);
    };

    const toggleIgnoreTestDealers = () => {
        setShouldIgnoreTestDealers(!shouldIgnoreTestDealers);
    };

    useEffect(() => {
        const filteredMap = filterDealerKeyToggleMap(dealerKeyToggleMap);

        if (shouldIgnoreInactiveDealers) {
            filteredMap.isActive = true;
        }
        if (shouldIgnoreTestDealers) {
            filteredMap.isTestDealer = false;
        }
        setDealerTogglePayload(filteredMap);

        handleSearchChange(filteredMap, props);
    }, [dealerKeyToggleMap, shouldIgnoreTestDealers, shouldIgnoreInactiveDealers]);

    const onSelectedDealerKeyToggle = (toggle: string, flag = 'toggleState') => {
        const toggleState = dealerKeyToggleMap[toggle];
        const selected = toggleState[flag];
        setDealerKeyToggleMap({
            ...dealerKeyToggleMap,
            ...{
                [toggle]: { ...toggleState, [flag]: !selected }
            }
        });
    };

    if (!props.dealer) {
        return null;
    }

    const selectedDealerToggleKeys = Object.keys(dealerTogglePayload);

    const currentSearch = selectedDealerToggleKeys.length ? (
        <div>
            <Checkbox checked={shouldIgnoreTestDealers} color="primary" onChange={() => toggleIgnoreTestDealers()} />
            <span>Ignore Test Dealers</span>
            <Checkbox checked={shouldIgnoreInactiveDealers} color="primary" onChange={() => toggleIgnoreInactiveDealers()} />
            <span>Ignore Inactive Dealers</span>
            <Typography className={classes.searchTitle} align="center" variant="h4">
                Current Search
                <Button className={classes.resetbutton} onClick={() => handleReset(dealerKeyToggleMap)}>
                    Reset Search
                </Button>
            </Typography>
            <List className={classes.currentSearch}>
                {selectedDealerToggleKeys.map((key) => {
                    const toggleValue: any = dealerTogglePayload[key];
                    const shortToggleName = getShortToggleName(key);
                    const toggleSearchText = `${shortToggleName} = ${toggleValue}`;
                    return (
                        <ListItem key={key}>
                            <ListItemText primary={toggleSearchText} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    onClick={() => {
                                        removeSearchItem(shortToggleName);
                                    }}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    ) : null;

    return (
        <div>
            <div className={classes.inputContainer}>
                <TextField
                    className={classes.input}
                    id="outlined-basic"
                    label="Filter Toggles"
                    variant="outlined"
                    value={filterToggleInput}
                    onChange={onFilteredToggleInputChange}
                />
            </div>
            <DealerToggleMetricsTable
                onSelectedDealerKeyToggle={onSelectedDealerKeyToggle}
                dealerKeyToggleMap={dealerKeyToggleMap}
                toggleFilter={filterToggleInput}
            />
            <DealerMetricsCustomSearchField submitCustomToggle={addSearchItem} />
            {currentSearch}
        </div>
    );
};

export default function DealerSearchDisplay(props: IDealerSearchDisplayProps): JSX.Element {
    const classes = useStyles();
    const dealers = props.dealer;
    if (!dealers) {
        return <div />;
    }
    return (
        <DealerMetricsWrapper classes={classes}>
            <DealerMetricsDisplay {...props} />
        </DealerMetricsWrapper>
    );
}
