import { IDealerContactOptions } from '../types';
import { asSafeBoolean, asSafeEmptyString } from './selectorUtils';

export function asSafeContactOptions(contactOptions?: IDealerContactOptions) {
    if (!contactOptions) {
        const options: IDealerContactOptions = {
            isChatEnabled: false,
            isPhoneEnabled: false,
            isSmsEnabled: false,
            smsPhone: '',
            trackingPhone: '',
            isChatUpgradeEnabled: false,
            livePersonAccountId: '',
            isActivEngageEnabled: false,
            activEngageMmdId: ''
        };
        return options;
    } else {
        const options: IDealerContactOptions = {
            isChatEnabled: asSafeBoolean(contactOptions.isChatEnabled),
            isPhoneEnabled: asSafeBoolean(contactOptions.isPhoneEnabled),
            isSmsEnabled: asSafeBoolean(contactOptions.isSmsEnabled),
            smsPhone: asSafeEmptyString(contactOptions.smsPhone),
            trackingPhone: asSafeEmptyString(contactOptions.trackingPhone),
            isChatUpgradeEnabled: asSafeBoolean(contactOptions.isChatUpgradeEnabled),
            livePersonAccountId: asSafeEmptyString(contactOptions.livePersonAccountId),
            isActivEngageEnabled: asSafeBoolean(contactOptions.isActivEngageEnabled),
            activEngageMmdId: asSafeEmptyString(contactOptions.activEngageMmdId)
        };
        return options;
    }
}
