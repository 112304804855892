import { IDealerMileages } from '../types';
import { asSafeEmptyString, asSafeBoolean, asSafeNumber } from './selectorUtils';

export function asSafeMileages(mileages?: IDealerMileages[]): IDealerMileages[] {
    if (!mileages) {
        const defaultMileages: IDealerMileages[] = [
            {
                _id: asSafeEmptyString(),
                isBase: asSafeBoolean(),
                isDefault: asSafeBoolean(),
                mileage: asSafeNumber()
            }
        ];
        return defaultMileages;
    }
    const mileageArray: IDealerMileages[] = [];

    mileages.forEach((element) => {
        element._id = asSafeEmptyString(element._id);
        element.isBase = asSafeBoolean(element.isBase);
        element.isDefault = asSafeBoolean(element.isDefault);
        element.mileage = asSafeNumber(element.mileage);

        mileageArray.push(element);
    });
    return mileageArray;
}
