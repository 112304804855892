import React, { FunctionComponent } from 'react';
import { IInputAdornmentProps, IDealerCreditTierAPR, IDealerLeasing, IDealerToManage } from '../../store/manager/types';
import useStyles from '../layout/styles';
import { CustomTooltip } from './CustomTooltip';
import { Icon, Typography } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

export interface IDR3LabelProps {
    label: string | React.ReactElement;
    sublabel?: string;
    subtitle?: string;
    InputProps?: IInputAdornmentProps;
    tooltip?: string;
    creditTier?: IDealerCreditTierAPR[];
    leasingData?: IDealerLeasing;
    dealers?: IDealerToManage[];
    className?: string;
    isTableData?: boolean;
}
export const DRFieldLabel: FunctionComponent<IDR3LabelProps> = (props) => {
    const classes = useStyles({});
    if (props.tooltip) {
        return (
            <CustomTooltip
                title={props.tooltip}
                placement="top"
                PopperProps={{
                    popperOptions: {
                        placement: 'top',
                        modifiers: {
                            offset: {
                                enabled: true,
                                offset: '0%, -80%'
                            }
                        }
                    }
                }}
            >
                <Icon>
                    <Typography variant="h6" className={classes.label}>
                        {props.label}
                        <HelpOutlineOutlinedIcon />
                        <Typography variant="subtitle2" color="textSecondary" className={classes.label}>
                            {props.sublabel}
                        </Typography>
                    </Typography>
                </Icon>
            </CustomTooltip>
        );
    } else {
        return (
            <Typography variant="h6" className={classes.label}>
                {props.label}
                <Typography variant="subtitle2" color="textSecondary" className={classes.label}>
                    {props.sublabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.label}>
                    {props.subtitle}
                </Typography>
            </Typography>
        );
    }
};
