import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const reviewDealUrlEnabled = value;
        const newDealer = {
            ...oldDealer,
            reviewDealUrlEnabled
        };
        updateDealerCallback(null, newDealer);
    };
}

const ReviewDealUrlEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = props.dealer.reviewDealUrlEnabled;
    const tooltip = "This setting enables sending the Shopper's URL to Dealer CRM";

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.reviewDealUrlEnabled}
            tooltipProps={tooltip}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default ReviewDealUrlEnabled;
