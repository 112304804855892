import React, { FunctionComponent, useEffect, useState } from 'react';
import { IClientContainerProps } from '../types';
import { ErrorMessages } from '../../common/utils';
import { DR3FieldWrapper } from '../../common/DR3FieldWrapper';
import { ValidationTextField } from '../../common/ValidationTextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import useStyles from '../../layout/styles';

const ClientName: FunctionComponent<IClientContainerProps> = ({ client, isNew, setClient }) => {
    const [clientName, setClientName] = useState('');
    const classes = useStyles({});
    const isRequired = !isNew;
    const errorText = isRequired && !clientName ? ErrorMessages.MISSING_FIELD : '';

    useEffect(() => {
        setClientName(client.clientName ?? '');
    }, [client]);

    const handleChange = () => {
        const newClient = {
            ...client,
            clientName
        };
        setClient(newClient);
    };

    return (
        <DR3FieldWrapper label={'Client Name'} sublabel={'required'}>
            <ValidationTextField
                error={!!errorText}
                variant="outlined"
                className={classes.textField}
                required={isRequired}
                value={clientName}
                onChange={(event) => {
                    setClientName(event.target.value);
                }}
                onBlur={handleChange}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
export default ClientName;
