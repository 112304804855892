import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const calculateOnMSRP = value;
        const cash = {
            ...oldDealer.cash,
            calculateOnMSRP
        };
        const newDealer = {
            ...oldDealer,
            cash
        };
        updateDealerCallback(null, newDealer);
    };
}
// tslint:disable-next-line:variable-name
const CalculateOnMSRP: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.cash.calculateOnMSRP;
    return (
        <DR3RadioBooleanField
            trueOption="MSRP"
            falseOption="Internet Price"
            label={ToggleLabelMap.calculateOnMSRP}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default CalculateOnMSRP;
