import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { IClientContainerProps } from './../types';
import HttpMethodField from './HttpMethod';
import UrlField from './Url';
import UserNameField from './UserName';
import PasswordField from './Password';

const WebHookContainer: FunctionComponent<IClientContainerProps> = (props) => {
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <Typography align="center" gutterBottom variant="h4">
                        Webhook Setup
                    </Typography>
                </Grid>
            </Grid>
            <UrlField {...props} />
            <HttpMethodField {...props} />
            <UserNameField {...props} />
            <PasswordField {...props} />
        </div>
    );
};
export default WebHookContainer;
