import React, { FunctionComponent } from 'react';
import { IHandleFieldChange } from '../../store/manager/types';
import { Paper, Tabs, Tab } from '@material-ui/core';
import useStyles from '../../components/layout/styles';

interface IDealerManagerTabsProps {
    value: any;
    handleChange: IHandleFieldChange<any>;
    sponsorSetting: string;
}

const DealerManagerTabs: FunctionComponent<IDealerManagerTabsProps> = ({ value, handleChange, sponsorSetting, children }) => {
    function getDealerSiteTab() {
        if (sponsorSetting === 'listingSite') {
            return <Tab label="Website Settings" disabled />;
        }
        return <Tab label="Website Settings" />;
    }

    function getListingSiteTab() {
        if (sponsorSetting === 'dealerSite') {
            return <Tab label="Listing Site Settings" disabled />;
        }
        return <Tab label="Listing Site Settings" />;
    }

    const classes = useStyles({});
    return (
        <div>
            <Paper className={`${classes.tabs} dealerManagerTabs`}>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                    <Tab label="Global Settings" />
                    {getDealerSiteTab()}
                    {getListingSiteTab()}
                </Tabs>
            </Paper>
            {children}
        </div>
    );
};

export default DealerManagerTabs;
