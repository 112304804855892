import { FeatureToggles } from '@makemydeal/dr-feature-toggles-client';

export function getFeatureToggles(togglesResponse: any): FeatureToggles {
    const { featureToggles: response } = togglesResponse;

    if (!response) {
        return {};
    }

    return response;
}
