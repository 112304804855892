import React from 'react';
import useStyles from '../../components/layout/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

const variantIcon: { [key: string]: React.ComponentType<SvgIconProps> } = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

export default function MySnackbarContentWrapper(props: any) {
    const classes = useStyles({});
    const { className, message, onClose, variant, ...other } = props;

    // tslint:disable-next-line:variable-name
    const Icon: React.ComponentType<SvgIconProps> = variantIcon[variant];

    return (
        <SnackbarContent
            className={className}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={className}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    <ul className={clsx(classes.snackBarList)}>
                        {message.map((r: string, i: number) => {
                            return <li key={`li${i}`}>{r}</li>;
                        })}
                    </ul>
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.arrayOf(PropTypes.string),
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};
