import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioStringField } from '../common/DR3RadioStringField';
import { PaidDepositRestrictedStates } from '../../constants/AdminConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const reservationType = value;
        let reservation = {
            ...oldDealer.reservation,
            reservationType
        };
        if (oldDealer.reservation.reservationType === 'paidDeposit' && value === 'vehicleHold') {
            const amount = 0;
            reservation = {
                ...reservation,
                amount
            };
        }
        const newDealer = {
            ...oldDealer,
            reservation
        };
        updateDealerCallback(null, newDealer);
    };
}

function getTooltipText() {
    const statesList = PaidDepositRestrictedStates.join(', ');
    return `Paid Deposits are not available in the following states: ${statesList}`;
}

const ReservationType: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const isRestricted = PaidDepositRestrictedStates.includes(props.dealer.address.state);

    let isDisabled = true;

    if (
        isRestricted ||
        props.dealer.reservation.showReservationDetail ||
        props.dealer.listingOverride.reservation.showReservationDetail
    ) {
        isDisabled = false;
    }

    const value = props.dealer.reservation.reservationType;
    return (
        <DR3RadioStringField
            trueOption="Paid Deposit"
            falseOption="Vehicle Hold"
            label="Reservation Type"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            disabled={isDisabled}
            tooltip={getTooltipText()}
        />
    );
};
export default ReservationType;
