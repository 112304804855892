import React from 'react';
import { FunctionComponent } from 'react';
import {
    IDealerToManage,
    INumberDropdownOptions,
    IUpdateDealerCallbackFn,
    ICommonManagerFieldProps
} from '../../store/manager/types';
import { DR3DropdownNumberField } from '../common/DR3DropdownNumberField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const holdPeriod = value;
        const reservation = {
            ...oldDealer.reservation,
            holdPeriod
        };
        updateDealerCallback(null, {
            ...oldDealer,
            reservation
        });
    };
}

const holdPeriodOptions: INumberDropdownOptions[] = [
    {
        optionValue: 1,
        optionLabel: '1 day'
    },
    {
        optionValue: 2,
        optionLabel: '2 days'
    },
    {
        optionValue: 3,
        optionLabel: '3 days'
    },
    {
        optionValue: 4,
        optionLabel: '4 days'
    },
    {
        optionValue: 5,
        optionLabel: '5 days'
    },
    {
        optionValue: 6,
        optionLabel: '6 days'
    },
    {
        optionValue: 7,
        optionLabel: '7 days'
    }
];

const Theme: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    let isDisabled = true;

    if (props.dealer.reservation.showReservationDetail || props.dealer.listingOverride.reservation.showReservationDetail) {
        isDisabled = false;
    }

    return (
        <DR3DropdownNumberField
            label="Hold Period"
            value={props.dealer.reservation.holdPeriod}
            disabled={isDisabled}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={holdPeriodOptions}
        />
    );
};
export default Theme;
