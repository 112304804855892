import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const sponsorSetting = value;
        updateDealerCallback(null, {
            ...oldDealer,
            sponsorSetting
        });
    };
}

const options: IDropdownOptions[] = [
    {
        optionValue: 'dealerSite',
        optionLabel: 'Website'
    },
    {
        optionValue: 'listingSite',
        optionLabel: 'Listing Site'
    },
    {
        optionValue: 'dealerAndListingSites',
        optionLabel: 'Websites & Listing Sites'
    }
];

const toolTip =
    'Inherit settings from "Website & Listing" enables different options for website (DDC/off-platform) ' +
    'and listing (ATC/KBB) sites. Inherit settings from "Website" is intended for dealers with no listing site, ' +
    'but will apply all website settings to the listing site if it is active. Inherit settings from "Listing Site" ' +
    'is only intended for dealers with no active  website with DR.';

const SponsorSetting: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3DropdownField
            label="Inherit Settings From"
            value={props.dealer.sponsorSetting}
            tooltip={toolTip}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={options}
        />
    );
};
export default SponsorSetting;
