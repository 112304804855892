import React, { FunctionComponent } from 'react';
import { validateDNAAccountID } from './utils';
import useStyles from '../layout/styles';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { ValidationTextField } from './ValidationTextField';
import { FormHelperText } from '@material-ui/core';
import { IHandleFieldChange } from '../../store/manager/types';
interface IDR3TextFieldProps {
    label: string | React.ReactElement;
    sublabel?: string;
    value: string;
    readOnly?: boolean;
    handleChange: IHandleFieldChange<string>;
    errorText?: string;
    isRequired?: boolean;
    tooltip?: string;
    isDNAAccountIdField?: boolean;
    withMargin?: boolean;
}
export const DR3TextField: FunctionComponent<IDR3TextFieldProps> = (props) => {
    function handleChange(event: React.ChangeEvent<unknown>) {
        const value = (event.target as HTMLInputElement).value;
        if (props.isDNAAccountIdField) {
            const valid = validateDNAAccountID(value);
            if (valid) {
                return props.handleChange(value);
            } else {
                return null;
            }
        }
        return props.handleChange(value);
    }
    const classes = useStyles({});
    const error = !!props.errorText;
    const required = !!props.isRequired;
    const withMargin = props.withMargin ? classes.fieldWrapperWithMargin : classes.fieldWrapperWithoutMargin;
    return (
        <DR3FieldWrapper label={props.label} sublabel={props.sublabel} tooltip={props.tooltip} className={withMargin}>
            <ValidationTextField
                variant="outlined"
                className={classes.textField}
                error={error}
                required={required}
                value={props.value}
                inputProps={{
                    readOnly: props.readOnly
                }}
                onChange={handleChange}
            />
            <FormHelperText>{props.errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
