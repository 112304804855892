import React, { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3TextField } from '../common/DR3TextField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const ecommerceCTACopy = value;
        const newDealer = {
            ...oldDealer,
            ecommerceCTACopy
        };
        updateDealerCallback(null, newDealer);
    };
}
const EcommerceCTACopy: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    return (
        <DR3TextField
            label="Ecommerce CTA Copy"
            value={props.dealer.ecommerceCTACopy}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default EcommerceCTACopy;
