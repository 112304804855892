import Api, { IApi } from './Api';
import React from 'react';
const api = new Api();
export interface IWithApiProps {
    api: IApi;
}
const withApi = <P extends object>(Container: React.ComponentType<P>): React.FC<P & IWithApiProps> => ({
    ...props
}: IWithApiProps) => <Container api={api} {...(props as P)} />;

export default withApi;
