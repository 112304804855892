import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const selectn = require('selectn');

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const contactOptions = {
            ...oldDealer.contactOptions,
            isActivEngageEnabled: value
        };

        const newDealer = {
            ...oldDealer,
            contactOptions
        };
        updateDealerCallback(null, newDealer);
    };
}

const IsActivEngageEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = selectn('dealer.contactOptions.isActivEngageEnabled', props);

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.isActivEngageEnabled}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default IsActivEngageEnabled;
