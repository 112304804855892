import React, { useState, FunctionComponent } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import { DR3FieldWrapper } from '../common/DR3FieldWrapper';
import { FormControl, LinearProgress } from '@material-ui/core';
import { ICommonManagerFieldProps } from '../../store/manager/types';
import withApi, { IWithApiProps } from '../../utils/withApi';
import { IWithAuthProps } from '../../utils/AuthTypes';
import { uploadFileToS3 } from '../common/utils';
import { withAuth } from '@okta/okta-react';

interface DealerPosterDropZoneProps extends ICommonManagerFieldProps, IWithApiProps, IWithAuthProps {}

const FIFTY_MEGABYTES = 50 * 1024 * 1024;

const DealerPosterDropZone: FunctionComponent<DealerPosterDropZoneProps> = (props) => {
    const [progress, setProgress] = useState(-1);
    const [error, setError] = useState('');

    if (!props.dealer) {
        return null;
    }

    const onVideoChange = async (files: any) => {
        if (!props.dealer) return;
        try {
            const { api, auth, dealer: oldDealer } = props;
            const accessToken = await auth.getAccessToken();
            if (!accessToken) throw new Error('No access token');

            const { policy, url: howItWorksPosterUrl } = await api.asyncStartHowItWorksUploadPoster(
                oldDealer.dealerId,
                accessToken
            );
            await uploadFileToS3(policy, files[0], (current) => setProgress(current));
            setProgress(-1);

            const newDealer = {
                ...oldDealer,
                howItWorksPosterUrl
            };

            props.updateDealerCallback(null, newDealer);
        } catch (err) {
            console.error(err);
            setError('Failed to upload file.');
            setProgress(-1);
        }
    };

    const { howItWorksPosterUrl } = props.dealer;

    const handleRemoveVideo = async () => {
        if (!props.dealer) return;

        try {
            const { api, auth, dealer: oldDealer } = props;
            const accessToken = await auth.getAccessToken();
            if (!accessToken) throw new Error('No access token');

            await api.asyncRemoveHowItWorksContent(oldDealer.dealerId, accessToken, oldDealer.howItWorksPosterUrl);

            props.updateDealerCallback(null, {
                ...oldDealer,
                howItWorksPosterUrl: ''
            });
        } catch (err) {
            console.error(err);
            setError('Failed to remove poster.');
            setProgress(-1);
        }
    };

    const showDropZone = !howItWorksPosterUrl && progress === -1;

    return (
        <DR3FieldWrapper label={'How It Works Poster'}>
            <FormControl fullWidth component="fieldset">
                {howItWorksPosterUrl && <img alt="poster" width="400" src={howItWorksPosterUrl} />}
                {showDropZone && (
                    <>
                        <DropzoneArea
                            dropzoneText={'Drag and drop an image here or click'}
                            onChange={onVideoChange}
                            acceptedFiles={['image/jpeg', 'image/png', 'image/svg+xml']}
                            showPreviews={false}
                            showPreviewsInDropzone={false}
                            useChipsForPreview
                            showFileNamesInPreview={false}
                            showFileNames={true}
                            maxFileSize={FIFTY_MEGABYTES}
                            filesLimit={1}
                            dropzoneClass="dealer-custom-dropzone"
                        />
                        <div className="dealerPosterMessage">
                            File type must be .png, .jpg, or .svg
                            <br />
                            File size must be less than 50MB
                        </div>
                    </>
                )}
                {progress >= 0 && (
                    <>
                        <p>Uploading Image</p>
                        <LinearProgress variant="determinate" value={progress} />
                    </>
                )}
                {error && <p className="dealer-dropzone-error">Error uploading image</p>}
                {howItWorksPosterUrl && <Button onClick={handleRemoveVideo}>Remove Image</Button>}
            </FormControl>
        </DR3FieldWrapper>
    );
};

export default withAuth(withApi(DealerPosterDropZone));
