import { IDealerAddress } from '../types';
import { asSafeEmptyString } from './selectorUtils';
export function asSafeAddress(address?: IDealerAddress): IDealerAddress {
    if (!address) {
        return {
            street: '',
            city: '',
            state: '',
            zip: '',
            address2: ''
        };
    } else {
        return {
            street: asSafeEmptyString(address.street),
            city: asSafeEmptyString(address.city),
            state: asSafeEmptyString(address.state),
            zip: asSafeEmptyString(address.zip),
            address2: asSafeEmptyString(address.address2)
        };
    }
}
