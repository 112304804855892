import { IDealerProducts } from '../types';
import { asSafeBoolean } from './selectorUtils';

export function asSafeDealerProducts(products?: IDealerProducts): IDealerProducts {
    if (!products) {
        const defaultProducts: IDealerProducts = {
            hasFi: asSafeBoolean(),
            hasAccessories: asSafeBoolean(),
            hasLeasing: asSafeBoolean(),
            hasNewCashOffer: asSafeBoolean(),
            hasShowroom: asSafeBoolean(),
            hasTestDrive: asSafeBoolean(),
            hasTestDriveAtHome: asSafeBoolean(),
            hasUsedCashOffer: asSafeBoolean(),
            hasWidgetNew: asSafeBoolean(),
            hasWidgetUsed: asSafeBoolean(),
            isAccessoriesCatalogEnabled: asSafeBoolean(),
            isSubaruGtpEnabled: asSafeBoolean()
        };
        return defaultProducts;
    }
    const newProducts: IDealerProducts = {
        hasFi: asSafeBoolean(products.hasFi),
        hasAccessories: asSafeBoolean(products.hasAccessories),
        hasLeasing: asSafeBoolean(products.hasLeasing),
        hasNewCashOffer: asSafeBoolean(products.hasNewCashOffer),
        hasShowroom: asSafeBoolean(products.hasShowroom),
        hasTestDrive: asSafeBoolean(products.hasTestDrive),
        hasTestDriveAtHome: asSafeBoolean(products.hasTestDriveAtHome),
        hasUsedCashOffer: asSafeBoolean(products.hasUsedCashOffer),
        hasWidgetNew: asSafeBoolean(products.hasWidgetNew),
        hasWidgetUsed: asSafeBoolean(products.hasWidgetUsed),
        isAccessoriesCatalogEnabled: asSafeBoolean(products.isAccessoriesCatalogEnabled),
        isSubaruGtpEnabled: asSafeBoolean(products.isSubaruGtpEnabled)
    };
    return newProducts;
}
