import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

export const ShowDealPulseInManagerView: FunctionComponent<ICommonManagerFieldProps> = ({ dealer, updateDealerCallback }) => {
    if (!dealer) {
        return null;
    }

    const tooltip = 'This feature allows Deal Pulse data to display on the Manager View landing screen.';

    const handleChange = (oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) => {
        return (value: boolean): void => {
            const newDealer = {
                ...oldDealer,
                showDealPulseInManagerView: value
            };
            updateDealerCallback(null, newDealer);
        };
    };

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.showDealPulseInManagerView}
            value={dealer.showDealPulseInManagerView}
            handleChange={handleChange(dealer, updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
