import React, { FunctionComponent, useEffect, useState } from 'react';
import { IClientContainerProps } from '../types';
import { ErrorMessages } from '../../common/utils';
import { DR3FieldWrapper } from '../../common/DR3FieldWrapper';
import { ValidationTextField } from '../../common/ValidationTextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import useStyles from '../../layout/styles';

const ClientDefaultAppName: FunctionComponent<IClientContainerProps> = ({ client, isNew, setClient }) => {
    const [applicationName, setApplicationName] = useState('');
    const classes = useStyles({});
    const isRequired = !isNew;
    const errorText = isRequired && !applicationName ? ErrorMessages.MISSING_FIELD : '';

    useEffect(() => {
        setApplicationName(client.defaultChangedBy?.applicationName ?? '');
    }, [client]);

    const handleChange = () => {
        const newClient = {
            ...client,
            defaultChangedBy: {
                ...client.defaultChangedBy,
                applicationName
            }
        };
        setClient(newClient);
    };

    return (
        <DR3FieldWrapper label={'Default Application Name'} sublabel={'required'}>
            <ValidationTextField
                error={!!errorText}
                variant="outlined"
                className={classes.textField}
                required={isRequired}
                value={applicationName}
                onChange={(event) => {
                    setApplicationName(event.target.value);
                }}
                onBlur={handleChange}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
export default ClientDefaultAppName;
