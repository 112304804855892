import { IDealerIncentives } from '../types';
import { asSafeBoolean } from './selectorUtils';

export function asSafeDealerIncentives(incentives?: IDealerIncentives): IDealerIncentives {
    if (!incentives) {
        const defaultIncentives: IDealerIncentives = {
            includeIncentivesInPrice: asSafeBoolean(),
            excludeSpecialAPRLeaseOffersNewCar: asSafeBoolean(),
            excludeSpecialAPRLoanOffersUsedCar: asSafeBoolean(),
            excludeSpecialAPRLoanOffersNewCar: asSafeBoolean(),
            enableMaxCashIncentives: asSafeBoolean()
        };
        return defaultIncentives;
    }
    const newIncentives: IDealerIncentives = {
        includeIncentivesInPrice: asSafeBoolean(incentives.includeIncentivesInPrice),
        excludeSpecialAPRLeaseOffersNewCar: asSafeBoolean(incentives.excludeSpecialAPRLeaseOffersNewCar),
        excludeSpecialAPRLoanOffersUsedCar: asSafeBoolean(incentives.excludeSpecialAPRLoanOffersUsedCar),
        excludeSpecialAPRLoanOffersNewCar: asSafeBoolean(incentives.excludeSpecialAPRLoanOffersNewCar),
        enableMaxCashIncentives: asSafeBoolean(incentives.enableMaxCashIncentives)
    };
    return newIncentives;
}
