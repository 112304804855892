import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioStringField } from '../common/DR3RadioStringField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const isMenuPricingBasedOn = value;
        const newDealer = {
            ...oldDealer,
            isMenuPricingBasedOn
        };
        updateDealerCallback(null, newDealer);
    };
}

const IsMenuPricingBasedOn: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    let isDisabled = true;

    if (props.dealer.products.hasFi || props.dealer.listingOverride.products.hasFi) {
        isDisabled = false;
    }
    const value = props.dealer.isMenuPricingBasedOn;
    return (
        <DR3RadioStringField
            trueOption="Cost Plus Markup"
            falseOption="Retail"
            label="Menu Pricing Type"
            value={value}
            disabled={isDisabled}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default IsMenuPricingBasedOn;
