import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        oldDealer.creditTiers.forEach((element) => {
            if (element.type === 'new' && element.name === value) {
                element.isDefault = true;
            }
            if (element.type === 'new' && !(element.name === value)) {
                element.isDefault = false;
            }
        });
        updateDealerCallback(null, {
            ...oldDealer,
            ...oldDealer.creditTiers
        });
    };
}

const defaultOptions: IDropdownOptions[] = [
    {
        optionValue: 'Excellent',
        optionLabel: 'Excellent'
    },
    {
        optionValue: 'Very Good',
        optionLabel: 'Very Good'
    },
    {
        optionValue: 'Good',
        optionLabel: 'Good'
    },
    {
        optionValue: 'Fair',
        optionLabel: 'Fair'
    },
    {
        optionValue: 'Poor',
        optionLabel: 'Poor'
    }
];

const CreditAndFinancingDropdown: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    let value = 'Excellent';
    props.dealer.creditTiers = Object.values(props.dealer.creditTiers);
    props.dealer.creditTiers.forEach((element) => {
        if (element.type === 'new' && element.isDefault === true) {
            value = element.name;
        }
    });

    const tooltip = 'Offer competitive prices or negotiate on price';

    return (
        <DR3DropdownField
            label="New Vehicles"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={defaultOptions}
            tooltip={tooltip}
        />
    );
};
export default CreditAndFinancingDropdown;
