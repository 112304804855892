/* eslint-disable react/jsx-no-target-blank */
import React, { FunctionComponent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import DealerMetricsPieChart from './DealerMetricsPieChart';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { generateDealerMetricsCsv, saveFile } from './common/utils';

interface IMatchedDealer {
    dealerId: number;
    name: string;
}

interface IDealerSearchWrapperProps {
    matchedDealers: IMatchedDealer[];
    totalDealerCount: number;
    matchedDealerCount: number;
    partnersData: any[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            overflowY: 'scroll',
            flexGrow: 1,
            height: '250px',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexWrap: 'wrap',
            margin: '2rem'
        },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        input: {
            width: '50%'
        },
        grid: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        list: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexWrap: 'wrap',
            margin: '10px'
        },
        copyButton: {
            backgroundColor: '#3f51b5',
            color: '#f9f1e7',
            fontSize: '2rem',
            marginLeft: '1rem',
            width: '7rem',
            position: 'relative',
            transition: 'background-color 300ms ease-in-out'
        },
        filteredDealerCount: {
            textAlign: 'center'
        },
        matchedContainer: {
            width: '50%'
        },
        matches: {
            height: '450px'
        },
        matchedDealerContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center'
        },
        noResultContainer: {
            margin: '2rem'
        },
        resultContainer: {
            height: '400px'
        },
        noResults: {
            padding: '2rem'
        },
        demo: {
            backgroundColor: theme.palette.background.paper
        },
        title: {
            margin: theme.spacing(4, 0, 2),
            padding: '1rem 0 0 0'
        }
    })
);

// This statement is needed to override a typescript compilation error when accessing clipboard copy
declare const window: any;

const DealerToggleMetricsMatchList: FunctionComponent<IDealerSearchWrapperProps> = ({
    matchedDealers,
    totalDealerCount,
    matchedDealerCount,
    partnersData
}) => {
    const classes = useStyles();
    const [isToolTipOpen, setTooltipOpen] = React.useState(false);
    const [filteredDealers, setFilteredDealers] = React.useState([]);
    const [filteredDealerInput, setFilteredDealerInput] = React.useState('');

    const filterMatchedDealers = debounce(async (dealers: any) => {
        if (!filteredDealerInput) {
            return [];
        }

        const filtered: any = [];
        // Using for loop for better performance on large result sets
        // tslint:disable-next-line:prefer-for-of
        for (let i = dealers.length - 1; i > -1; i--) {
            const dealer = dealers[i];
            if (dealer.dealerId.toString().includes(filteredDealerInput)) {
                filtered.push(dealer);
            }
        }

        setFilteredDealers(filtered);
    }, 200);

    React.useEffect(() => {
        if (!filteredDealerInput) {
            setFilteredDealers([]);
        }
        filterMatchedDealers(matchedDealers);
    }, [filteredDealerInput]);

    React.useEffect(() => {
        setFilteredDealers([]);
        setFilteredDealerInput('');
    }, [matchedDealers]);

    const handleCopyClick = (matchedDealerList: any[]) => {
        // Yes I know this is hacky but it is the only way...
        // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
        const dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.setAttribute('value', matchedDealerList.map(({ dealerId }) => dealerId).join());
        dummy.setAttribute('style', 'opacity: 0, width: 0px, display: block, height: 0px, margin: 0;');
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);

        setTooltipOpen(true);

        setTimeout(() => {
            setTooltipOpen(false);
        }, 1000);
    };

    const handleExport = (matchedDealerList: any) => {
        const csv = generateDealerMetricsCsv(matchedDealerList, partnersData);
        saveFile(csv, 'matchedDealers.csv');
    };

    const onFilteredInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFilteredDealerInput(value);
    };

    const displayedDealers = filteredDealers && filteredDealers.length ? filteredDealers : matchedDealers;

    if (!matchedDealers || !matchedDealers.length) {
        return (
            <div className={classes.noResultContainer}>
                <Paper>
                    <Typography className={classes.noResults} variant="h5" align="center">
                        No current results to display
                    </Typography>
                </Paper>
            </div>
        );
    }

    return (
        <Grid className={classes.grid} container spacing={2}>
            <DealerMetricsPieChart total={totalDealerCount} part={matchedDealerCount} />
            <div className={classes.matchedContainer}>
                <Paper className={classes.matches}>
                    <Typography className={classes.title} variant="h4" align="center">
                        Matched Dealer ID's
                        <Button
                            onClick={() => {
                                handleExport(displayedDealers);
                            }}
                            className={classes.copyButton}
                        >
                            Export CSV
                        </Button>
                        <Tooltip
                            title="Copied to clipboard!"
                            disableFocusListener={true}
                            disableHoverListener={true}
                            TransitionComponent={Fade}
                            open={isToolTipOpen}
                        >
                            <Button
                                onClick={() => {
                                    handleCopyClick(displayedDealers);
                                }}
                                className={classes.copyButton}
                            >
                                Copy
                            </Button>
                        </Tooltip>
                    </Typography>
                    <div className={classes.inputContainer}>
                        <TextField
                            className={classes.input}
                            value={filteredDealerInput}
                            onChange={onFilteredInputChange}
                            id="outlined-basic"
                            label="Filter Dealers"
                            variant="outlined"
                        />
                    </div>
                    <div className={classes.root}>
                        <div className={classes.filteredDealerCount}>{displayedDealers.length} Dealers</div>
                        <div className={classes.matchedDealerContainer}>
                            {displayedDealers.map(({ dealerId, name }) => {
                                return (
                                    <Tooltip key={name} title={name} TransitionComponent={Fade}>
                                        <a target="_blank" href={`/dealers/${dealerId}/manage`} className={classes.list}>
                                            <ListItemText primary={dealerId} />
                                        </a>
                                    </Tooltip>
                                );
                            })}
                        </div>
                    </div>
                </Paper>
            </div>
        </Grid>
    );
};

export default DealerToggleMetricsMatchList;
