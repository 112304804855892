import { IDealerProductSet } from '../types';
import { asSafeBoolean } from './selectorUtils';

export function asSafeProductSet(productSet?: IDealerProductSet) {
    if (!productSet) {
        return {
            accelerateMyDeal: asSafeBoolean(),
            standaloneCreditApp: asSafeBoolean(),
            ecommerce: asSafeBoolean()
        };
    }
    const safeProductSet: IDealerProductSet = {
        accelerateMyDeal: asSafeBoolean(productSet.accelerateMyDeal),
        standaloneCreditApp: asSafeBoolean(productSet.standaloneCreditApp),
        ecommerce: asSafeBoolean(productSet.ecommerce)
    };
    return safeProductSet;
}
