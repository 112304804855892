import { makeStyles } from '@material-ui/core/styles';

interface IStyles {
    root: {
        display: string;
    };
}
const useStyles: ReturnType<typeof makeStyles> = makeStyles((theme) => ({
    label: {
        width: '100%'
    },
    textField: {
        width: '100%'
    },
    fieldWrapperWithoutMargin: {
        // no margin
    },
    fieldWrapperWithMargin: {
        marginBottom: '20px'
    },
    content: {
        flexGrow: 1,
        overflow: 'auto'
    },
    title: {
        flexGrow: 1,
        position: 'static',
        textAlign: 'center'
    },
    toolbar: {
        paddingRight: theme.spacing(3)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        color: 'black'
    },
    menuItem: {
        textDecoration: 'none',
        color: 'black'
    },
    menuSubItem: {
        textDecoration: 'none',
        color: 'black',
        fontSize: '.8rem',
        marginLeft: '20px'
    },
    button: {
        color: 'white !important'
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    tabs: {
        paddingTop: '75px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0), 0px 1px 1px 0px rgba(0,0,0,0), 0px 1px 3px 0px rgba(0,0,0,0)'
    },
    snackBarList: {
        paddingLeft: '0px',
        marginLeft: '0px',
        listStyleType: 'none'
    }
}));
export default useStyles;
