export const downloadFileService = {
    async downloadFile(response: any, fileName: string) {
        const fileBlob = await response.blob();

        const link = document.createElement('a');

        link.download = fileName;
        link.rel = 'noopener';
        link.href = URL.createObjectURL(fileBlob);

        this._clickOnNode(link);

        setTimeout(() => {
            URL.revokeObjectURL(fileBlob);
        }, 100);
    },

    _clickOnNode(node: any) {
        const event = new MouseEvent('click');
        node.dispatchEvent(event);
    }
};
