import { IManagerState, ManagerActionTypes as Types, ManagerActionPayload } from './types';
export const initialState: IManagerState = {
    dealerToManage: null,
    fetchSearchedDealersalers: null,
    messages: [],
    isSaving: false,
    isLoading: true,
    validatorMap: {},
    partnerMappings: []
};
export function managerReducer(state = initialState, action: ManagerActionPayload): IManagerState {
    switch (action.type) {
        case Types.UPDATE_VALIDATOR: {
            const key = action.payload.key;
            const validatorMap = {
                ...state.validatorMap,
                [key]: action.payload
            };
            return {
                ...state,
                validatorMap
            };
        }
        case Types.UPDATE_DEALER_TO_MANAGE: {
            if (!action.payload) {
                return {
                    ...state,
                    dealerToManage: null
                };
            }
            return {
                ...state,
                dealerToManage: { ...action.payload }
            };
        }
        case Types.FETCH_ALL_DEALERS: {
            if (!action.payload) {
                return {
                    ...state,
                    fetchSearchedDealersalers: null
                };
            }
            return {
                ...state,
                fetchSearchedDealersalers: { ...action.payload }
            };
        }
        case Types.UPDATE_PARTNER_MAPPINGS: {
            if (!action.payload) {
                return {
                    ...state,
                    partnerMappings: []
                };
            }
            return {
                ...state,
                partnerMappings: [...action.payload]
            };
        }
        default:
            return state;
    }
}
