import { IDealerCreditApp } from '../types';
import { asSafeBoolean } from './selectorUtils';

export function asSafeCreditApp(creditApp?: IDealerCreditApp) {
    if (!creditApp) {
        return {
            shouldSendCreditDecisionEmail: asSafeBoolean(),
            isSoftPullEnabled: asSafeBoolean(),
            isEmployerAddressRequired: asSafeBoolean(),
            enableLeadFormBeforeCreditApp: asSafeBoolean()
        };
    }
    const app: IDealerCreditApp = {
        shouldSendCreditDecisionEmail: asSafeBoolean(creditApp.shouldSendCreditDecisionEmail),
        isSoftPullEnabled: asSafeBoolean(creditApp.isSoftPullEnabled),
        isEmployerAddressRequired: asSafeBoolean(creditApp.isEmployerAddressRequired),
        enableLeadFormBeforeCreditApp: asSafeBoolean(creditApp.enableLeadFormBeforeCreditApp)
    };
    return app;
}
