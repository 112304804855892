import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const partnerId = value;
        updateDealerCallback(null, {
            ...oldDealer,
            partnerId
        });
    };
}

const themeOptions: IDropdownOptions[] = [
    { optionValue: 'MMD', optionLabel: 'MMD' },
    { optionValue: 'ADX', optionLabel: 'ADX' },
    { optionValue: 'VWA', optionLabel: 'VWA' }
];

const PartnerId: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3DropdownField
            label="Partner Id"
            value={props.dealer.partnerId}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={themeOptions}
        />
    );
};
export default PartnerId;
