import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const labelSetName = value;
        updateDealerCallback(null, {
            ...oldDealer,
            labelSetName
        });
    };
}

const themeOptions: IDropdownOptions[] = [
    { optionValue: 'default', optionLabel: 'Default' },
    { optionValue: 'acura', optionLabel: 'Acura' },
    { optionValue: 'bmw', optionLabel: 'BMW' },
    { optionValue: 'honda', optionLabel: 'Honda' },
    { optionValue: 'lexus', optionLabel: 'Lexus' },
    { optionValue: 'subaru', optionLabel: 'Subaru' },
    { optionValue: 'subaruNewEngland', optionLabel: 'Subaru of New England' },
    { optionValue: 'toyota', optionLabel: 'Toyota' },
    { optionValue: 'toyotaNewJersey', optionLabel: 'Toyota of New Jersey' }
];

const LabelSet: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    let value = props.dealer.labelSetName;
    if (value === '') {
        value = 'default';
        props.dealer.labelSetName = 'default';
    }

    return (
        <DR3DropdownField
            label="Label Set"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={themeOptions}
        />
    );
};
export default LabelSet;
