import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const showReservationDetail = value;
        const reservation = {
            ...oldDealer.listingOverride.reservation,
            showReservationDetail
        };
        const listingOverride = {
            ...oldDealer.listingOverride,
            reservation
        };
        const newDealer = {
            ...oldDealer,
            listingOverride
        };
        updateDealerCallback(null, newDealer);
    };
}

const toolTip =
    'Setting "Enable Reservations?" to "Yes" will apply all settings under "Reservations" ' +
    'in the Global Settings tab to listing sites.';

const ShowReservationDetail: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.listingOverride.reservation.showReservationDetail;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.showReservationDetail}
            tooltipProps={toolTip}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default ShowReservationDetail;
