import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3TextField } from '../common/DR3TextField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const address2 = value;
        const address = {
            ...oldDealer.address,
            address2
        };
        updateDealerCallback(null, {
            ...oldDealer,
            address
        });
    };
}
const DealershipAddress2: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.address.address2;
    return (
        <DR3TextField label="Address Line 2" value={value} handleChange={handleChange(props.dealer, props.updateDealerCallback)} />
    );
};
export default DealershipAddress2;
