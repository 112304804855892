import React from 'react';
import { withAuth } from '@okta/okta-react';
import { IWithAuthProps, IUserInfo } from '../utils/AuthTypes';
import { Button, Typography } from '@material-ui/core';
// tslint:disable-next-line:no-var-requires
require('./layout/css/styles.scss');

interface IHostedLoginState {
    authenticated: boolean | null;
    userInfo: IUserInfo | null;
    token: string | null;
}

class HostedLoginComponent extends React.Component<IWithAuthProps, IHostedLoginState> {
    public static calculateState() {
        return {};
    }
    public state: IHostedLoginState = {
        authenticated: null,
        userInfo: null,
        token: null
    };
    constructor(props: IWithAuthProps) {
        super(props);
        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }
    public async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            if (authenticated && !this.state.userInfo) {
                const userInfo = await this.props.auth.getUser();
                const token = await this.props.auth.getAccessToken();
                this.setState({ authenticated, userInfo, token });
            } else {
                this.setState({ authenticated });
            }
        }
    }
    public async componentDidUpdate() {
        await this.checkAuthentication();
    }
    public async componentDidMount() {
        await this.checkAuthentication();
    }
    public async login() {
        await this.props.auth.login('/');
    }
    public async logout() {
        await this.props.auth.logout();
    }
    public render() {
        return (
            <div className="loginStatus">
                {this.state.authenticated !== null && (
                    <div>
                        {!!this.state.userInfo && <Typography variant="h6">{this.state.userInfo.name}</Typography>}
                        {this.state.authenticated && (
                            <Button className="button" onClick={this.logout}>
                                Logout
                            </Button>
                        )}
                        {!this.state.authenticated && (
                            <Button className="button" onClick={this.login}>
                                Login
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
export default withAuth(HostedLoginComponent);
