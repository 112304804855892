import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import { ToggleLabelMap } from '../constants/ToggleConstants';

interface IDealerSearchWrapperProps {
    dealerKeyToggleMap: any;
    toggleFilter: string;
    onSelectedDealerKeyToggle: (toggles: any, flag: string) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        metrics: {
            maxHeight: '300px',
            overflowY: 'scroll'
        },
        row: {
            cursor: 'pointer'
        }
    })
);

const DealerToggleMetricsTable: FunctionComponent<IDealerSearchWrapperProps> = ({
    dealerKeyToggleMap,
    onSelectedDealerKeyToggle,
    toggleFilter
}) => {
    const classes = useStyles();

    const dealerToggleKeys = toggleFilter
        ? Object.keys(dealerKeyToggleMap)
              .filter((key) => key.toLowerCase().includes(toggleFilter.toLowerCase()))
              .sort()
        : Object.keys(dealerKeyToggleMap).sort();

    const tableContent = dealerToggleKeys.map((keyToggle: any) => {
        const toggleSwitch = dealerKeyToggleMap[keyToggle].useBoolean ? (
            <Switch
                checked={dealerKeyToggleMap[keyToggle].toggleState}
                onChange={() => {
                    onSelectedDealerKeyToggle(keyToggle, 'toggleState');
                }}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        ) : null;

        const toggleStateOrValue = dealerKeyToggleMap[keyToggle].useBoolean
            ? dealerKeyToggleMap[keyToggle].toggleState.toString()
            : dealerKeyToggleMap[keyToggle].toggleValue;
        const toggleLabel = (ToggleLabelMap as any)[keyToggle] || 'N/A';
        return (
            <TableRow
                key={keyToggle}
                onClick={() => onSelectedDealerKeyToggle(keyToggle, 'selected')}
                selected={dealerKeyToggleMap[keyToggle].selected}
                className={classes.row}
                hover={true}
            >
                <TableCell scope="row">{keyToggle}</TableCell>
                <TableCell>{toggleLabel}</TableCell>
                <TableCell>{toggleStateOrValue}</TableCell>
                <TableCell>{toggleSwitch}</TableCell>
            </TableRow>
        );
    });

    return (
        <TableContainer className={classes.metrics} component={Paper}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Toggle Name</TableCell>
                        <TableCell>Admin Setting Name</TableCell>
                        <TableCell>Toggle State</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{tableContent}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default DealerToggleMetricsTable;
