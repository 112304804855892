import { IListingOverrideProducts } from '../types';
import { asSafeBoolean } from './selectorUtils';

export function asSafeDealerListingOverrideProducts(products?: IListingOverrideProducts): IListingOverrideProducts {
    if (!products) {
        const defaultProducts: IListingOverrideProducts = {
            hasWidgetNew: asSafeBoolean(),
            hasWidgetUsed: asSafeBoolean(),
            hasFi: asSafeBoolean(),
            hasUsedCashOffer: asSafeBoolean(),
            hasNewCashOffer: asSafeBoolean()
        };
        return defaultProducts;
    }
    const newProducts: IListingOverrideProducts = {
        hasWidgetNew: asSafeBoolean(products.hasWidgetNew),
        hasWidgetUsed: asSafeBoolean(products.hasWidgetUsed),
        hasFi: asSafeBoolean(products.hasFi),
        hasUsedCashOffer: asSafeBoolean(products.hasUsedCashOffer),
        hasNewCashOffer: asSafeBoolean(products.hasNewCashOffer)
    };
    return newProducts;
}
