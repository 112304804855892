import React, { FunctionComponent, useMemo, useRef } from 'react';
import MaterialTable, { Icons, MaterialTableProps, Options } from 'material-table';

interface DR3TableWrapperProps extends MaterialTableProps<any> {
    icons: Icons;
    options: Options<any>;
    data: any[];
    onUpdate: (value1: any, value2: any) => void;
    onAdd: (newValue: any) => void;
    onDelete: (oldData: any) => void;
}

export const DR3TableWrapper: FunctionComponent<DR3TableWrapperProps> = (props) => {
    const tableRef = useRef<any>(null);

    // Workaround for table due to a bug in this and higher versions
    // Visit https://github.com/mbrn/material-table/issues/1480 to learn more
    useMemo(() => {
        tableRef?.current?.dataManager?.changePageSize(props.data.length);
    }, [props.options.pageSize]);

    return (
        <MaterialTable
            tableRef={tableRef}
            options={props.options}
            icons={props.icons}
            title={props.title}
            columns={props.columns}
            data={props.data}
            editable={{
                onRowUpdate: (newData: any, oldData: any) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            props.onUpdate(newData, oldData);
                            // Workaround for table due to a bug in this and higher versions when updating values
                            // Visit https://github.com/mbrn/material-table/issues/1480 to learn more
                            tableRef?.current?.dataManager?.setData(props.data);
                        }, 100);
                    }),
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            props.onAdd(newData);
                        }, 100);
                    }),
                onRowDelete: (oldData: any) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            props.onDelete(oldData);
                        }, 100);
                    })
            }}
        />
    );
};
