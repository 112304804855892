import { IDealerLimits } from '../types';
import { asSafeNumber } from './selectorUtils';

export function asSafeLimits(limits?: IDealerLimits): IDealerLimits {
    if (!limits) {
        const defaultLimits: IDealerLimits = {
            mileage: asSafeNumber(),
            price: asSafeNumber(),
            year: asSafeNumber()
        };
        return defaultLimits;
    }
    const newLimits: IDealerLimits = {
        mileage: asSafeNumber(limits.mileage),
        price: asSafeNumber(limits.price),
        year: asSafeNumber(limits.year)
    };
    return newLimits;
}
