import { IHandleUpdateFieldChange, IHandleFieldChange } from '../../../store/manager/types';
import MaterialTable, { Column, Options } from 'material-table';
import React, { FunctionComponent, useMemo, useRef } from 'react';
import { DR3FieldWrapper } from '../../common/DR3FieldWrapper';
import { tableIcons } from '../../common/utils';
import { useStyles } from '../../common/DR3PartnerMappingTable.style';
import { IDealerMap } from '../types';

interface IDR3DealAccessMappingTableProps {
    mappingData: IDealerMap[];
    onUpdate: IHandleUpdateFieldChange<IDealerMap>;
    onAdd: IHandleFieldChange<IDealerMap>;
    onDelete: IHandleFieldChange<IDealerMap>;
    label: string;
    columns: Column<IDealerMap>[];
    tableOptions: Options<IDealerMap>;
}

export const DR3DealAccessMappingTable: FunctionComponent<IDR3DealAccessMappingTableProps> = (props) => {
    const tableRef = useRef<any>(null);

    // Workaround for table due to a bug in this and higher versions
    // Visit https://github.com/mbrn/material-table/issues/1480 to learn more
    useMemo(() => {
        tableRef?.current?.dataManager?.changePageSize(props.tableOptions.pageSize);
    }, [props.tableOptions.pageSize]);

    const classes = useStyles({});

    return (
        <DR3FieldWrapper label={props.label} isTableData={true} className={classes.partnerMappingsSection}>
            <MaterialTable
                tableRef={tableRef}
                style={{ height: '100%', overflowY: 'auto' }}
                options={props.tableOptions}
                icons={tableIcons}
                title={props.label}
                columns={props.columns}
                data={props.mappingData}
                editable={{
                    onRowUpdate: (newData: any, oldData: any) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve(props.onUpdate(newData, oldData));
                            }, 100);
                        }),
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve(props.onAdd(newData));
                            }, 100);
                        }),
                    onRowDelete: (oldData: any) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve(props.onDelete(oldData));
                            }, 100);
                        })
                }}
            />
        </DR3FieldWrapper>
    );
};
