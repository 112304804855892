import React, { FunctionComponent } from 'react';
import { IDR3LabelProps, DRFieldLabel } from './DRFieldLabel';
import useStyles from '../layout/styles';
import { Box } from '@material-ui/core';

export const DR3FieldWrapper: FunctionComponent<IDR3LabelProps> = (props) => {
    const classes = useStyles({});
    const className = props.className ? props.className : classes.fieldWrapperWithMargin;

    if (props.creditTier || props.leasingData || props.dealers || props.isTableData) {
        // no split because label is inside table
        return (
            <Box className={className} display="flex" justifyContent="center">
                <Box textAlign="right" alignItems="left" width="50%" flexGrow={1} order={1}>
                    {props.children}
                </Box>
            </Box>
        );
    } else {
        // makes a split down the middle of the page - label on left, field on right
        return (
            <Box className={className} display="flex" justifyContent="center">
                <Box textAlign="right" width="50%" flexGrow={1} order={1} paddingTop="1em" paddingRight="1em">
                    <DRFieldLabel label={props.label} sublabel={props.sublabel} tooltip={props.tooltip} />
                </Box>
                <Box alignItems="left" width="50%" flexGrow={1} order={2}>
                    {props.children}
                </Box>
            </Box>
        );
    }
};
