import { IDealerTerms } from '../types';
import { asSafeEmptyString, asSafeBoolean, asSafeNumber } from './selectorUtils';

export function asSafeTerms(terms?: IDealerTerms[]): IDealerTerms[] {
    if (!terms) {
        const defaultTerms: IDealerTerms[] = [
            {
                _id: asSafeEmptyString(),
                isDefault: asSafeBoolean(),
                months: asSafeNumber()
            }
        ];
        return defaultTerms;
    }
    const termsArray: IDealerTerms[] = [];

    terms.forEach((element) => {
        element._id = asSafeEmptyString(element._id);
        element.isDefault = asSafeBoolean(element.isDefault);
        element.months = asSafeNumber(element.months);

        termsArray.push(element);
    });
    return termsArray;
}
