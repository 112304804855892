import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioStringField } from '../common/DR3RadioStringField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const defaultDealType = value;
        const newDealer = {
            ...oldDealer,
            defaultDealType
        };
        updateDealerCallback(null, newDealer);
    };
}

const DefaultDealType: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (
        !props.dealer ||
        (!props.dealer.products.hasLeasing && (!props.dealer.products.hasNewCashOffer || !props.dealer.products.hasUsedCashOffer))
    ) {
        return null;
    }
    const value = props.dealer.defaultDealType;
    const hasLeasing = props.dealer.products.hasLeasing;
    const hasNewCashOffer = props.dealer.products.hasNewCashOffer;
    const hasUsedCashOffer = props.dealer.products.hasUsedCashOffer;
    let disableLeaseOption = false;
    if (!hasLeasing && hasNewCashOffer && hasUsedCashOffer) {
        disableLeaseOption = true;
    }
    return (
        <DR3RadioStringField
            trueOption="Lease"
            falseOption="Finance"
            label="Default Payment Type"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            disabled={disableLeaseOption}
        />
    );
};
export default DefaultDealType;
