import React, { FunctionComponent } from 'react';
import { INumberDropdownOptions } from '../../store/manager/types';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { FormControl, Select, OutlinedInput, MenuItem } from '@material-ui/core';

interface IDR3DropdownNumberFieldProps {
    label: string;
    value: number;
    disabled?: boolean;
    handleChange: (value: number) => void;
    options: INumberDropdownOptions[];
}

// saves a number value
export const DR3DropdownNumberField: FunctionComponent<IDR3DropdownNumberFieldProps> = (props) => {
    const selected = props.value;
    function handleChange(event: React.ChangeEvent<unknown>) {
        const value = (event.target as HTMLInputElement).value;
        const numberValue = parseInt(value, 10);
        return props.handleChange(numberValue);
    }
    const isDisabled = !!props.disabled;
    return (
        <DR3FieldWrapper label={props.label}>
            <div>
                <FormControl component="fieldset">
                    <Select
                        value={selected}
                        disabled={isDisabled}
                        onChange={handleChange}
                        input={<OutlinedInput labelWidth={0} name={props.label} />}
                    >
                        {props.options.map((element) => {
                            return (
                                <MenuItem key={element.optionValue} value={element.optionValue}>
                                    {element.optionLabel}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </DR3FieldWrapper>
    );
};
