import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { VictoryPie } from 'victory';
import Paper from '@material-ui/core/Paper';

interface IDealerMetricsPieChartProps {
    total: number;
    part: number;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '46%',
            margin: '2rem 1rem 1rem 1rem',
            height: '450px'
        }
    })
);

const DealerMetricsPieChart: FunctionComponent<IDealerMetricsPieChartProps> = ({ total, part }) => {
    const classes = useStyles();
    if (!total || !part) {
        return null;
    }

    const partPercentage: number = Math.round((part * 100) / total);
    const otherPercentage: number = 100 - partPercentage;
    const otherQuantity = total - part;

    return (
        <Paper className={classes.root}>
            <VictoryPie
                data={[
                    { label: `Matched (${part})`, y: partPercentage },
                    { label: `Other (${otherQuantity})`, y: otherPercentage }
                ]}
                animate={{ duration: 1000 }}
                colorScale={['#49da8d', '#3f51b5']}
                style={{
                    labels: { fontSize: '.8rem', position: 'relative', display: 'block' }
                }}
                width={600}
            />
        </Paper>
    );
};

export default DealerMetricsPieChart;
