import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { ErrorMessages } from '../common/utils';
import { DR3TextField } from '../common/DR3TextField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const city = value;
        const address = {
            ...oldDealer.address,
            city
        };
        updateDealerCallback(null, {
            ...oldDealer,
            address
        });
    };
}
function isRequiredFn(dealer: IDealerToManage) {
    return !dealer.address.city;
}
const DealershipCity: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.address.city;
    const isRequired = isRequiredFn(props.dealer);
    let errorText = '';
    if (isRequired && !value) {
        errorText = ErrorMessages.MISSING_FIELD;
    }
    return (
        <DR3TextField
            label="City"
            sublabel="required"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            errorText={errorText}
            isRequired={isRequiredFn(props.dealer)}
        />
    );
};
export default DealershipCity;
