import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';
import { getNewLineText } from '../common/utils';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        let updatedDealer;
        const creditProvider = value;
        switch (value) {
            case 'external': {
                updatedDealer = {
                    ...oldDealer,
                    creditProvider,
                    isDealertrackCreditProvider: false
                };
                break;
            }
            case 'dealertrack': {
                updatedDealer = {
                    ...oldDealer,
                    creditProvider,
                    isDealertrackCreditProvider: true
                };
                break;
            }
            case 'r1_dealertrack': {
                updatedDealer = {
                    ...oldDealer,
                    creditProvider,
                    isDealertrackCreditProvider: true
                };
                break;
            }
            case 'ideal': {
                updatedDealer = {
                    ...oldDealer,
                    creditProvider,
                    isDocUploadEnabled: false,
                    isDealertrackCreditProvider: true,
                    useFnI2: true
                };
                break;
            }
        }
        updateDealerCallback(null, updatedDealer);
    };
}

const themeOptions: IDropdownOptions[] = [
    { optionValue: 'external', optionLabel: 'External URL' },
    { optionValue: 'r1_dealertrack', optionLabel: 'RouteOne & Dealertrack' },
    { optionValue: 'dealertrack', optionLabel: 'Dealertrack' },
    { optionValue: 'ideal', optionLabel: 'iDeal' }
];

const getCreditProviderValueIfPreExisting = (props: any) => {
    let creditProviderInitialValue;
    const { dealer, updateDealerCallback } = props;
    if (!dealer.creditProvider || (dealer.creditProvider && dealer.creditProvider === '')) {
        creditProviderInitialValue = dealer.isDealertrackCreditProvider === false ? 'external' : 'dealertrack';
        const updatedDealer = {
            ...dealer,
            creditProvider: creditProviderInitialValue
        };
        updateDealerCallback(null, updatedDealer);
    } else {
        creditProviderInitialValue = dealer.creditProvider;
    }

    return creditProviderInitialValue;
};

const tooltip =
    // eslint-disable-next-line max-len
    'Selecting External requires a credit application URL, this will add a button that redirects the credit application to that URL.\n' +
    // eslint-disable-next-line max-len
    'Selecting RouteOne & Dealertrack requires a RouteOne Party ID and credit applications will go to both RouteOne and to the Dealertrack Dealer ID.\n' +
    'Selecting Dealertrack will route credit applications to the Dealertrack Dealer ID.\n' +
    // eslint-disable-next-line max-len
    'Selecting iDeal will route credit applications to the Dealertrack Dealer ID and iDeal. Disclaimer:  Confirm this dealership is active with iDeal before completing.\n';

const CreditProvider: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3DropdownField
            label={ToggleLabelMap.isDealertrackCreditProvider}
            value={getCreditProviderValueIfPreExisting(props)}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltip={getNewLineText(tooltip)}
            options={themeOptions}
        />
    );
};
export default CreditProvider;
