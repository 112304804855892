import withApi from '../../../utils/withApi';
import React, { FunctionComponent } from 'react';
import { withAuth } from '@okta/okta-react';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline, AppBar, Typography, Toolbar, Container, Button } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import HostedLogin from '../../HostedLogin';
import themeOverride from '../../layout/theme';
import { UpdateStatus } from './UpdatedBy';
import { ClientChangedBy } from '../types';
import { dateUtils } from '@makemydeal/dr-platform-shared';

type HeaderWrapperProps = {
    onClickSave?: () => {};
    onClickHome?: () => void;
    containerClassName: string;
    children: any;
    updatedBy: ClientChangedBy;
};

export const HeaderWrapper: FunctionComponent<HeaderWrapperProps> = ({
    children,
    onClickSave,
    onClickHome,
    containerClassName,
    updatedBy
}) => {
    const imageHeight = '14px';
    const centerText = {
        paddingLeft: '90px',
        paddingRight: '0px',
        marginRight: '165px'
    };
    const handleSaveClick = () => {
        return !onClickSave ? null : onClickSave();
    };
    const handleHomeClick = () => {
        return !onClickHome ? null : onClickHome();
    };

    return (
        <ThemeProvider theme={themeOverride}>
            <div className="root">
                <CssBaseline />
                <AppBar position="fixed" className="header">
                    <Toolbar>
                        <div className="menuAndSave">
                            <Button onClick={handleHomeClick} className="button">
                                <HomeIcon></HomeIcon>
                            </Button>
                            <Button onClick={handleSaveClick} className="button">
                                Save Changes
                            </Button>
                        </div>
                        <Typography variant="h6" className="title" style={centerText}>
                            <img
                                alt="Accelerate Logo"
                                src="https://accelerate.dealer.com/static/1.1.118/img/Accelerate_My_Deal_Logo_White_Text.png"
                                height={imageHeight}
                            />
                            <span style={{ height: '14rem', marginLeft: '3px' }}>ADMIN</span>
                            {updatedBy.user && (
                                <UpdateStatus
                                    updatedBy={updatedBy.user}
                                    updatedOn={dateUtils.formatAsMMDDYYYY(updatedBy.timeStamp)}
                                ></UpdateStatus>
                            )}
                        </Typography>
                        <HostedLogin />
                    </Toolbar>
                </AppBar>
                <main className="content">
                    <Container className={containerClassName}>{children}</Container>
                </main>
            </div>
        </ThemeProvider>
    );
};

export default withAuth(withApi(HeaderWrapper));
