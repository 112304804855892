import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const ecommercePartner = value;
        updateDealerCallback(null, {
            ...oldDealer,
            ecommercePartner
        });
    };
}

const partnerOptions: IDropdownOptions[] = [
    {
        optionValue: 'esntial',
        optionLabel: 'Esntial'
    }
];

const EcommercePartnerDropdown: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3DropdownField
            label="Ecommerce Partner"
            value={props.dealer.ecommercePartner}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={partnerOptions}
        />
    );
};
export default EcommercePartnerDropdown;
