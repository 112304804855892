import React from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const theme = value;
        updateDealerCallback(null, {
            ...oldDealer,
            theme
        });
    };
}

function handleChangeRedesign(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const themeRedesign = value;
        updateDealerCallback(null, {
            ...oldDealer,
            themeRedesign
        });
    };
}

const themeOptions: IDropdownOptions[] = [
    {
        optionValue: 'bmw.css',
        optionLabel: 'BMW Compliant'
    },
    {
        optionValue: 'infiniti.css',
        optionLabel: 'Infiniti Compliant'
    },
    {
        optionValue: 'lexus.css',
        optionLabel: 'Lexus Compliant'
    },
    {
        optionValue: 'dealerdotcom.css',
        optionLabel: 'Dealer.com'
    },
    {
        optionValue: 'toyota.css',
        optionLabel: 'Toyota Compliant'
    },
    {
        optionValue: 'volvo.css',
        optionLabel: 'Volvo Compliant'
    },
    {
        optionValue: 'light-gray.css',
        optionLabel: 'DDC Gray'
    },
    {
        optionValue: 'light-red.css',
        optionLabel: 'Light Red'
    },
    {
        optionValue: 'light-blue.css',
        optionLabel: 'Light Blue'
    },
    {
        optionValue: 'audi.css',
        optionLabel: 'Audi Compliant'
    },
    {
        optionValue: 'volkswagen.css',
        optionLabel: 'Volkswagen Compliant'
    },
    {
        optionValue: 'dark-blue.css',
        optionLabel: 'Dark Blue'
    },
    {
        optionValue: 'dark-red.css',
        optionLabel: 'Dark Red'
    },
    {
        optionValue: 'jaguar.css',
        optionLabel: 'Jaguar'
    },
    {
        optionValue: 'light-gray-red.css',
        optionLabel: 'Light Gray Red'
    },
    {
        optionValue: 'mercedes.css',
        optionLabel: 'Mercedes Compliant'
    },
    {
        optionValue: 'amsi.css',
        optionLabel: 'AMSI Compliant'
    },
    {
        optionValue: 'nissan.css',
        optionLabel: 'Nissan Compliant'
    },
    {
        optionValue: 'kia.css',
        optionLabel: 'Kia Compliant'
    },
    {
        optionValue: 'mazda.css',
        optionLabel: 'Mazda Compliant'
    },
    {
        optionValue: 'land-rover.css',
        optionLabel: 'Land Rover Compliant'
    },
    {
        optionValue: 'subaru.css',
        optionLabel: 'Subaru Compliant'
    },
    {
        optionValue: 'alfa-romeo.css',
        optionLabel: 'Alfa-Romeo Compliant'
    },
    {
        optionValue: 'mini.css',
        optionLabel: 'Mini Compliant'
    },
    {
        optionValue: 'acura.css',
        optionLabel: 'Acura Compliant'
    },
    {
        optionValue: 'fiat.css',
        optionLabel: 'Fiat Compliant'
    },
    {
        optionValue: 'mitsubishi.css',
        optionLabel: 'Mitsubishi Compliant'
    },
    {
        optionValue: 'echopark.css',
        optionLabel: 'EchoPark Compliant'
    },
    {
        optionValue: 'porsche.css',
        optionLabel: 'Porsche Compliant'
    },
    {
        optionValue: 'hertz.css',
        optionLabel: 'Hertz Compliant'
    },
    {
        optionValue: 'park-place.css',
        optionLabel: 'Park Place Compliant'
    },
    {
        optionValue: 'maserati.css',
        optionLabel: 'Maserati Compliant'
    },
    {
        optionValue: 'hyundai.css',
        optionLabel: 'Hyundai Compliant'
    }
];

// alphabetize dropdown options
themeOptions.sort((a, b) => (a.optionLabel > b.optionLabel ? 1 : -1));
// add default option at the beginning
themeOptions.unshift({
    optionValue: '',
    optionLabel: 'Default'
});

const themeOptionsRedesign = [
    {
        optionValue: 'turbo.css',
        optionLabel: 'Turbo'
    },
    {
        optionValue: 'global-blue.css',
        optionLabel: 'Global Blue'
    },
    {
        optionValue: 'global-light-theme-gray-true-blue.css',
        optionLabel: 'Gray True Blue'
    },
    {
        optionValue: 'global-light-theme-gray-sky-blue.css',
        optionLabel: 'Gray Sky Blue'
    },
    {
        optionValue: 'global-light-theme-gray-navy-blue.css',
        optionLabel: 'Gray Navy Blue'
    },
    {
        optionValue: 'global-light-theme-gray-candy-red.css',
        optionLabel: 'Gray Candy Red'
    },
    {
        optionValue: 'global-light-theme-gray-scarlet-red.css',
        optionLabel: 'Gray Scarlet Red'
    },
    {
        optionValue: 'global-light-theme-gray-silver-gray.css',
        optionLabel: 'Gray Silver Gray'
    },
    {
        optionValue: 'brand-subaru.css',
        optionLabel: 'Subaru'
    },
    {
        optionValue: 'brand-hyundai.css',
        optionLabel: 'Hyundai'
    },
    {
        optionValue: 'brand-cjdr.css',
        optionLabel: 'CJDR'
    },
    {
        optionValue: 'brand-ford.css',
        optionLabel: 'Ford'
    },
    {
        optionValue: 'brand-audi.css',
        optionLabel: 'Audi'
    },
    {
        optionValue: 'brand-lexus.css',
        optionLabel: 'Lexus'
    },
    {
        optionValue: 'brand-bmw.css',
        optionLabel: 'BMW'
    },
    {
        optionValue: 'brand-nissan.css',
        optionLabel: 'Nissan'
    },
    {
        optionValue: 'brand-volvo.css',
        optionLabel: 'Volvo'
    },
    {
        optionValue: 'brand-gm.css',
        optionLabel: 'GM'
    },
    {
        optionValue: 'brand-buick.css',
        optionLabel: 'Buick'
    },
    {
        optionValue: 'brand-chevrolet.css',
        optionLabel: 'Chevrolet'
    },
    {
        optionValue: 'brand-gmc.css',
        optionLabel: 'GMC'
    },
    {
        optionValue: 'brand-toyota.css',
        optionLabel: 'Toyota'
    },
    {
        optionValue: 'brand-jaguar.css',
        optionLabel: 'Jaguar'
    },
    {
        optionValue: 'brand-land-rover.css',
        optionLabel: 'Land Rover'
    },
    {
        optionValue: 'brand-vw.css',
        optionLabel: 'VW'
    },
    {
        optionValue: 'brand-kia.css',
        optionLabel: 'Kia'
    },
    {
        optionValue: 'brand-mazda.css',
        optionLabel: 'Mazda'
    },
    {
        optionValue: 'brand-acura.css',
        optionLabel: 'Acura'
    },
    {
        optionValue: 'brand-mitsubishi.css',
        optionLabel: 'Mitsubishi'
    },
    {
        optionValue: 'brand-porsche.css',
        optionLabel: 'Porsche'
    },
    {
        optionValue: 'brand-infiniti.css',
        optionLabel: 'Infiniti'
    },
    {
        optionValue: 'brand-maserati.css',
        optionLabel: 'Maserati'
    },
    {
        optionValue: 'brand-cadillac.css',
        optionLabel: 'Cadillac'
    },
    {
        optionValue: 'brand-lincoln.css',
        optionLabel: 'Lincoln'
    },
    {
        optionValue: 'brand-buick-gmc.css',
        optionLabel: 'Buick-GMC'
    },
    {
        optionValue: 'brand-alfa-romeo.css',
        optionLabel: 'Alfa Romeo'
    },
    {
        optionValue: 'brand-mini.css',
        optionLabel: 'MINI'
    },
    {
        optionValue: 'brand-mercedes.css',
        optionLabel: 'Mercedes'
    },
    {
        optionValue: 'brand-genesis.css',
        optionLabel: 'Genesis'
    }
].sort((a, b) => a.optionLabel.localeCompare(b.optionLabel));
themeOptionsRedesign.unshift({
    optionValue: '',
    optionLabel: 'Default'
});

export default (props: ICommonManagerFieldProps) => {
    const { dealer } = props;

    if (!dealer) {
        return null;
    }

    const theme = dealer.theme;
    const themeRedesign = dealer.themeRedesign;

    const isRedesignEnabled = !!dealer.isRedesignEnabled;

    return isRedesignEnabled ? (
        <DR3DropdownField
            label="Theme"
            value={themeRedesign}
            handleChange={handleChangeRedesign(dealer, props.updateDealerCallback)}
            options={themeOptionsRedesign}
        />
    ) : (
        <DR3DropdownField
            label="Theme"
            value={theme}
            handleChange={handleChange(dealer, props.updateDealerCallback)}
            options={themeOptions}
        />
    );
};
