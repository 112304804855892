import { IDealerSiteToggles } from '../types';
import { asSafeBoolean } from './selectorUtils';

export function asSafeDealerSiteToggles(dealerSiteToggles?: IDealerSiteToggles): IDealerSiteToggles {
    if (!dealerSiteToggles) {
        const defaultToggles: IDealerSiteToggles = {
            isDefaultHeaderTextEnabled: asSafeBoolean(),
            isVDPLeadFormEnabled: asSafeBoolean()
        };
        return defaultToggles;
    }
    const toggles: IDealerSiteToggles = {
        isDefaultHeaderTextEnabled: asSafeBoolean(dealerSiteToggles.isDefaultHeaderTextEnabled),
        isVDPLeadFormEnabled: asSafeBoolean(dealerSiteToggles.isVDPLeadFormEnabled)
    };
    return toggles;
}
