import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const canNegotiateUsed = value;
        updateDealerCallback(null, {
            ...oldDealer,
            canNegotiateUsed
        });
    };
}

const CanNegotiateUsed: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3RadioBooleanField
            trueOption="Negotiate"
            falseOption="Single Price"
            label={ToggleLabelMap.canNegotiateUsed}
            value={props.dealer.canNegotiateUsed}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default CanNegotiateUsed;
