import React, { FunctionComponent } from 'react';
import { IHandleFieldChange } from '../../store/manager/types';
import { FormControlLabel, Checkbox, CheckboxProps } from '@material-ui/core';

interface IDR3CheckboxFieldProps extends CheckboxProps {
    label: string;
    handleChange?: IHandleFieldChange<boolean>;
}

export const DR3CheckboxField: FunctionComponent<IDR3CheckboxFieldProps> = (props) => {
    const { label, handleChange, ...otherProps } = props;

    function onChange(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        handleChange?.(checked);
    }

    return <FormControlLabel control={<Checkbox onChange={onChange} {...otherProps} />} label={label} />;
};
