import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3NumberTextField } from '../common/DR3NumberTextField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const year = value;
        const limits = {
            ...oldDealer.financing.limits,
            year
        };
        const financing = {
            ...oldDealer.financing,
            limits
        };
        updateDealerCallback(null, {
            ...oldDealer,
            financing
        });
    };
}
const FinancingYearLimit: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.financing.limits.year;
    return (
        <DR3NumberTextField
            label="Year Limit"
            tooltip="Years or newer"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default FinancingYearLimit;
