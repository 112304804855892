import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';
import { PaidDepositRestrictedStates } from '../../constants/AdminConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const state = value;
        const address = {
            ...oldDealer.address,
            state
        };
        let reservation = {
            ...oldDealer.reservation
        };

        if (PaidDepositRestrictedStates.includes(state)) {
            const reservationType = 'vehicleHold';
            const amount = 0;
            reservation = {
                ...oldDealer.reservation,
                reservationType,
                amount
            };
        }

        updateDealerCallback(null, {
            ...oldDealer,
            address,
            reservation
        });
    };
}

const stateOptions: IDropdownOptions[] = [
    {
        optionValue: 'AL',
        optionLabel: 'Alabama'
    },
    {
        optionValue: 'AK',
        optionLabel: 'Alaska'
    },
    {
        optionValue: 'AZ',
        optionLabel: 'Arizona'
    },
    {
        optionValue: 'AR',
        optionLabel: 'Arkansas'
    },
    {
        optionValue: 'CA',
        optionLabel: 'California'
    },
    {
        optionValue: 'CO',
        optionLabel: 'Colorado'
    },
    {
        optionValue: 'CT',
        optionLabel: 'Connecticut'
    },
    {
        optionValue: 'DE',
        optionLabel: 'Delaware'
    },
    {
        optionValue: 'FL',
        optionLabel: 'Florida'
    },
    {
        optionValue: 'GA',
        optionLabel: 'Georgia'
    },
    {
        optionValue: 'HI',
        optionLabel: 'Hawaii'
    },
    {
        optionValue: 'ID',
        optionLabel: 'Idaho'
    },
    {
        optionValue: 'IL',
        optionLabel: 'Illinois'
    },
    {
        optionValue: 'IN',
        optionLabel: 'Indiana'
    },
    {
        optionValue: 'IA',
        optionLabel: 'Iowa'
    },
    {
        optionValue: 'KS',
        optionLabel: 'Kansas'
    },
    {
        optionValue: 'KY',
        optionLabel: 'Kentucky'
    },
    {
        optionValue: 'LA',
        optionLabel: 'Louisiana'
    },
    {
        optionValue: 'ME',
        optionLabel: 'Maine'
    },
    {
        optionValue: 'MD',
        optionLabel: 'Maryland'
    },
    {
        optionValue: 'MA',
        optionLabel: 'Massachusetts'
    },
    {
        optionValue: 'MI',
        optionLabel: 'Michigan'
    },
    {
        optionValue: 'MN',
        optionLabel: 'Minnesota'
    },
    {
        optionValue: 'MS',
        optionLabel: 'Mississippi'
    },
    {
        optionValue: 'MO',
        optionLabel: 'Missouri'
    },
    {
        optionValue: 'MT',
        optionLabel: 'Montana'
    },
    {
        optionValue: 'NE',
        optionLabel: 'Nebraska'
    },
    {
        optionValue: 'NV',
        optionLabel: 'Nevada'
    },
    {
        optionValue: 'NH',
        optionLabel: 'New Hampshire'
    },
    {
        optionValue: 'NJ',
        optionLabel: 'New Jersey'
    },
    {
        optionValue: 'NM',
        optionLabel: 'New Mexico'
    },
    {
        optionValue: 'NY',
        optionLabel: 'New York'
    },
    {
        optionValue: 'NC',
        optionLabel: 'North Carolina'
    },
    {
        optionValue: 'ND',
        optionLabel: 'North Dakota'
    },
    {
        optionValue: 'OH',
        optionLabel: 'Ohio'
    },
    {
        optionValue: 'OK',
        optionLabel: 'Oklahoma'
    },
    {
        optionValue: 'OR',
        optionLabel: 'Oregon'
    },
    {
        optionValue: 'PA',
        optionLabel: 'Pennsylvania'
    },
    {
        optionValue: 'RI',
        optionLabel: 'Rhode Island'
    },
    {
        optionValue: 'SC',
        optionLabel: 'South Carolina'
    },
    {
        optionValue: 'SD',
        optionLabel: 'South Dakota'
    },
    {
        optionValue: 'TN',
        optionLabel: 'Tennessee'
    },
    {
        optionValue: 'TX',
        optionLabel: 'Texas'
    },
    {
        optionValue: 'UT',
        optionLabel: 'Utah'
    },
    {
        optionValue: 'VT',
        optionLabel: 'Vermont'
    },
    {
        optionValue: 'VA',
        optionLabel: 'Virginia'
    },
    {
        optionValue: 'WA',
        optionLabel: 'Washington'
    },
    {
        optionValue: 'WV',
        optionLabel: 'West Virginia'
    },
    {
        optionValue: 'WI',
        optionLabel: 'Wisconsin'
    },
    {
        optionValue: 'WY',
        optionLabel: 'Wyoming'
    }
];

const DealershipState: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3DropdownField
            label="State"
            value={props.dealer.address.state}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={stateOptions}
        />
    );
};
export default DealershipState;
