import React, { FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { DR3FieldWrapper } from '../common/DR3FieldWrapper';
import { FormControl } from '@material-ui/core';
import { IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';

const useStyles = makeStyles((theme) => ({
    resetButton: {
        marginTop: theme.spacing(2)
    }
}));

function handleChange(oldDealer: any, props: any) {
    const { updateDealerCallback, resetListingOverridesCallback } = props;
    return (): void => {
        const newValues = Object.entries(oldDealer.listingOverride).reduce((allOverrides: any, currentOverride) => {
            const key = currentOverride[0];
            if (oldDealer[key]) {
                allOverrides[key] = oldDealer[key];
            } else {
                allOverrides[key] = oldDealer.listingOverride[key];
            }
            return allOverrides;
        }, {});

        const newDealer = { ...oldDealer };
        newDealer.listingOverride = { ...newValues };

        updateDealerCallback(null, newDealer);
        resetListingOverridesCallback('Successfully reset the Listing Site settings back to the Website settings.');
    };
}

// tslint:disable-next-line:variable-name
const ResetToDealerSettingsButton: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    const classes = useStyles();

    if (!props.dealer) {
        return null;
    }

    return (
        <DR3FieldWrapper
            label={'Reset to Website Settings'}
            tooltip={
                'Clicking this button will overwrite all of the above Listing Site Settings with the existing Website Settings'
            }
        >
            <FormControl fullWidth component="fieldset">
                <Button onClick={handleChange(props.dealer, props)} id="Reset-Button" className={classes.resetButton}>
                    Reset
                </Button>
            </FormControl>
        </DR3FieldWrapper>
    );
};

export default ResetToDealerSettingsButton;
