import React from 'react';
import { FunctionComponent } from 'react';
import { ToggleLabelMap } from '../../constants/ToggleConstants';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const selectn = require('selectn');

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const reviewDealUrlEnabled = value;
        const listingOverride = {
            ...oldDealer.listingOverride,
            reviewDealUrlEnabled
        };
        const newDealer = {
            ...oldDealer,
            listingOverride
        };
        updateDealerCallback(null, newDealer);
    };
}

const ListingReviewDealUrlEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = !!selectn('dealer.listingOverride.reviewDealUrlEnabled', props);
    const tooltip = "This setting enables sending the Shopper's URL to Dealer CRM";

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.reviewDealUrlEnabled}
            tooltipProps={tooltip}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default ListingReviewDealUrlEnabled;
