import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

const ListingTradeInHideExcellent: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || props.dealer.listingOverride.tradeInValuationProvider !== 'Kelley Blue Book') {
        return null;
    }

    const tooltip = 'When this setting is "Yes", the shopper will see: "Very Good", "Good", and "Fair".';

    const handleChange = (oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) => {
        return (value: boolean): void => {
            const listingOverride = {
                ...oldDealer.listingOverride,
                tradeInHideExcellent: value
            };
            const newDealer = {
                ...oldDealer,
                listingOverride
            };
            updateDealerCallback(null, newDealer);
        };
    };

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.tradeInHideExcellent}
            value={!!props.dealer.listingOverride.tradeInHideExcellent}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default ListingTradeInHideExcellent;
