import React, { FunctionComponent } from 'react';
import { FormGroup, Box } from '@material-ui/core';

interface IDR3CheckboxGroupProps {
    tooltipProps?: string;
}

export const DR3CheckboxGroup: FunctionComponent<IDR3CheckboxGroupProps> = (props) => {
    const [firstChild, ...children] = React.Children.toArray(props.children);

    return (
        <Box display="flex" justifyContent="center">
            <FormGroup row={true}>
                {firstChild ? <Box>{firstChild}</Box> : null}

                {React.Children.map(children, (child) => {
                    return <Box pl={2}>{child}</Box>;
                })}
            </FormGroup>
        </Box>
    );
};
