import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const shouldSendCreditDecisionEmail = value;
        const creditApp = {
            ...oldDealer.creditApp,
            shouldSendCreditDecisionEmail
        };
        const newDealer = {
            ...oldDealer,
            creditApp
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip =
    'Important: Use the "Send Credit Decision Email" toggle to control ' +
    'whether or not Accelerate will send an email to user with credit decision.';

const ShouldSendCreditDecisionEmail: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = !!props.dealer.creditApp.shouldSendCreditDecisionEmail;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.shouldSendCreditDecisionEmail}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default ShouldSendCreditDecisionEmail;
