import { IListingOverrideSiteToggles } from '../types';
import { asSafeBoolean } from './selectorUtils';

// tslint:disable-next-line: max-line-length
export function asSafeListingOverrideSiteToggles(listingSiteToggles?: IListingOverrideSiteToggles): IListingOverrideSiteToggles {
    if (!listingSiteToggles) {
        const defaultToggles: IListingOverrideSiteToggles = {
            isVDPLeadFormEnabled: asSafeBoolean()
        };
        return defaultToggles;
    }
    const toggles: IListingOverrideSiteToggles = {
        isVDPLeadFormEnabled: asSafeBoolean(listingSiteToggles.isVDPLeadFormEnabled)
    };
    return toggles;
}
