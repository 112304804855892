import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, Paper, Typography, Grid, Box, Button, Switch } from '@material-ui/core';
import { DR3FieldWrapper } from '../common/DR3FieldWrapper';
import { DR3DealXGClientSearchTable } from './DR3DealXGClientSearchTable';
import { DealXGClientMap, DealXGClientMapDisplay, IDealXGClientManagerProps } from './types';
import { displayFormatDealXGClients, showDisabledClients, loadDealXGClients } from './utils';
import { Options } from 'material-table';
import { Config } from '../../config';

export const DealXGClientManagerContainer: FC<IDealXGClientManagerProps> = (props: IDealXGClientManagerProps) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(4),
            color: theme.palette.text.primary
        }
    }));

    const classes = useStyles();

    interface IClientSearchWrapperProps {
        classes: ReturnType<typeof useStyles>;
    }
    const [checked, setChecked] = useState(false);
    const [clients, setClients] = useState<DealXGClientMap[]>([]);
    const [displayClients, setDisplayClients] = useState<DealXGClientMapDisplay[]>([]);

    const getClients = async () => {
        const accessToken = await props.auth.getAccessToken();
        if (accessToken) {
            const clientsList = await loadDealXGClients(accessToken);

            setClients(clientsList);

            const displayClientsList = displayFormatDealXGClients(clientsList);
            setDisplayClients(showDisabledClients(displayClientsList, false));
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        const displayClientsList = displayFormatDealXGClients(clients);
        setDisplayClients(showDisabledClients(displayClientsList, event.target.checked));
    };

    useEffect(() => {
        getClients();
    }, []);

    const ClientSearchWrapper: FunctionComponent<IClientSearchWrapperProps> = ({ classes, children }) => {
        function createNewClient(): void {
            const url = `${Config.services.api.protocol + Config.services.api.host}/dealxg/client/0/manage`;
            window.open(url);
        }

        return (
            <Box paddingTop={'6em'}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Grid container alignContent="center" justify="space-between">
                            <Grid item>
                                <Switch checked={checked} onChange={handleChange} />
                                SHOW DISABLED ONLY
                            </Grid>
                            <Grid item>
                                <Typography align="center" gutterBottom variant="h4">
                                    DealXG Client Search
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={createNewClient}>
                                    ADD NEW CLIENT
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {children}
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Box>
        );
    };

    const searchTableOptions: Options<any> = {
        pageSize: 100,
        pageSizeOptions: [10, 25, 50, 100],
        showFirstLastPageButtons: false,
        search: true,
        showSelectAllCheckbox: true
    };

    return (
        <ClientSearchWrapper classes={classes}>
            <div>
                {
                    <DR3FieldWrapper label={'DealXG Client Search'} isTableData={true}>
                        <DR3DealXGClientSearchTable
                            clients={clients}
                            displayClients={displayClients}
                            searchTableOptions={searchTableOptions}
                            checked={checked}
                            setClients={setClients}
                            setDisplayClients={setDisplayClients}
                            auth={props.auth}
                        />
                    </DR3FieldWrapper>
                }
            </div>
        </ClientSearchWrapper>
    );
};
