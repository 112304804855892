module.exports = Object.freeze({
    AtcToggleData: {
        PRODUCT_ID: 'SMART_PAYMENT',
        ATTRIBUTE_NAME_NEW: 'NEW_CAR_DISPLAY',
        ATTRIBUTE_NAME_USED: 'USED_CAR_DISPLAY',
        ATTRIBUTE_NAME_CERTIFIED: 'CERTIFIED_CAR_DISPLAY',
        ATTRIBUTE_VALUES_TRUE: 'TRUE',
        ATTRIBUTE_VALUES_FALSE: 'FALSE',
        SOURCE: 'DDC'
    },

    AppConstants: {
        ADMIN_MODE: 'admin',
        ADMIN_INTERNAL_MODE: 'admininternal',
        DEFAULT_DEALER_BUCKET_MAPPING_PREFIX: 'dr-external-dealer-mappings',
        DEFAULT_DEALER_PARTNER_MAPPING_OBJECT: 'partnerMappings.json'
    },

    VinSolutionsCRMToggle: {
        OFF: 'Off',
        ADF_S3: 'ADF/S3'
    },

    PaidDepositRestrictedStates: ['AZ', 'CA', 'MD', 'OH', 'TN', 'UT', 'WA', 'WI'],

    DealCentralCMSToggle: {
        OFF: 'Off',
        CDMS: 'CDMS',
        CMDPlus: 'DMS+'
    }
});
