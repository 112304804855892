import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
    IDealerToManage,
    IValidatorMap,
    IUpdateDealerCallbackFn,
    IValidateCallbackFn,
    ISaveDealerCallbackFn,
    IUploadBrandingExpCallbackFn
} from '../store/manager/types';
import { Box, Typography } from '@material-ui/core';
import ListingCanNegotiateNew from './listingSiteFields/ListingCanNegotiateNew';
import ListingCanNegotiateUsed from './listingSiteFields/ListingCanNegotiateUsed';
import ListingEnableMaxCashIncentives from './listingSiteFields/ListingEnableMaxCashIncentives';
import ListingEnablePrePurchaseAgreement from './listingSiteFields/ListingEnablePrePurchaseAgreement';
import ListingExcludeStdCashIncsLease from './listingSiteFields/ListingExcludeStdCashIncsLease';
import ListingExcludeStdCashIncsLoan from './listingSiteFields/ListingExcludeStdCashIncsLoan';
import ListingHasWidgetNew from './listingSiteFields/ListingHasWidgetNew';
import ListingHasWidgetUsed from './listingSiteFields/ListingHasWidgetUsed';
import ListingExcludeSpecialAPRLoanOffersNewCar from './listingSiteFields/ListingExcludeSpecialAPRLoanOffersNewCar';
import ListingExcludeSpecialAPRLoanOffersUsedCar from './listingSiteFields/ListingExcludeSpecialAPRLoanOffersUsedCar';
import ListingExcludeSpecialAPRLeaseOffersNewCar from './listingSiteFields/ListingExcludeSpecialAPRLeaseOffersNewCar';
import ListingIsChatEnabled from './listingSiteFields/ListingIsChatEnabled';
import ListingIsDIOEnabled from './listingSiteFields/ListingIsDIOEnabled';
import ListingIsPhoneEnabled from './listingSiteFields/ListingIsPhoneEnabled';
import ListingIsSmsEnabled from './listingSiteFields/ListingIsSmsEnabled';
import ListingIsVDPLeadFormEnabled from './listingSiteFields/ListingIsVDPLeadFormEnabled';
import ListingSmsPhone from './listingSiteFields/ListingSmsPhone';
import ListingTrackingPhone from './listingSiteFields/ListingTrackingPhone';
import ListingTradeInValuationLeadForm from './listingSiteFields/ListingTradeInValuationLeadForm';
import ListingVehicleProtectionActive from './listingSiteFields/ListingVehicleProtectionActive';
import ListingTradeServiceProvider from './listingSiteFields/ListingTradeServiceProvider';
import ListingKbbHideExcellent from './listingSiteFields/ListingKbbHideExcellent';
import ListingKbbIcoURL from './listingSiteFields/ListingKbbIcoURL';
import ListingShowReservationDetail from './listingSiteFields/ListingShowReservationDetail';
import ListingReviewDealUrlEnabled from './listingSiteFields/ListingReviewDealUrlEnabled';
import ResetToDealerSettingsButton from './listingSiteFields/ResetToDealerSettingsButton';
import themeOverride from './layout/theme';
import HasUsedCashOffer from './listingSiteFields/ListingHasUsedCashOffer';
import HasNewCashOffer from './listingSiteFields/ListingHasNewCashOffer';
import ListingIsRedesignEnabled from './listingSiteFields/ListingIsRedesignEnabled';
import ListingExcludeStdCashIncsCash from './listingSiteFields/ListingExcludeStdCashIncsCash';
import ListingLeadGatePaymentOnDS from './listingSiteFields/ListingLeadGatePaymentOnDS';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(4),
        color: theme.palette.text.primary
    },
    adminMessage: {
        paddingTop: theme.spacing(4)
    }
}));

export interface IListingFieldsListUIProps {
    dealer: IDealerToManage | null;
    validatorMap: IValidatorMap;
    updateDealerCallback: IUpdateDealerCallbackFn;
    validateCallback: IValidateCallbackFn;
    saveDealerCallback: ISaveDealerCallbackFn;
    uploadBrandingExpCallback: IUploadBrandingExpCallbackFn;
    resetListingOverridesCallback: any;
    admin: boolean;
    dealerFieldsListSelected: boolean;
}
interface IListingFieldsWrapperProps {
    classes: ReturnType<typeof useStyles>;
}

// tslint:disable-next-line:variable-name
const ListingFieldsWrapper: FunctionComponent<IListingFieldsWrapperProps> = ({ classes, children }) => {
    return (
        <div className={`${classes.root} globalFieldsWrapper`}>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};
interface IListingFieldsSectionProps {
    title: string;
    hidden?: boolean;
    subtitle?: string;
}

// tslint:disable-next-line:variable-name
const ListingFieldsSection: FunctionComponent<IListingFieldsSectionProps> = ({ title, hidden, subtitle, children }) => {
    const id = title.split(' ').join('');
    if (hidden) {
        return null;
    } else {
        return (
            <Box id={id} paddingTop={'3.5em'}>
                <Typography align="center" gutterBottom variant="h4">
                    {title}
                </Typography>
                <Typography align="center" gutterBottom variant="h5" color="inherit">
                    {subtitle}
                </Typography>
                {children}
            </Box>
        );
    }
};

interface IListingFieldsSubSectionProps {
    title: string;
}

// tslint:disable-next-line:variable-name
const ListingFieldsSubSection: FunctionComponent<IListingFieldsSubSectionProps> = ({ title, children }) => {
    return (
        <Box paddingTop={'2em'}>
            <Typography align="center" gutterBottom variant="h5">
                {title}
            </Typography>
            {children}
        </Box>
    );
};

export default function ListingFieldsListUI(props: IListingFieldsListUIProps): JSX.Element {
    const classes = useStyles();
    const dealer = props.dealer;
    if (!dealer || props.dealerFieldsListSelected === false) {
        return <div />;
    }

    const pilotFeaturesText = 'This section is only to be used by product and readiness teams!';

    return (
        <ThemeProvider theme={themeOverride}>
            <ListingFieldsWrapper classes={classes}>
                {props.admin && (
                    <Typography align="center" color="secondary" className={classes.adminMessage}>
                        ADMIN MODE
                        <br />
                        For end-to-end testing of hidden fields.
                    </Typography>
                )}
                <div id={'JumpToAdminOptionsGeneral'}></div>

                <ListingFieldsSection title={'Base Features'}>
                    <ListingFieldsSubSection title={'Admin Options General'} />
                    <ListingHasWidgetNew {...props} />
                    <div id={'JumpToPaymentAndCredit'}></div>
                    <ListingHasWidgetUsed {...props} />

                    <ListingFieldsSubSection title={'Payment and Credit'} />
                    <HasUsedCashOffer {...props} />
                    <div id={'JumpToIncentives'}></div>
                    <HasNewCashOffer {...props} />
                    <ListingLeadGatePaymentOnDS {...props} />

                    <ListingFieldsSubSection title={'Incentives'} />
                    <ListingExcludeStdCashIncsLoan {...props} />
                    <ListingExcludeSpecialAPRLoanOffersNewCar {...props} />
                    <ListingExcludeSpecialAPRLoanOffersUsedCar {...props} />
                    <ListingExcludeStdCashIncsLease {...props} />
                    <ListingExcludeSpecialAPRLeaseOffersNewCar {...props} />
                    <ListingExcludeStdCashIncsCash {...props} />
                    <div id={'JumpToVehicleTradeIn'}></div>
                    <ListingEnableMaxCashIncentives {...props} />

                    <ListingFieldsSubSection title={'Vehicle Trade In'} />
                    <ListingTradeServiceProvider {...props} />
                    <ListingKbbIcoURL {...props} />
                    <div id={'JumpToConsumerExperienceOptions'}></div>
                    <ListingTradeInValuationLeadForm {...props} />
                    <ListingKbbHideExcellent {...props} />

                    <ListingFieldsSubSection title={'Consumer Experience Options'} />
                    <div id={'JumpToDealDefaultValues'}></div>
                    <ListingIsVDPLeadFormEnabled {...props} />

                    <ListingFieldsSubSection title={'Deal Default Values'} />
                    <ListingCanNegotiateNew {...props} />
                    <ListingCanNegotiateUsed {...props} />
                    <div id={'JumpToContactOptions'}></div>
                </ListingFieldsSection>

                <ListingFieldsSection title={'Additional Features Options'}>
                    <ListingFieldsSubSection title={'Contact Options'} />
                    <ListingIsChatEnabled {...props} />
                    <ListingIsPhoneEnabled {...props} />
                    <ListingTrackingPhone {...props} />
                    <div id={'JumpToCRM'}></div>
                    <ListingIsSmsEnabled {...props} />
                    <ListingSmsPhone {...props} />

                    <ListingFieldsSubSection title={'CRM'} />
                    <div id={'JumpToPrePurchaseAgreement/Reservation'}></div>
                    <ListingReviewDealUrlEnabled {...props} />

                    <ListingFieldsSubSection title={'Pre-Purchase Agreement/Reservation'} />
                    <ListingEnablePrePurchaseAgreement {...props} />
                    <ListingShowReservationDetail {...props} />
                    <div id={'JumpToVehicleProtection'}></div>

                    <ListingFieldsSubSection title={'Vehicle Protection'} />
                    <ListingVehicleProtectionActive {...props} />
                </ListingFieldsSection>

                <ListingFieldsSubSection title={'Reset'}>
                    <ResetToDealerSettingsButton {...props} />
                </ListingFieldsSubSection>

                <ListingFieldsSection title="Pilot Features">
                    <Typography align="center" color="error">
                        {pilotFeaturesText}
                    </Typography>
                    <ListingIsRedesignEnabled {...props} />
                    <ListingIsDIOEnabled {...props} />
                </ListingFieldsSection>
            </ListingFieldsWrapper>
        </ThemeProvider>
    );
}
