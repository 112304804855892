const config: any = {};
const envConfigs: any = {};
envConfigs.services = {};

const protocol = `${window.location.protocol}//`;

switch (process.env.REACT_APP_ENV) {
    case 'prod':
        envConfigs.services.api = {
            protocol, // 'https://',
            host: 'admin.accelerate.dealer.com',
            base: '/api/'
        };

        envConfigs.services.kbb = {
            protocol: 'https://',
            host: envConfigs.services.api.host,
            base: '/api/services/kbb'
        };

        envConfigs.services.gateway = {
            protocol: 'https://',
            host: 'gw-prod-live.awsdrst.makemydeal.com',
            base: '/'
        };

        envConfigs.services.featureToggles = {
            protocol: 'https://',
            host: 'feature-toggles-2.awsdrst.makemydeal.com',
            base: '/'
        };

        envConfigs.services.staticImages = {
            protocol: 'https://',
            host: 'accelerate.dealer.com',
            base: '/static/img'
        };

        envConfigs.urls = {
            desktopUrl: 'admin.accelerate.dealer.com',
            s3BrandingExpUrl: 'https://accelerate.dealer.com/static/img/branding_exp/',
            useNewAdminDealers: false
        };

        break;

    // no uat env at this time
    case 'uat':
        envConfigs.services.api = {
            protocol, // : 'https://',
            host: 'admin-uat.accelerate.dealer.com',
            base: '/api/'
        };

        envConfigs.services.kbb = {
            protocol: 'https://',
            host: envConfigs.services.api.host,
            base: '/api/services/kbb'
        };

        envConfigs.services.gateway = {
            protocol: 'https://',
            host: 'gw-prod-live.awsdrst.makemydeal.com',
            base: '/'
        };

        envConfigs.services.featureToggles = {
            protocol: 'https://',
            host: 'feature-toggles-2.awsdrst.makemydeal.com',
            base: '/'
        };

        envConfigs.services.staticImages = {
            protocol: 'https://',
            host: 'uat.accelerate.dealer.com',
            base: '/static/img'
        };

        envConfigs.urls = {
            desktopUrl: 'admin-uat.accelerate.dealer.com',
            s3BrandingExpUrl: 'https://uat.accelerate.dealer.com/static/img/branding_exp/',
            useNewAdminDealers: false
        };

        break;

    case 'qa':
        envConfigs.services.api = {
            protocol, // : 'https://',
            host: 'admin-qa.accelerate.dealer.com',
            base: '/api/'
        };

        envConfigs.services.kbb = {
            protocol: 'https://',
            host: envConfigs.services.api.host,
            base: '/api/services/kbb'
        };

        envConfigs.services.gateway = {
            protocol: 'https://',
            host: 'gw-prod-live.awsdrst.makemydeal.com',
            base: '/'
        };

        envConfigs.services.featureToggles = {
            protocol: 'https://',
            host: 'feature-toggles-2-qa.awsdrstnp.makemydeal.com',
            base: '/'
        };

        envConfigs.services.staticImages = {
            protocol: 'https://',
            host: 'qa.accelerate.dealer.com',
            base: '/static/img'
        };

        envConfigs.urls = {
            desktopUrl: 'admin-qa.accelerate.dealer.com',
            s3BrandingExpUrl: 'https://qa.accelerate.dealer.com/static/img/branding_exp/',
            useNewAdminDealers: false
        };

        break;

    case 'dev':
        envConfigs.services.api = {
            protocol, // : 'http://',
            host: 'admin-dev.accelerate.dealer.com',
            base: '/api/'
        };

        envConfigs.services.kbb = {
            protocol: 'https://',
            host: envConfigs.services.api.host,
            base: '/api/services/kbb'
        };

        envConfigs.services.gateway = {
            protocol: 'https://',
            host: 'gw-prod-live.awsdrst.makemydeal.com',
            base: '/'
        };

        envConfigs.services.featureToggles = {
            protocol: 'https://',
            host: 'feature-toggles-2-dev.awsdrstnp.makemydeal.com',
            base: '/'
        };

        envConfigs.services.staticImages = {
            protocol: 'https://',
            host: 'dev.accelerate.dealer.com',
            base: '/static/img'
        };

        envConfigs.urls = {
            desktopUrl: 'admin-dev.accelerate.dealer.com',
            s3BrandingExpUrl: 'https://dev.accelerate.dealer.com/static/img/branding_exp/',
            useNewAdminDealers: false
        };

        break;

    default:
        envConfigs.services.api = {
            protocol, // : 'http://',
            host: 'localhost:3000',
            base: '/api/'
        };

        envConfigs.services.kbb = {
            protocol: 'https://',
            host: envConfigs.services.api.host,
            base: '/api/services/kbb'
        };

        envConfigs.services.gateway = {
            protocol: 'https://',
            host: 'gw-prod-live.awsdrst.makemydeal.com',
            base: '/'
        };

        envConfigs.services.featureToggles = {
            protocol: 'https://',
            host: 'feature-toggles-2-dev.awsdrstnp.makemydeal.com',
            base: '/'
        };

        envConfigs.services.staticImages = {
            protocol: 'https://',
            host: 'dev.accelerate.dealer.com',
            base: '/static/img'
        };

        envConfigs.urls = {
            desktopUrl: 'admin-dev.accelerate.dealer.com',
            s3BrandingExpUrl: 'https://dev.accelerate.dealer.com/static/img/branding_exp/',
            useNewAdminDealers: false
        };
}

config.services = {
    api: {
        protocol: envConfigs.services.api.protocol,
        host: envConfigs.services.api.host,
        base: envConfigs.services.api.base,
        baseUrl: envConfigs.services.api.protocol + envConfigs.services.api.host + envConfigs.services.api.base
    },

    kbb: {
        protocol: envConfigs.services.kbb.protocol,
        host: envConfigs.services.kbb.host,
        base: envConfigs.services.kbb.base
    },

    gateway: {
        protocol: envConfigs.services.gateway.protocol,
        host: envConfigs.services.gateway.host,
        base: envConfigs.services.gateway.base,
        // tslint:disable-next-line: max-line-length
        baseUrl: envConfigs.services.gateway.protocol + envConfigs.services.gateway.host + envConfigs.services.gateway.base
    },
    featureToggles: {
        protocol: envConfigs.services.featureToggles.protocol,
        host: envConfigs.services.featureToggles.host,
        base: envConfigs.services.featureToggles.base,
        baseUrl:
            envConfigs.services.featureToggles.protocol +
            envConfigs.services.featureToggles.host +
            envConfigs.services.featureToggles.base
    },
    staticImages: {
        protocol: envConfigs.services.staticImages.protocol,
        host: envConfigs.services.staticImages.host,
        base: envConfigs.services.staticImages.base
    }
};

config.urls = {
    desktopUrl: envConfigs.urls.desktopUrl,
    s3BrandingExpUrl: envConfigs.urls.s3BrandingExpUrl,
    useNewAdminDealers: envConfigs.urls.useNewAdminDealers
};

config.rules = {
    // ..
};

config.env = process.env.REACT_APP_ENV;

export const Config = config;
