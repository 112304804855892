import { IGetDealerResponse } from '../types';
import { asSafeEmptyString } from './selectorUtils';

export function asSafeSponsorSetting(response: IGetDealerResponse): string {
    if (!response.sponsorSetting) {
        const defaultSponsorSetting = 'dealerSite';
        return asSafeEmptyString(defaultSponsorSetting);
    }
    const currentSponsorSetting = asSafeEmptyString(response.sponsorSetting);
    return currentSponsorSetting;
}
