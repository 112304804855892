import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const isVDPLeadFormEnabled = value;
        const dealerSiteToggles = {
            ...oldDealer.listingOverride.dealerSiteToggles,
            isVDPLeadFormEnabled
        };
        const listingOverride = {
            ...oldDealer.listingOverride,
            dealerSiteToggles
        };
        const newDealer = {
            ...oldDealer,
            listingOverride
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip =
    'If this field is enabled, the Lead form will show as the user is attempting to ' +
    'leave the Vehicle Detail page or access a "locked" action.';

const IsVDPLeadFormEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.listingOverride.dealerSiteToggles.isVDPLeadFormEnabled;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.isVDPLeadFormEnabled}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default IsVDPLeadFormEnabled;
