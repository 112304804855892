import { IDealerCreditTierAPR } from '../types';
import { asSafeEmptyString, asSafeBoolean, asSafeNumber } from './selectorUtils';

export function asSafeCreditTierAPR(apr?: IDealerCreditTierAPR[]): IDealerCreditTierAPR[] {
    if (!apr) {
        const defaultAPR: IDealerCreditTierAPR[] = [
            {
                _id: asSafeEmptyString(),
                isDefault: asSafeBoolean(),
                rate: asSafeNumber(),
                term: asSafeNumber()
            }
        ];
        return defaultAPR;
    }

    const dealerAprArray: IDealerCreditTierAPR[] = [];

    apr.forEach((element) => {
        element._id = asSafeEmptyString(element._id);
        element.isDefault = asSafeBoolean(element.isDefault);
        element.rate = asSafeNumber(element.rate);
        element.term = asSafeNumber(element.term);

        dealerAprArray.push(element);
    });

    return dealerAprArray;
}
