import { TextField, withStyles } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

export const ValidationTextField: React.ComponentType<TextFieldProps> = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderWidth: 1
        },
        '& input:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important'
        }
    }
})(TextField);
