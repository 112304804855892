import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const accountStatus = value;
        updateDealerCallback(null, {
            ...oldDealer,
            accountStatus
        });
    };
}

const options: IDropdownOptions[] = [
    {
        optionValue: 'live',
        optionLabel: 'Active - Live'
    },
    {
        optionValue: 'deactivated',
        optionLabel: 'Deactivated - Removed'
    }
];

const AccountStatus: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3DropdownField
            label="Account Status"
            value={props.dealer.accountStatus}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={options}
        />
    );
};
export default AccountStatus;
