import React, { FunctionComponent } from 'react';
import { IHandleFieldChange } from '../../store/manager/types';
import useStyles from '../layout/styles';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { ValidationTextField } from './ValidationTextField';
import { FormHelperText } from '@material-ui/core';

interface IDR3TextAreaFieldProps {
    label: string;
    sublabel?: string;
    value: string;
    handleChange: IHandleFieldChange<string>;
    multiline: boolean;
    rowsMax: number;
    errorText?: string;
    isRequired?: boolean;
    disabled?: boolean;
    tooltip?: string;
}
export const DR3TextAreaField: FunctionComponent<IDR3TextAreaFieldProps> = (props) => {
    function handleChange(event: React.ChangeEvent<unknown>) {
        const value = (event.target as HTMLInputElement).value;
        return props.handleChange(value);
    }
    const classes = useStyles({});
    const error = !!props.errorText;
    const isDisabled = !!props.disabled;
    const required = !!props.isRequired;
    return (
        <DR3FieldWrapper
            label={props.label}
            sublabel={props.sublabel}
            tooltip={props.tooltip}
            className={classes.fieldWrapperWithoutMargin}
        >
            <div>
                <ValidationTextField
                    variant="outlined"
                    className={classes.textField}
                    value={props.value}
                    disabled={isDisabled}
                    onChange={handleChange}
                    multiline={props.multiline}
                    rowsMax={props.rowsMax}
                    error={error}
                    required={required}
                />
                <FormHelperText>{props.errorText}</FormHelperText>
            </div>
        </DR3FieldWrapper>
    );
};
