export function asSafeEmptyString(str?: string) {
    return str ? str : '';
}
export function asSafeNullString(str: string | null) {
    return str ? str : null;
}
export function asSafeNumber(num?: number) {
    return num ? num : 0;
}
export function asSafeBoolean(bool?: boolean) {
    return !!bool;
}

export function asSafeBooleanDefaultTrue(bool?: boolean) {
    return bool === undefined ? true : bool;
}
