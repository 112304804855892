import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, ICommonManagerFieldProps } from '../../store/manager/types';
import { ToggleLabelMap } from '../../constants/ToggleConstants';
import { DR3CheckboxGroup } from '../common/DR3CheckboxGroup';
import { DR3CheckboxField } from '../common/DR3CheckboxField';

type ProductSetFields = 'accelerateMyDeal' | 'standaloneCreditApp' | 'ecommerce';

const ProductSet: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const { productSet } = props.dealer;

    function handleChange(fieldName: ProductSetFields, checked: boolean): void {
        const dealer = props.dealer as IDealerToManage;

        props.updateDealerCallback(null, {
            ...dealer,
            productSet: {
                ...dealer.productSet,
                [fieldName]: checked
            }
        });
    }

    return (
        <DR3CheckboxGroup>
            <DR3CheckboxField
                name="enableAccelerateMyDeal"
                title={'Enable Accelerate My deal'}
                label={ToggleLabelMap.accelerateMyDeal}
                checked={productSet.accelerateMyDeal}
                handleChange={(checked) => handleChange('accelerateMyDeal', checked)}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <DR3CheckboxField
                name="enableStandaloneCreditApp"
                title={'Enable Standalone Credit App'}
                label={ToggleLabelMap.standaloneCreditApp}
                checked={productSet.standaloneCreditApp}
                handleChange={(checked) => handleChange('standaloneCreditApp', checked)}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            {props.admin && (
                <DR3CheckboxField
                    name="enableEcommerce"
                    title={'Enable Ecommerce'}
                    label={ToggleLabelMap.ecommerce}
                    checked={productSet.ecommerce}
                    handleChange={(checked) => handleChange('ecommerce', checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            )}
        </DR3CheckboxGroup>
    );
};

export default ProductSet;
