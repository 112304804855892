import { IDealerAccessories } from '../types';
import { asSafeBoolean, asSafeNumber } from './selectorUtils';

export function asSafeDealerAccessories(accessories?: IDealerAccessories): IDealerAccessories {
    if (!accessories) {
        const defaultAccessories: IDealerAccessories = {
            hideMenuPricing: false,
            laborRate: asSafeNumber()
        };
        return defaultAccessories;
    }

    const newProducts: IDealerAccessories = {
        hideMenuPricing: asSafeBoolean(accessories.hideMenuPricing),
        laborRate: asSafeNumber(accessories.laborRate)
    };
    return newProducts;
}
