import React from 'react';
import { FunctionComponent } from 'react';
import {
    IDealerToManage,
    IInputAdornmentProps,
    IUpdateDealerCallbackFn,
    ICommonManagerFieldProps
} from '../../store/manager/types';
import { DR3TextFieldWithAdornment } from '../common/DR3TextFieldWithAdornment';
import { ErrorMessages } from '../common/utils';

let errorText = '';
function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        if (value === 0 || value === null || value === undefined) {
            errorText = 'Value must be greater than 0 when "Reservation Type" is "Paid Deposit"';
        } else {
            errorText = '';
        }
        const amount = value;
        const reservation = {
            ...oldDealer.reservation,
            amount
        };
        updateDealerCallback(null, {
            ...oldDealer,
            reservation
        });
    };
}

function isRequiredFn(dealer: IDealerToManage) {
    let disallowZero = false;
    if (dealer.reservation.showReservationDetail && dealer.reservation.reservationType === 'paidDeposit') {
        disallowZero = true;
    }
    return disallowZero;
}

const inputProps: IInputAdornmentProps = {
    position: 'start',
    unit: '$'
};

const ReservationAmount: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    let isDisabled = true;

    if (
        (props.dealer.reservation.showReservationDetail || props.dealer.listingOverride.reservation.showReservationDetail) &&
        props.dealer.reservation.reservationType === 'paidDeposit'
    ) {
        isDisabled = false;
    }

    const value = props.dealer.reservation.amount;
    if (value === 0 && isDisabled === false) {
        errorText = 'Value must be greater than 0 when "Reservation Type" is "Paid Deposit"';
    } else {
        errorText = '';
    }
    if (isDisabled) {
        errorText = ErrorMessages.AMOUNT_WARNING;
    }
    return (
        <DR3TextFieldWithAdornment
            label="Reservation Amount"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            InputProps={inputProps}
            maxLength={7}
            disabled={isDisabled}
            isRequired={isRequiredFn(props.dealer)}
            errorText={errorText}
        />
    );
};
export default ReservationAmount;
