import {
    IDealerToManage,
    IFetchDealerAction,
    ManagerActionTypes,
    IUpdateDealerAction,
    IUpdateValidatorAction,
    IValidator,
    ISaveDealerAction,
    IFetchSearchedDealersAction,
    PartnerMapping,
    IPartnerMappingsAction
} from './types';
export const updateValidatorMap = (validator: IValidator): IUpdateValidatorAction => {
    return {
        type: ManagerActionTypes.UPDATE_VALIDATOR,
        payload: validator
    };
};
export const updateDealerToManage = (dealerToManage: IDealerToManage): IUpdateDealerAction => {
    return {
        type: ManagerActionTypes.UPDATE_DEALER_TO_MANAGE,
        payload: { ...dealerToManage }
    };
};
export const fetchSearchedDealers = (dealersRetrieved: IDealerToManage[]): IFetchSearchedDealersAction => {
    return {
        type: ManagerActionTypes.FETCH_ALL_DEALERS,
        payload: { ...dealersRetrieved }
    };
};
export const fetchDealerToManage = (dealerId: number): IFetchDealerAction => {
    return {
        type: ManagerActionTypes.FETCH_DEALER_TO_MANAGE,
        payload: dealerId
    };
};
export const saveDealer = (dealerToManage: IDealerToManage): ISaveDealerAction => {
    return {
        type: ManagerActionTypes.SAVE_DEALER,
        payload: { ...dealerToManage }
    };
};
export const updatePartnerMappings = (partnerMappings: PartnerMapping[]): IPartnerMappingsAction => {
    return {
        type: ManagerActionTypes.UPDATE_PARTNER_MAPPINGS,
        payload: [...partnerMappings]
    };
};
