import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { formatPhoneNumber, validatePhoneNumber, ErrorMessages } from '../common/utils';
import { DR3TextField } from '../common/DR3TextField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        value = formatPhoneNumber(value);
        const smsPhone = value;
        const contactOptions = {
            ...oldDealer.contactOptions,
            smsPhone
        };
        updateDealerCallback(null, {
            ...oldDealer,
            contactOptions
        });
    };
}
function isRequiredFn(dealer: IDealerToManage) {
    return !dealer.contactOptions.smsPhone;
}
const SmsPhone: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.contactOptions.isSmsEnabled) {
        return null;
    }
    let value = props.dealer.contactOptions.smsPhone;
    const isRequired = isRequiredFn(props.dealer);
    let errorText = '';
    value = formatPhoneNumber(value);
    const valid = validatePhoneNumber(value);
    valid ? (errorText = '') : (errorText = 'Phone number must be 10 digits.');
    if (isRequired && !value) {
        errorText = ErrorMessages.MISSING_FIELD;
    }
    return (
        <DR3TextField
            label="SMS Phone"
            sublabel="required"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            errorText={errorText}
            isRequired={isRequiredFn(props.dealer)}
        />
    );
};
export default SmsPhone;
