import { IDR3DealXGClientSearchTableProps } from '../dealxgClientSetup/types';
import React, { FunctionComponent } from 'react';
import MaterialTable from 'material-table';
import { DR3FieldWrapper } from '../common/DR3FieldWrapper';
import { tableIcons } from '../common/utils';
import { Config } from '../../config';
import { Button } from '@material-ui/core';
import { toggleClient } from './api';
import { displayFormatDealXGClients, showDisabledClients, toggleClientData } from './utils';

export const DR3DealXGClientSearchTable: FunctionComponent<IDR3DealXGClientSearchTableProps> = ({
    clients,
    displayClients,
    setClients,
    setDisplayClients,
    searchTableOptions,
    checked,
    auth
}) => {
    async function toggle(clientId: string, disable: boolean): Promise<void> {
        const accessToken = await auth.getAccessToken();
        if (accessToken) {
            const serviceResponse = await toggleClient(accessToken, clientId, disable);
            if (serviceResponse.ok) {
                toggleClientData(clientId, clients, disable, setClients);
                setDisplayClients(showDisabledClients(displayFormatDealXGClients(clients), checked));
            } else {
                alert('Unable to update Client status');
            }
        }
    }
    function enabledText(isChecked: boolean) {
        return isChecked ? 'Disable' : 'Enable';
    }
    function enabledColor(isChecked: boolean) {
        return isChecked ? 'inherit' : 'secondary';
    }

    return (
        <DR3FieldWrapper label={''} isTableData={true}>
            <MaterialTable
                icons={tableIcons}
                title={''}
                columns={[
                    // columns need to be map to props
                    { title: 'Client ID', field: 'clientId' },
                    {
                        title: 'Client Name',
                        field: 'clientName',
                        render: (rowData) => {
                            if (rowData.clientId) {
                                return (
                                    <a
                                        href={`${
                                            Config.services.api.protocol + Config.services.api.host
                                        }/dealxg/client/${rowData.clientId.toString()}/manage`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {rowData.clientName}
                                    </a>
                                );
                            }
                            return null;
                        }
                    },
                    { title: 'Default Application Name', field: 'defaultAppName' },
                    { title: 'Default Persona', field: 'defaultPersona' },
                    { title: 'Webhook URL', field: 'webhookUrl' },
                    { title: 'Webhook HTTP Method', field: 'webhookHttpMethod' },
                    {
                        title: `${enabledText(!checked)} Client`,
                        render: (rowData) => {
                            if (rowData.clientId) {
                                return (
                                    <Button
                                        variant="contained"
                                        color={enabledColor(!rowData.disable)}
                                        onClick={() => toggle(rowData.clientId, !rowData.disable)}
                                    >
                                        {enabledText(!rowData.disable)}
                                    </Button>
                                );
                            }
                            return null;
                        }
                    }
                ]}
                data={displayClients}
                options={searchTableOptions}
            />
        </DR3FieldWrapper>
    );
};
