import React from 'react';
import { FunctionComponent } from 'react';
import {
    IDealerToManage,
    IInputAdornmentProps,
    IUpdateDealerCallbackFn,
    ICommonManagerFieldProps
} from '../../store/manager/types';
import { DR3TextFieldWithAdornment } from '../common/DR3TextFieldWithAdornment';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const downPaymentFixedAmountNew = value;
        const financing = {
            ...oldDealer.financing,
            downPaymentFixedAmountNew
        };
        updateDealerCallback(null, {
            ...oldDealer,
            financing
        });
    };
}

const inputProps: IInputAdornmentProps = {
    position: 'start',
    unit: '$'
};

const DownPaymentFixedAmountNew: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.financing.isDownPaymentFixedAmount) {
        return null;
    }
    const value = props.dealer.financing.downPaymentFixedAmountNew;
    return (
        <DR3TextFieldWithAdornment
            label="Cash Down Value - New Vehicles"
            value={value}
            maxLength={7}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            InputProps={inputProps}
        />
    );
};
export default DownPaymentFixedAmountNew;
