import React, { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3NumberTextField } from '../common/DR3NumberTextField';
import { Box } from '@material-ui/core';

export function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number | string): void => {
        const ownerId = Number(value);
        updateDealerCallback(null, {
            ...oldDealer,
            ownerId
        });
    };
}
const OwnerId: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.ownerId;
    const tooltip = value > 0 ? 'Links below the Gearbox Owner ID will open each relevant section for the dealer' : '';
    return (
        <>
            <DR3NumberTextField
                label="Gearbox Owner ID"
                value={value}
                tooltip={tooltip}
                handleChange={handleChange(props.dealer, props.updateDealerCallback)}
                withMargin={true}
            />
            {tooltip && (
                <Box width="50%" alignItems="right" marginTop="-2%" marginBottom="1%" marginLeft="auto">
                    <a
                        href={`https://fastlane.autotrader.com/freedom/eso/eso-feature-manager.seam?dealerIdParam=${value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: '20px' }}
                    >
                        Fastlane
                    </a>
                    <a
                        href={`https://www.autotrader.com/car-dealers/city+st-00000/${value}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: '20px' }}
                    >
                        ATC Listings
                    </a>
                    <a href={`https://www.kbb.com/dealers/city_vt/dealership/${value}/`} target="_blank" rel="noopener noreferrer">
                        KBB Listings
                    </a>
                </Box>
            )}
        </>
    );
};
export default OwnerId;
