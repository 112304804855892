import React, { FC } from 'react';
import { DealerFieldsSubSubSection } from '../common/DealerFieldsSubSubSection';
import TradeInMethodsCheckboxGroup from './components/TradeinMethodsCheckboxGroup/TradeinMethodsCheckboxGroup';
import { IDealerToManage, IUpdateDealerCallbackFn } from '../../store/manager/types';

type AMDTradeInMethodsContainerProps = {
    updateDealerCallback: IUpdateDealerCallbackFn;
};

export type FieldsInDealerToUpdate = {
    kbbIco: keyof IDealerToManage;
    kbbGuide: keyof IDealerToManage;
    dealerAppraisal: keyof IDealerToManage;
    defaultMethod: keyof IDealerToManage;
};

const AMDTradeInMethodsContainer: FC<AMDTradeInMethodsContainerProps> = (props) => {
    const { updateDealerCallback } = props;

    const shopperAdminFields: FieldsInDealerToUpdate = {
        kbbIco: 'tradeInValuationProviderKBBICO',
        kbbGuide: 'tradeInValuationProviderKBB',
        dealerAppraisal: 'tradeInValuationProviderManual',
        defaultMethod: 'tradeInValuationProviderDefault'
    };

    const salesViewAdminFields: FieldsInDealerToUpdate = {
        kbbIco: 'tradeInValuationProviderKBBICOSalesView',
        kbbGuide: 'tradeInValuationProviderKBBSalesView',
        dealerAppraisal: 'tradeInValuationProviderManualSalesView',
        defaultMethod: 'tradeInValuationProviderDefaultSalesView'
    };

    const handleUpdateAvailableMethods = (fields: FieldsInDealerToUpdate) => (
        dealer2manage: IDealerToManage | null,
        values: any
    ) => {
        if (!dealer2manage) return null;

        const updatedDealer: IDealerToManage = {
            ...dealer2manage,
            [fields.kbbIco]: values.kbbIco || false,
            [fields.kbbGuide]: values.kbbGuide || false,
            [fields.dealerAppraisal]: values.dealerAppraisal || false
        };
        updateDealerCallback(null, updatedDealer);

        return updatedDealer;
    };

    const handleDefaultMethod = (method: keyof IDealerToManage) => (dealer2manage: IDealerToManage | null, value: any) => {
        if (!dealer2manage) return;

        updateDealerCallback(null, {
            ...dealer2manage,
            [method]: value
        });
    };

    const handleShowAppraisalValueScreen = (dealer: IDealerToManage | null, value: boolean) => {
        if (!dealer) return;

        updateDealerCallback(null, {
            ...dealer,
            showAppraisalValueScreenSalesView: value
        });
    };

    return (
        <>
            <DealerFieldsSubSubSection title="AMD Trade-in Methods" />
            <TradeInMethodsCheckboxGroup
                fieldsToUpdate={shopperAdminFields}
                handleUpdateAvailableMethods={handleUpdateAvailableMethods(shopperAdminFields)}
                handleUpdateDefaultMethod={handleDefaultMethod('tradeInValuationProviderDefault')}
            />

            <DealerFieldsSubSubSection title="Sales View Trade-in Methods" />
            <TradeInMethodsCheckboxGroup
                fieldsToUpdate={salesViewAdminFields}
                handleUpdateAvailableMethods={handleUpdateAvailableMethods(salesViewAdminFields)}
                handleUpdateDefaultMethod={handleDefaultMethod('tradeInValuationProviderDefaultSalesView')}
                showAppraisalValueScreen
                handleUpdateAppraisalValueScreen={handleShowAppraisalValueScreen}
            />
        </>
    );
};

export default AMDTradeInMethodsContainer;
