import React, { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, Snackbar, ThemeProvider } from '@material-ui/core';
import { Alert, ClientChangedBy, DealXGClientMap, IDealXGClientManagerByIdProps, defaultAlert } from './types';
import ClientDetailsContainer from './ClientDetails/ClientDetailsContainer';
import WebHookContainer from './WebHook/WebHookContainer';
import ObfuscationContainer from './Obfuscation/ObfuscationContainer';
import NotesContainer from './Notes/NotesContainer';
import { loadDealXGClientById } from './utils';
import withApi from '../../utils/withApi';
import { withAuth } from '@okta/okta-react';
import { HeaderWrapper } from './Header/Header';
import { Config } from '../../config';
import themeOverride from '../layout/theme';
import MySnackbarContentWrapper from '../../containers/components/MySnackbarContentWrapper';
import { saveClient } from './saveClient';
import { DealAccessPermissionsCsv } from './DealAccessPermissions/DealAccessPermissionsCsv';
import DealAccessMappingTable from './DealAccessPermissions/AccessPermissions';

export const DealXGClientByIdManagerContainer: FC<IDealXGClientManagerByIdProps> = (props: IDealXGClientManagerByIdProps) => {
    const [client, setClient] = useState<DealXGClientMap>({} as DealXGClientMap);
    const [isNew, setIsNew] = useState(false as boolean);
    const [updatedBy, setUpdatedBy] = useState<ClientChangedBy>({ user: '', timeStamp: new Date() });
    const [showAlert, setShowAlert] = useState<Alert>(defaultAlert);
    const getClient = async () => {
        const accessToken = await props.auth.getAccessToken();
        if (accessToken) {
            const {
                match: {
                    params: { clientId }
                }
            } = props;

            const isNewClient = clientId === '0';

            setIsNew(isNewClient);

            if (isNewClient) {
                setClient({
                    defaultChangedBy: {
                        persona: 'Shopper'
                    },
                    dealerMap: []
                } as any);
            } else {
                const loadedClient = await loadDealXGClientById(accessToken, clientId);
                setClient(loadedClient);
                const { changedBy = [] } = loadedClient?.meta ?? {};
                if (changedBy.length) {
                    const { user, timeStamp } = changedBy[0];
                    setUpdatedBy({ user, timeStamp: new Date(timeStamp) });
                }
            }
        }
    };

    const save = async () => {
        const accessToken = await props.auth.getAccessToken();
        await saveClient(accessToken, setShowAlert, setClient, client, isNew, setIsNew);
    };

    useEffect(() => {
        getClient();
    }, []);

    const navigateHome = () => {
        window.location.replace(Config.services.api.protocol + Config.services.api.host);
    };

    return (
        <div>
            <HeaderWrapper
                containerClassName={'dealXGClientByIdManagerContainer'}
                updatedBy={updatedBy}
                onClickHome={navigateHome}
                onClickSave={save}
            >
                <Box paddingTop={'6em'} justifyContent="center" alignItems="center">
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <ClientDetailsContainer
                                isNew={isNew}
                                client={client}
                                setClient={setClient}
                                setShowAlert={setShowAlert}
                            />
                            <WebHookContainer isNew={isNew} client={client} setClient={setClient} setShowAlert={setShowAlert} />
                            <NotesContainer isNew={isNew} client={client} setClient={setClient} setShowAlert={setShowAlert} />
                            <ObfuscationContainer isNew={isNew} client={client} setClient={setClient} setShowAlert={setShowAlert} />
                        </Grid>
                    </Grid>
                    <DealAccessPermissionsCsv isNew={isNew} client={client} setClient={setClient} setShowAlert={setShowAlert} />
                    <Grid container justify="center" style={{ marginTop: '20px' }} spacing={3}>
                        <Grid item xs={5}>
                            <DealAccessMappingTable
                                isNew={isNew}
                                client={client}
                                setClient={setClient}
                                setShowAlert={setShowAlert}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </HeaderWrapper>
            {/* ALERTS */}
            <ThemeProvider theme={themeOverride}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    open={showAlert.show}
                    autoHideDuration={6000}
                    onClose={() => {
                        setShowAlert(defaultAlert);
                    }}
                >
                    <MySnackbarContentWrapper
                        variant={showAlert.variant}
                        className={`${showAlert.className} mappingError`}
                        message={showAlert.message}
                        onClose={() => {
                            setShowAlert(defaultAlert);
                        }}
                    />
                </Snackbar>
            </ThemeProvider>
        </div>
    );
};

export default withAuth(withApi(DealXGClientByIdManagerContainer));
