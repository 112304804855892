import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { ErrorMessages, Disclaimers, validateRouteOneDealerId } from '../common/utils';
import { DR3TextField } from '../common/DR3TextField';

let errorText = '';

export function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const routeOnePartyId = value;
        updateDealerCallback(null, {
            ...oldDealer,
            routeOnePartyId
        });
    };
}
function isRequiredFn(dealer: IDealerToManage) {
    return !dealer.routeOnePartyId;
}
const RouteOnePartyId: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    if (props.dealer && !props.dealer.creditProvider) {
        return null;
    }

    if (props.dealer && !(props.dealer.creditProvider === 'r1_dealertrack')) {
        return null;
    }
    const value = props.dealer.routeOnePartyId;
    const isRequired = isRequiredFn(props.dealer);
    const valid = validateRouteOneDealerId(value);
    valid ? (errorText = '') : (errorText = ErrorMessages.ROUTE_ONE_INVALID);
    if (isRequired && !value) {
        errorText = ErrorMessages.MISSING_FIELD;
    }
    return (
        <DR3TextField
            label="RouteOne Party ID"
            sublabel={Disclaimers.ROUTE_ONE}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            errorText={errorText}
            isRequired={isRequiredFn(props.dealer)}
        />
    );
};
export default RouteOnePartyId;
