import React, { FC } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

export const EnableVauto: FC<ICommonManagerFieldProps> = ({ dealer, updateDealerCallback }) => {
    if (!dealer) {
        return null;
    }

    const tooltip = 'Allow Vauto integration';

    const handleChange = (oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) => (value: boolean): void => {
        const newDealer = {
            ...oldDealer,
            enableVauto: value
        };
        updateDealerCallback(null, newDealer);
    };

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.enableVauto}
            value={dealer.enableVauto}
            handleChange={handleChange(dealer, updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
