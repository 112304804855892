import React, { FunctionComponent } from 'react';
import ClientIdField from './ClientId';
import ClientNameField from './ClientName';
import ClientDefaultAppNameField from './ClientDefaultAppName';
import ClientDefaultPersona from './ClientDefaultPersona';
import { IClientContainerProps } from '../types';
import { Grid, Typography } from '@material-ui/core';

const ClientDetailsContainer: FunctionComponent<IClientContainerProps> = (props) => {
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <Typography align="center" gutterBottom variant="h4">
                        Client Setup
                    </Typography>
                </Grid>
            </Grid>
            <ClientIdField {...props} />
            <ClientNameField {...props} />
            <ClientDefaultAppNameField {...props} />
            <ClientDefaultPersona {...props} />
        </div>
    );
};
export default ClientDetailsContainer;
