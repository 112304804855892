import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const TCPAProvider = value;
        let isTCPACheckboxDisabled = false;
        if (oldDealer.TCPAProvider !== 'amsi' && TCPAProvider === 'amsi') {
            isTCPACheckboxDisabled = true;
        }
        const updatedDealer = {
            ...oldDealer,
            isTCPACheckboxDisabled,
            TCPAProvider
        };
        updateDealerCallback(null, updatedDealer);
    };
}

const themeOptions: IDropdownOptions[] = [
    { optionValue: 'custom', optionLabel: 'Custom' },
    { optionValue: 'amsi', optionLabel: 'AMSI' },
    { optionValue: 'bmw', optionLabel: 'BMW' },
    { optionValue: 'genesis', optionLabel: 'Genesis' },
    { optionValue: 'hyundai', optionLabel: 'Hyundai' },
    { optionValue: 'mini', optionLabel: 'MINI' }
];

const TcpaProvider: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.isTCPAEnabled) {
        return null;
    }

    return (
        <DR3DropdownField
            label="Consent Type"
            value={props.dealer.TCPAProvider}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={themeOptions}
        />
    );
};
export default TcpaProvider;
