import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioStringField } from '../common/DR3RadioStringField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const depositType = value;
        const reservation = {
            ...oldDealer.reservation,
            depositType
        };
        const newDealer = {
            ...oldDealer,
            reservation
        };
        updateDealerCallback(null, newDealer);
    };
}

const DepositType: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.reservation.depositType;
    let isDisabled = true;

    if (
        (props.dealer.reservation.showReservationDetail || props.dealer.listingOverride.reservation.showReservationDetail) &&
        props.dealer.reservation.reservationType === 'paidDeposit'
    ) {
        isDisabled = false;
    }

    return (
        <DR3RadioStringField
            trueOption="Refundable"
            falseOption="Non-Refundable"
            label="Deposit Type"
            value={value}
            disabled={isDisabled}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default DepositType;
