import { IDealerCreditTier, IHandleUpdateFieldChange, IDealerCreditTierAPR, IHandleFieldChange } from '../../store/manager/types';
import MaterialTable, { Column, Options } from 'material-table';
import React, { FunctionComponent, useMemo, useRef } from 'react';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { tableIcons } from './utils';

interface IDR3CreditTierTableProps {
    creditTierData: IDealerCreditTier;
    onUpdate: IHandleUpdateFieldChange<IDealerCreditTierAPR>;
    onAdd: IHandleFieldChange<IDealerCreditTierAPR>;
    onDelete: IHandleFieldChange<IDealerCreditTierAPR>;
    label: string | React.ReactElement;
    columns: Column<IDealerCreditTierAPR>[];
    tableOptions: Options<IDealerCreditTierAPR>;
}
export const DR3CreditTierTable: FunctionComponent<IDR3CreditTierTableProps> = (props) => {
    const tableRef = useRef<any>(null);

    // Workaround for table due to a bug in this and higher versions
    // Visit https://github.com/mbrn/material-table/issues/1480 to learn more
    useMemo(() => {
        tableRef?.current?.dataManager?.changePageSize(props.tableOptions.pageSize);
        tableRef?.current?.dataManager?.setData(props.creditTierData.aprs);
    }, [props.tableOptions.pageSize, ...props.creditTierData.aprs.map((a) => a)]);

    return (
        <DR3FieldWrapper label={props.label} creditTier={props.creditTierData.aprs}>
            <MaterialTable
                tableRef={tableRef}
                options={props.tableOptions}
                icons={tableIcons}
                title={props.label}
                columns={props.columns}
                data={props.creditTierData.aprs}
                editable={{
                    onRowUpdate: (newData: any, oldData: any) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                props.onUpdate(newData, oldData);
                            }, 100);
                        }),
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                props.onAdd(newData);
                            }, 100);
                        }),
                    onRowDelete: (oldData: any) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                props.onDelete(oldData);
                            }, 100);
                        })
                }}
            />
        </DR3FieldWrapper>
    );
};
