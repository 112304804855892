import React, { FunctionComponent, useEffect, useState } from 'react';
import { IClientContainerProps } from '../types';
import { ErrorMessages } from '../../common/utils';
import { DR3FieldWrapper } from '../../common/DR3FieldWrapper';
import { ValidationTextField } from '../../common/ValidationTextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import useStyles from '../../layout/styles';

const ClientId: FunctionComponent<IClientContainerProps> = ({ client, isNew, setClient }) => {
    const [clientId, setClientId] = useState('');
    const classes = useStyles({});
    const isRequired = !isNew;
    const errorText = isRequired && !clientId ? ErrorMessages.MISSING_FIELD : '';

    useEffect(() => {
        setClientId(client.clientId);
    }, [client]);

    const handleChange = () => {
        const newClient = {
            ...client,
            clientId
        };
        setClient(newClient);
    };

    return (
        <DR3FieldWrapper label={'Client ID'} sublabel={'required'}>
            <ValidationTextField
                error={!!errorText}
                variant="outlined"
                className={classes.textField}
                required={isRequired}
                disabled={!isNew}
                value={clientId}
                onChange={(event) => {
                    setClientId(event.target.value);
                }}
                onBlur={handleChange}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
export default ClientId;
