import React, { FunctionComponent } from 'react';
import { Alert, IClientContainerProps, IDealerMap } from '../types';
import { DR3DealAccessMappingTable } from './DR3DealAccessMappingTable';
import { isDealerMapIncorrectly, isIntegratorAlreadyOnPartnerMapping } from '../utils';

const DealAccessMappingTable: FunctionComponent<IClientContainerProps> = (props) => {
    const partnerMappingColumn: any = [
        {
            title: 'Partner Name',
            field: 'name',
            editable: 'onAdd'
        },
        {
            title: 'Partner Dealer Id',
            field: 'value'
        }
    ];
    const defaultAlert: Alert = {
        show: true,
        variant: 'warning',
        message: ['Duplicated mapping!'],
        className: 'alertError'
    };
    const incompleteDealer: Alert = {
        show: true,
        variant: 'warning',
        message: ['Icomplete information!'],
        className: 'alertError'
    };
    const handleAddNewPartnerMapping = (newData: IDealerMap) => {
        if (isDealerMapIncorrectly(newData)) {
            props.setShowAlert?.(incompleteDealer);
            return;
        }
        if (isIntegratorAlreadyOnPartnerMapping(newData, props.client.dealerMap)) {
            props.setShowAlert?.(defaultAlert);
            return;
        }

        const tmpPartnerMappings = [...(props.client.dealerMap || [])];
        tmpPartnerMappings.push(newData);
        const client = {
            ...props.client,
            dealerMap: tmpPartnerMappings
        };
        props.setClient(client);
    };
    const handleUpdatePartnerMapping = (newData: IDealerMap, oldData: IDealerMap) => {
        if (isDealerMapIncorrectly(newData)) {
            props.setShowAlert?.(incompleteDealer);
            return;
        }
        if (isIntegratorAlreadyOnPartnerMapping(newData, props.client.dealerMap)) {
            props.setShowAlert?.(defaultAlert);
            return;
        }
        const { dealerMap } = props.client;
        dealerMap.splice(dealerMap.indexOf(oldData), 1, newData);

        const client = {
            ...props.client,
            dealerMap
        };
        props.setClient(client);
    };
    const handleDeletePartnerMapping = (newData: any) => {
        const { dealerMap } = props.client;
        dealerMap.splice(dealerMap.indexOf(newData), 1);

        const client = {
            ...props.client,
            dealerMap
        };

        props.setClient(client);
    };
    const getPartnerMappingTableOptions = () => {
        return {
            pageSize: (props.client.dealerMap || []).length,
            pageSizeOptions: [(props.client.dealerMap || []).length],
            search: false
        };
    };
    return (
        <DR3DealAccessMappingTable
            mappingData={props.client.dealerMap || []}
            columns={partnerMappingColumn}
            onUpdate={(newData, oldData) => handleUpdatePartnerMapping(newData as IDealerMap, oldData as IDealerMap)}
            onAdd={(e) => handleAddNewPartnerMapping(e)}
            onDelete={(e) => handleDeletePartnerMapping(e)}
            label="Deal Access Permissions"
            tableOptions={getPartnerMappingTableOptions()}
        />
    );
};
export default DealAccessMappingTable;
