import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        oldDealer.leasing.mileages.forEach((element) => {
            if (element.mileage.toString() === value) {
                element.isDefault = true;
            }
            if (element.mileage.toString() !== value) {
                element.isDefault = false;
            }
        });
        updateDealerCallback(null, {
            ...oldDealer,
            ...oldDealer.leasing.mileages
        });
    };
}

const LeasingMileageDefaultDropdown: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || !props.dealer.leasing.mileages[0]) {
        return null;
    }
    const options: IDropdownOptions[] = [];
    props.dealer.leasing.mileages = Object.values(props.dealer.leasing.mileages);
    props.dealer.leasing.mileages.forEach((mileages) => {
        const mileage: IDropdownOptions = {
            optionLabel: mileages.mileage.toString(),
            optionValue: mileages.mileage.toString()
        };
        options.push(mileage);
    });

    let value = options[0].optionValue;
    props.dealer.leasing.mileages.forEach((element) => {
        if (element.isDefault) {
            value = element.mileage.toString();
        }
    });
    const mileage = props.dealer.leasing.mileages.findIndex((t) => t.mileage.toString() === value);
    if (!props.dealer.leasing.mileages[mileage].isDefault) {
        props.dealer.leasing.mileages[mileage].isDefault = true;
    }

    return (
        <DR3DropdownField
            label="Mileage - Default"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={options}
        />
    );
};
export default LeasingMileageDefaultDropdown;
