import React from 'react';
import { Component } from 'react';
import { CssBaseline, AppBar, Typography, Toolbar, Container } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import HostedLogin from '../HostedLogin';
import { IDealerToManage } from '../../store/manager/types';
import AdminMenu from '../AdminMenu';
import Button from '@material-ui/core/Button';
import themeOverride from './theme';
import HomeIcon from '@material-ui/icons/Home';
import { TabSelected } from '../../store/manager/types';
import { dateUtils } from '@makemydeal/dr-platform-shared';
import { commonUtils } from '../../utils/commonUtils';
import { isEmpty } from 'lodash';
import './css/styles.scss';

export interface IGlobalWrapperProps {
    dealer: IDealerToManage | null;
    onClickSave?: () => {};
    onClickClone?: () => {};
    onClickHome?: () => void;
    onClickTab?: () => void;
    isDealerManagerPage?: boolean;
    isAccessoryPage?: boolean;
    tabSelected?: TabSelected;
    children: any;
}

export default class GlobalWrapper extends Component<IGlobalWrapperProps, any> {
    constructor(props: any) {
        super(props);
        this.state = { imageHeight: '14px' };
    }

    public handleHomeClick = () => {
        const { onClickHome } = this.props;
        if (!onClickHome) {
            return null;
        }
        onClickHome();
    };

    public handleSaveClick = () => {
        const { onClickSave } = this.props;
        if (!onClickSave) {
            return null;
        }
        onClickSave();
    };

    public handleCloneClick = () => {
        const { onClickClone } = this.props;
        if (!onClickClone) {
            return null;
        }
        onClickClone();
    };

    public handleTabClick = () => {
        const { onClickTab } = this.props;
        if (!onClickTab) {
            return null;
        }
        onClickTab();
    };

    public getDealerInfoString(dealer: IDealerToManage | null, isDealerManagerPage?: boolean) {
        let dealerIdString = '';
        if (isDealerManagerPage && dealer) {
            const dealerId = dealer.dealerId;
            if (dealerId) {
                dealerIdString = `${dealerId}`;
                const dealerName = dealer.name;
                if (dealerName) {
                    dealerIdString += `: ${dealerName}`;
                }
                dealerIdString = this.getDealerSponsorSettingString(dealer, dealerIdString);
            }
        }
        return dealerIdString;
    }

    public getDealerSponsorSettingString(dealer: IDealerToManage, dealerIdString: string): string {
        if (dealer.sponsorSetting) {
            if (dealer.sponsorSetting === 'dealerAndListingSites') {
                return `${dealerIdString} - Website & Listing Sites`;
            }
            if (dealer.sponsorSetting === 'dealerSite') {
                return `${dealerIdString} - Website`;
            }
            if (dealer.sponsorSetting === 'listingSite') {
                return `${dealerIdString} - Listing Site`;
            }
        }
        return dealerIdString;
    }

    public showMenuAndSaveButton(dealer: IDealerToManage | null, isDealerManagerPage?: boolean, isAccessoryPage?: boolean) {
        if (isDealerManagerPage || isAccessoryPage) {
            if (this.props.tabSelected !== undefined) {
                return (
                    <div className="menuAndSave">
                        <Button onClick={this.handleHomeClick} className="button">
                            <HomeIcon></HomeIcon>
                        </Button>
                        {isDealerManagerPage && <AdminMenu dealer={dealer} tabSelected={this.props.tabSelected}></AdminMenu>}
                        <Button onClick={this.handleSaveClick} className="button">
                            Save Changes
                        </Button>
                    </div>
                );
            } else {
                return null;
            }
        }
    }

    public showUpdatedByStatus(dealer: IDealerToManage | null, isDealerManagerPage?: boolean) {
        if (isDealerManagerPage && this.props.tabSelected !== undefined) {
            let lastUpdatedString = 'Last updated';
            let updatedBy = dealer?.updatedBy || '';
            // if updatedBy isn't an email, its invalid.
            if (!commonUtils.validateEmail(updatedBy)) {
                updatedBy = '';
            }
            // if updatedBy is greater than 0, append 'updated by <email>'.
            if (updatedBy.length > 0) {
                lastUpdatedString += ` by ${updatedBy}`;
            }
            // if updatedOn is not emtpy (invalid), 'append on <date>'.
            if (dealer?.updatedOn) {
                lastUpdatedString += ` on ${dateUtils.formatAsMMDDYYYY(new Date(dealer.updatedOn))}`;
            }
            // if both updatedBy and updatedOn are invalid, don't display.
            if (isEmpty(updatedBy) && isEmpty(dealer?.updatedOn)) {
                return null;
            }

            return <div className="updatedBy">{lastUpdatedString}</div>;
        } else {
            return null;
        }
    }

    public getContainerClassName(isDealerManagerPage?: boolean) {
        return isDealerManagerPage ? 'dealerManagerContainer' : 'dealerSearchContainer';
    }

    public render() {
        const { dealer, isDealerManagerPage, children, isAccessoryPage } = this.props;
        const dealerString = this.getDealerInfoString(dealer, isDealerManagerPage);
        let centerText = {
            paddingLeft: '90px',
            paddingRight: '0px'
        };
        if (isDealerManagerPage) {
            centerText = {
                paddingLeft: '0px',
                paddingRight: '160px'
            };
        }

        // TODO: US531791: delete setImageHeight, the constructor of this class, and the onload on the img.
        // Also, replace the accelerate logo dynamic height with a hardcoded 26px.
        const setImageHeight = (event: any) => {
            if (event.target.naturalHeight === 102) {
                this.setState({ imageHeight: '14px' });
            } else {
                this.setState({ imageHeight: '26px' });
            }
        };

        return (
            <ThemeProvider theme={themeOverride}>
                <div className="root">
                    <CssBaseline />
                    <AppBar position="fixed" className="header">
                        <Toolbar>
                            {this.showMenuAndSaveButton(dealer, isDealerManagerPage, isAccessoryPage)}
                            <Typography variant="h6" className="title" style={centerText}>
                                <span
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '1.2rem',
                                        marginLeft: '3px'
                                    }}
                                >
                                    Accelerate My Deal Admin
                                </span>
                                <div>{dealerString}</div>
                                {this.showUpdatedByStatus(dealer, isDealerManagerPage)}
                            </Typography>
                            <HostedLogin />
                        </Toolbar>
                    </AppBar>
                    <main className="content">
                        <Container className={this.getContainerClassName(isDealerManagerPage)}>{children}</Container>
                    </main>
                </div>
            </ThemeProvider>
        );
    }
}
