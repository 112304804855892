import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// define type to handle TS lint issues
const windowObj = window as any;

// case 1: hide REDUX_DEVTOOL from production
// case 2: if we don't have window object (for example at UnitTest) then we use compose
const withReduxDevTools =
    process.env.REACT_NODE_ENV === 'production'
        ? compose
        : (windowObj.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && windowObj.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const reduxMiddleware = withReduxDevTools(applyMiddleware(thunk));
