import React, { useState } from 'react';
import { withAuth } from '@okta/okta-react';
import withApi from '../../utils/withApi';
import { IAuthFn } from '../../utils/AuthTypes';
import { IApi } from '../../utils/Api';
import { Button, Dialog, DialogActions, DialogTitle, Grid, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core';
import MySnackbarContentWrapper from '../../containers/components/MySnackbarContentWrapper';

const useStyles = makeStyles(() => ({
    description: {
        marginBottom: '1rem'
    },
    productionDealerIdInput: {
        minWidth: '12rem'
    }
}));

interface ICopyProductionDealerFormProps {
    auth: IAuthFn;
    api: IApi;
}

const CopyProductionDealerForm = (props: ICopyProductionDealerFormProps) => {
    const classes = useStyles();

    const [prodDealerId, setProdDealerId] = useState('');
    const [requiresConfirmation, setRequiresConfirmation] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('info');

    const checkDealerExists = async (dealerId: number, accessToken: string) => {
        return props.api
            .asyncGetDealer({ dealerId }, accessToken)
            .then(() => true)
            ['catch']((err) => {
                if (err.statusCode === 404) {
                    return false;
                }

                throw err;
            });
    };

    const handleSubmit = async (ev: React.FormEvent) => {
        try {
            ev.preventDefault();

            const productionDealerId = Number(prodDealerId);

            const accessToken = await props.auth.getAccessToken();
            if (!accessToken) {
                throw new Error('Failed to obtain valid access token');
            }

            const dealerExists = await checkDealerExists(productionDealerId, accessToken);
            if (dealerExists) {
                setRequiresConfirmation(true);
                return;
            }

            await props.api.asyncCopyProductionDealer(productionDealerId, accessToken);

            setProdDealerId('');

            setMessageType('success');
            setMessage('Successfully copied dealer');
        } catch (err) {
            setMessageType('error');
            setMessage('Failed to copy dealer');
        }
    };

    const handleDialogConfirm = async () => {
        try {
            setRequiresConfirmation(false);

            const productionDealerId = Number(prodDealerId);

            const accessToken = await props.auth.getAccessToken();
            if (!accessToken) {
                throw new Error('Failed to obtain valid access token');
            }

            await props.api.asyncCopyProductionDealer(productionDealerId, accessToken);

            setProdDealerId('');

            setMessageType('success');
            setMessage('Successfully copied dealer');
        } catch (err) {
            setMessageType('error');
            setMessage('Failed to copy dealer');
        }
    };

    const handleDialogCancel = async () => {
        setRequiresConfirmation(false);
        setProdDealerId('');
    };

    const handleProdDealerIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setMessageType('info');
        setMessage('');
        setProdDealerId(ev.target.value);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography align="left" gutterBottom variant="h5">
                Copy Production Dealer
            </Typography>
            <Typography align="left" className={classes.description}>
                Copy a dealer from the production environment into the current environment. <br />
                If a dealer with the same ID already exists in the current environment it will be overwritten.
            </Typography>
            <Grid container spacing={2}>
                <Grid item>
                    <TextField
                        size="small"
                        variant="outlined"
                        id="prodDealerId"
                        label="Production Dealer ID"
                        value={prodDealerId}
                        onChange={handleProdDealerIdChange}
                        inputProps={{ pattern: '[0-9]+' }}
                        className={classes.productionDealerIdInput}
                        required
                    />
                </Grid>
                <Grid item>
                    <Button type="submit" variant="contained" color="primary">
                        Copy
                    </Button>
                </Grid>
            </Grid>
            <CopyProductionDealerConfirmationDialog
                open={requiresConfirmation}
                onCancel={handleDialogCancel}
                onConfirm={handleDialogConfirm}
            />
            <CopyProductionDealerSnackbar messageType={messageType} message={message} onClose={() => setMessage('')} />
        </form>
    );
};

interface ICopyProductionDealerConfirmationDialog {
    open: boolean;
    onCancel: (ev: any) => void;
    onConfirm: (ev: any) => void;
}

const CopyProductionDealerConfirmationDialog = (props: ICopyProductionDealerConfirmationDialog) => {
    const { open, onCancel, onConfirm } = props;
    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogTitle>Overwrite Existing Dealer?</DialogTitle>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={onCancel} autoFocus>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={onConfirm}>
                    Overwrite
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface ICopyProductionDealerSnackbarProps {
    messageType: string;
    message: string;
    onClose: () => void;
}

const CopyProductionDealerSnackbar = (props: ICopyProductionDealerSnackbarProps) => {
    const { messageType, message, onClose } = props;
    const className = messageType === 'success' ? 'alertSuccess' : 'alertError';

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            open={Boolean(message)}
            autoHideDuration={6_000}
            onClose={onClose}
        >
            <MySnackbarContentWrapper variant={messageType} className={className} message={[message]} onClose={onClose} />
        </Snackbar>
    );
};

export default withAuth(withApi(CopyProductionDealerForm));
