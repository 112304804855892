import React from 'react';
import { FunctionComponent } from 'react';
import {
    IDealerToManage,
    IInputAdornmentProps,
    IUpdateDealerCallbackFn,
    ICommonManagerFieldProps
} from '../../store/manager/types';
import { DR3TextFieldWithAdornment } from '../common/DR3TextFieldWithAdornment';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const downPaymentPercentUsed = value;
        const financing = {
            ...oldDealer.financing,
            downPaymentPercentUsed
        };
        updateDealerCallback(null, {
            ...oldDealer,
            financing
        });
    };
}

const inputProps: IInputAdornmentProps = {
    position: 'start',
    unit: '%'
};

const DownPaymentPercentUsed: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer || props.dealer.financing.isDownPaymentFixedAmount) {
        return null;
    }
    const value = props.dealer.financing.downPaymentPercentUsed;
    return (
        <DR3TextFieldWithAdornment
            label="Cash Down Value Percent - Used Vehicles"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            InputProps={inputProps}
            maxLength={3}
        />
    );
};
export default DownPaymentPercentUsed;
