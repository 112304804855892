// tslint:disable:variable-name
import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { ErrorMessages } from '../common/utils';
import { DR3TextField } from '../common/DR3TextField';

export function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        // Type here cannot be undefined
        const activEngageMmdId = value ? value : '';
        const contactOptions = {
            ...oldDealer.contactOptions,
            activEngageMmdId
        };

        const newDealer = {
            ...oldDealer,
            contactOptions
        };
        updateDealerCallback(null, newDealer);
    };
}
function isRequiredFn(dealer: IDealerToManage) {
    return dealer.contactOptions.isActivEngageEnabled;
}

const tooltip =
    // eslint-disable-next-line max-len
    'This field requires MMD ID, all Active Engage chats will be sent to this location unless the vehicle location is different then the website';

const ActivEngageMmdId: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    const activEngageToggle = props?.dealer?.contactOptions?.isActivEngageEnabled;

    if (!props.dealer || activEngageToggle === false) {
        return null;
    }

    const value = props?.dealer?.contactOptions?.activEngageMmdId;
    const isRequired = isRequiredFn(props.dealer);

    let errorText = '';
    if (isRequired && !value) {
        errorText = ErrorMessages.MISSING_FIELD;
    }
    return (
        <DR3TextField
            label="ActivEngage MMD ID"
            sublabel="required"
            value={value}
            isRequired={isRequired}
            errorText={errorText}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltip={tooltip}
        />
    );
};
export default ActivEngageMmdId;
