import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { ErrorMessages } from '../common/utils';
import { DR3TextAreaField } from '../common/DR3TextAreaField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const accountToken = value;
        const reservation = {
            ...oldDealer.reservation,
            accountToken
        };
        updateDealerCallback(null, {
            ...oldDealer,
            reservation
        });
    };
}
function isRequiredFn(dealer: IDealerToManage) {
    return !dealer.reservation.accountToken;
}
const AccountToken: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    let isDisabled = true;

    if (
        (props.dealer.reservation.showReservationDetail || props.dealer.listingOverride.reservation.showReservationDetail) &&
        props.dealer.reservation.reservationType === 'paidDeposit'
    ) {
        isDisabled = false;
    }

    const subLabel = !isDisabled ? 'required' : '';
    const value = props.dealer.reservation.accountToken;
    const isRequired = isRequiredFn(props.dealer);
    let errorText = '';
    if (isRequired && !value && isDisabled === false) {
        errorText = ErrorMessages.MISSING_FIELD;
    }
    if (isDisabled) {
        errorText = ErrorMessages.MISSING_WARNING;
    }
    if (isDisabled && props.dealer.reservation.reservationType === 'paidDeposit') {
        errorText = '';
    }
    return (
        <DR3TextAreaField
            label="Vantiv Account Token"
            sublabel={subLabel}
            value={value}
            disabled={isDisabled}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            multiline={true}
            rowsMax={5}
            errorText={errorText}
            isRequired={isRequiredFn(props.dealer)}
        />
    );
};
export default AccountToken;
