import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const hasFi = value;
        const products = {
            ...oldDealer.products,
            hasFi
        };
        const newDealer = {
            ...oldDealer,
            products
        };
        updateDealerCallback(null, newDealer);
    };
}

const toolTip =
    'Setting "Is Vehicle Protection Active?" to "Yes" will apply all settings under "Vehicle Protection" ' +
    'in the Global Settings tab to dealer sites.';

const VehicleProtectionActive: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = !!props.dealer.products.hasFi;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.hasFi}
            tooltipProps={toolTip}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default VehicleProtectionActive;
