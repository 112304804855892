import request from 'request-promise';
import axios from 'axios';
import {
    ICloneDealerResponse,
    IGetDealerResponse,
    IGetSearchedDealersReponse,
    ISaveDealerResponse,
    IDealerToManage,
    ICreateDealerResponse,
    IUploadBrandingExpResponse,
    PartnerMapping,
    Integrator
} from '../store/manager/types';
import { Config } from '../config';
import { AtcToggleData } from '../constants/AdminConstants';
import splunkLogger from '../utils/splunkLogger';
import { downloadFileService } from './downloadFileService';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fetch = require('node-fetch');

interface ICloneDealerRequest {
    dealerId: number;
}
interface IGetDealerRequest {
    dealerId: number;
}
interface ISaveDealerRequest {
    dealer: IDealerToManage;
}
export interface IUploadBrandingExpRequest {
    dealer: IDealerToManage;
}

interface IDealerPartnersMapping {
    integratorId: string;
    partnerDealerId: string;
}

export interface IApi {
    asyncCloneDealer(props: ICloneDealerRequest, accessToken: string): Promise<IGetDealerResponse>;
    asyncCopyProductionDealer(productionDealerId: number, accessToken: string): Promise<any>;
    asyncGetDealer(props: IGetDealerRequest, accessToken: string): Promise<IGetDealerResponse>;
    asyncGetDealerToggleMetrics(toggles: any, accessToken?: string): Promise<any>;
    asyncGetSearchedDealers(accessToken: string, urlQueryAddition: string): Promise<IGetSearchedDealersReponse>;
    asyncSaveDealer(props: IDealerToManage, accessToken: string): Promise<ISaveDealerResponse>;
    asyncSaveDealerATC(props: IDealerToManage, accessToken: string): Promise<ISaveDealerResponse>;
    asyncCreateDealer(props: IDealerToManage, accessToken: string): Promise<ICreateDealerResponse>;
    asyncUploadBrandingExp(
        dealerId: IUploadBrandingExpRequest,
        accessToken: string,
        imageList: FileList
    ): Promise<IUploadBrandingExpResponse>;
    asyncStartHowItWorksUploadVideo(dealerId: number, accessToken: string): Promise<any>;
    asyncStartHowItWorksUploadPoster(dealerId: number, accessToken: string): Promise<any>;
    asyncRemoveHowItWorksContent(dealerId: number, accessToken: string, url: string): Promise<any>;
    generateAccessoriesUploadURL(fileName: string, accessToken: string): Promise<string>;
    uploadAccessories(uri: string, file: File): Promise<any>;
    asyncGenerateDownloadAccessoriesTemplateSignedURL(accessToken: string): Promise<string>;
    asyncDownloadFile(uri: string, fileName: string): Promise<any>;
    asyncGetIntegratorsList(accessToken: string): Promise<Integrator[]>;
    asyncGetDealerPartnerMappings(dealerId: number, accessToken: string): Promise<PartnerMapping[]>;
    asyncSaveDealerPartnersMapping(
        dealerId: number,
        accessToken: string,
        partenerMappings: PartnerMapping[]
    ): Promise<PartnerMapping[]>;
    asyncGetFeatureToggles(dealerId: string, accessToken: string): Promise<any>;
}

const basePath = Config.services.api.protocol + Config.services.api.host + Config.services.api.base;

class Api implements IApi {
    public async asyncCopyProductionDealer(productionDealerId: number, accessToken: string): Promise<IGetDealerResponse> {
        const basePath = Config.services.api.protocol + Config.services.api.host + Config.services.api.base;
        const uri = `${basePath}utilities/copy-production-dealer`;
        const response: IGetDealerResponse = await request({
            uri,
            body: {
                productionDealerId
            },
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response;
    }
    public async asyncCloneDealer(props: IGetDealerRequest, accessToken: string): Promise<IGetDealerResponse> {
        const basePath = Config.services.api.protocol + Config.services.api.host + Config.services.api.base;
        const uri = `${basePath}dealers/${props.dealerId}/clone`;
        const response: ICloneDealerResponse = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response;
    }
    public async asyncGetDealer(props: IGetDealerRequest, accessToken: string): Promise<IGetDealerResponse> {
        const basePath = Config.services.api.protocol + Config.services.api.host + Config.services.api.base;
        const uri = `${basePath}dealers/${props.dealerId}/manage`;
        const response: IGetDealerResponse = await request({
            uri,
            method: 'GET',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response;
    }
    public async asyncGetDealerToggleMetrics(toggles: any, accessToken?: string): Promise<any> {
        const basePath = Config.services.api.protocol + Config.services.api.host;
        const uri = `${basePath}/metrics/dealersWithToggles`;
        const response: any = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            body: { toggles }
        });
        return response;
    }
    public async asyncGetSearchedDealers(accessToken: string, urlQueryAddition: string): Promise<any> {
        const uri = `${basePath}dealers${urlQueryAddition}`;
        const response: IGetDealerResponse = await request({
            uri,
            method: 'GET',
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response;
    }
    public async asyncSaveDealer(dealer: IDealerToManage, accessToken: string): Promise<ISaveDealerResponse> {
        const nullResponse: ISaveDealerResponse = { statusCode: 0, error: '', message: '', dealerId: '' };
        if (!dealer) {
            return nullResponse;
        }
        const uri = `${basePath}dealers/${dealer.dealerId}`;
        const response: ISaveDealerResponse = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            body: dealer
        });
        return response;
    }
    public async asyncSaveDealerATC(dealer: IDealerToManage | null, accessToken: string): Promise<ISaveDealerResponse> {
        const nullResponse: ISaveDealerResponse = { statusCode: 0, error: '', message: '', dealerId: '' };
        if (!dealer) {
            return nullResponse;
        }
        const uri = `${basePath}dealer/${dealer.ownerId}`;
        const response: ISaveDealerResponse = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            body: {
                ownerId: dealer.ownerId,
                modifiedBy: dealer.name,
                productId: AtcToggleData.PRODUCT_ID,
                source: AtcToggleData.SOURCE,
                hasWidgetNew:
                    dealer.sponsorSetting === 'dealerSite'
                        ? dealer.products.hasWidgetNew
                        : dealer.listingOverride.products.hasWidgetNew,
                hasWidgetUsed:
                    dealer.sponsorSetting === 'dealerSite'
                        ? dealer.products.hasWidgetUsed
                        : dealer.listingOverride.products.hasWidgetUsed,
                attributes: []
            }
        });
        return response;
    }
    public async asyncCreateDealer(dealer: IDealerToManage | null, accessToken: string): Promise<ICreateDealerResponse> {
        const nullResponse: ICreateDealerResponse = { _id: null, dealerId: 0 };
        if (!dealer) {
            return nullResponse;
        }
        const uri = `${basePath}dealers`;
        const response: ICreateDealerResponse = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            body: dealer
        });
        return response;
    }

    public async asyncUploadBrandingExp(
        dealer: IUploadBrandingExpRequest,
        accessToken: string,
        imageList: FileList
    ): Promise<IUploadBrandingExpResponse> {
        let imageInfo: IUploadBrandingExpResponse = {
            Bucket: '',
            Key: '',
            Location: ''
        };
        if (!accessToken) {
            return imageInfo;
        }
        const url = `${basePath}dealers/${dealer.dealer.dealerId}/uploadBrandingExperience`;
        const upload = new FormData();
        upload.append('file', imageList[0], imageList[0].name);
        const requestOptions = {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            body: upload
        };
        await fetch(url, requestOptions).then(async (res: any) => {
            try {
                const json = await res.json();
                if (json.Bucket && json.Key && json.Location) {
                    imageInfo = {
                        Bucket: json.Bucket,
                        Key: json.Key,
                        Location: json.Location
                    };
                }
            } catch (e) {
                splunkLogger.log(
                    `Error JSON parsing response from AWS s3 --- branding experience upload for dealer
        ${dealer.dealer.dealerId} Error: ${e}`,
                    ''
                );
            }
        });
        return imageInfo;
    }

    public async asyncStartHowItWorksUploadVideo(dealerId: number, accessToken: string): Promise<any> {
        const uri = `${basePath}dealers/${dealerId}/startHowItWorksVideoUpload`;
        const response: any = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response;
    }

    public async asyncStartHowItWorksUploadPoster(dealerId: number, accessToken: string): Promise<any> {
        const uri = `${basePath}dealers/${dealerId}/startHowItWorksPosterUpload`;
        const response: any = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response;
    }

    public async asyncRemoveHowItWorksContent(dealerId: number, accessToken: string, url: string): Promise<any> {
        const uri = `${basePath}dealers/${dealerId}/removeHowItWorksContent`;
        const response: any = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            body: {
                url
            }
        });
        return response;
    }

    public async generateAccessoriesUploadURL(fileName: string, accessToken: string): Promise<string> {
        const uri = `${basePath}accessories/generate-upload-url`;
        const response: any = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            body: {
                fileName
            }
        });
        return response.signedUrl;
    }

    public async uploadAccessories(uri: string, file: File): Promise<void> {
        await axios.put(uri, file, {
            headers: {
                'Content-Type': 'text/csv'
            }
        });
    }

    public async asyncGenerateDownloadAccessoriesTemplateSignedURL(accessToken: string): Promise<string> {
        const uri = `${basePath}accessories/generate-download-template-url`;
        const response: any = await request({
            uri,
            method: 'GET',
            json: true,
            body: {},
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.downloadTemplateSignedUrl;
    }

    public async asyncDownloadFile(fileName: string, url: string): Promise<any> {
        const response: any = await fetch(url, {
            method: 'GET'
        });
        await downloadFileService.downloadFile(response, fileName);
    }

    public async asyncGetIntegratorsList(accessToken: string): Promise<Integrator[]> {
        const uri = `${basePath}partners`;
        const response = await request({
            uri,
            method: 'GET',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.map((entry: { code: string; displayName: string }) => {
            return {
                integratorId: entry.code,
                integratorName: entry.displayName
            };
        });
    }

    public async asyncGetDealerPartnerMappings(dealerId: number, accessToken: string): Promise<PartnerMapping[]> {
        const uri = `${basePath}dealers/${dealerId}/partnersMapping`;
        const response: IDealerPartnersMapping[] = await request({
            uri,
            method: 'GET',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        const processedResponse = response.map((partnerMapping) => {
            const mapping: PartnerMapping = {
                integratorId: partnerMapping.integratorId,
                partnerDealerId: partnerMapping.partnerDealerId,
                status: 'original'
            };
            return mapping;
        });
        return processedResponse;
    }

    public async asyncSaveDealerPartnersMapping(
        dealerId: number,
        accessToken: string,
        partenerMappings: PartnerMapping[]
    ): Promise<PartnerMapping[]> {
        const uri = `${basePath}dealers/${dealerId}/partnersMapping`;
        const response: any = await request({
            uri,
            method: 'POST',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            body: {
                data: partenerMappings
            }
        });
        // finish return flow
        return response;
    }

    public async asyncGetFeatureToggles(dealerId: string, accessToken: string) {
        const uri = `${basePath}feature-toggles?dealerId=${dealerId}`;
        return request({
            url: uri,
            method: 'GET',
            json: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
    }
}

export default Api;
