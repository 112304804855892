import { handleChange as handleRouteOneChange } from '../dealerSiteFields/RouteOnePartyId';
import { handleChange as handleDRSAChange } from '../dealerSiteFields/OverrideDRSAccountId';
import { handleChange as handleDealerTrackOverrideChange } from '../dealerSiteFields/DealertrackOverrideId';
import { handleChange as handleVinSolutionsChange } from '../dealerSiteFields/VinSolutionsCRMIntegrationId';
import { handleChange as handleActivEngageChange } from '../dealerSiteFields/ActivEngageMmdId';
import { handleChange as handleDnaChange } from '../dealerSiteFields/DNAAccountId';
import { handleChange as handleSVOCChange } from '../dealerSiteFields/GearboxOwnerId';
import { handleChange as handleDealerTrackChange } from '../dealerSiteFields/DealerTrackID';
import { IDealerToManage, IUpdateDealerCallbackFn } from '../../store/manager/types';

export const getHandleChangeForId = (
    integratorId: string,
    dealer: IDealerToManage | null,
    updateDealerCallback: IUpdateDealerCallbackFn
) => {
    if (!dealer) return undefined;

    switch (integratorId) {
        case 'dnaAccountId':
            return handleDnaChange(dealer, updateDealerCallback);

        case 'svocId':
            return handleSVOCChange(dealer, updateDealerCallback);

        case 'dealerTrackID':
            return handleDealerTrackChange(dealer, updateDealerCallback);

        case 'dealertrackIdOverride':
            return handleDealerTrackOverrideChange(dealer, updateDealerCallback);

        case 'overrideDRSAccountId':
            return handleDRSAChange(dealer, updateDealerCallback);

        case 'routeOnePartyId':
            return handleRouteOneChange(dealer, updateDealerCallback);

        case 'vinSolutionsAccountId':
            return handleVinSolutionsChange(dealer, updateDealerCallback);

        case 'activEngageMmdId':
            return handleActivEngageChange(dealer, updateDealerCallback);

        case 'esntialPartnerId':
            return (value: string) => updateDealerCallback(null, { ...dealer, esntialPartnerId: value });

        case 'esntialPartnerDealerId':
            return (value: number) => updateDealerCallback(null, { ...dealer, esntialPartnerDealerId: value });

        case 'commonOrgId':
            return (value: string) => updateDealerCallback(null, { ...dealer, commonOrgId: value });
    }
};
