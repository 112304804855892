import { IDealerCreditTier } from '../types';
import { asSafeCreditTierAPR } from './asSafeCreditTierAPR';
import { asSafeBoolean, asSafeEmptyString, asSafeNumber } from './selectorUtils';

export default function asSafeCreditTier(creditTiers?: IDealerCreditTier[]): IDealerCreditTier[] {
    if (!creditTiers) {
        const defaultCreditTiers: IDealerCreditTier[] = [
            {
                aprs: asSafeCreditTierAPR(),
                isDefault: asSafeBoolean(),
                _id: asSafeEmptyString(),
                highScore: asSafeNumber(),
                legacyId: asSafeNumber(),
                lowScore: asSafeNumber(),
                name: asSafeEmptyString(),
                type: asSafeEmptyString()
            }
        ];
        return defaultCreditTiers;
    }

    const dealerCreditTiersArray: IDealerCreditTier[] = [];

    creditTiers.forEach((element) => {
        element.aprs = asSafeCreditTierAPR(element.aprs);
        element.isDefault = asSafeBoolean(element.isDefault);
        element._id = asSafeEmptyString(element._id);
        element.highScore = asSafeNumber(element.highScore);
        element.lowScore = asSafeNumber(element.lowScore);
        element.legacyId = asSafeNumber(element.legacyId);
        element.name = asSafeEmptyString(element.name);
        element.type = asSafeEmptyString(element.type);

        dealerCreditTiersArray.push(element);
    });

    return dealerCreditTiersArray;
}
