import { Tooltip, withStyles } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip';

export const CustomTooltip: React.ComponentType<TooltipProps> = withStyles(() => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: '#353535',
        maxWidth: 450,
        fontSize: '.9rem',
        border: '1px solid #dadde9'
    }
}))(Tooltip);
