import { createMuiTheme } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const themeOverride = createMuiTheme({
    palette: {
        secondary: blue
    },
    overrides: {
        MuiTableCell: {
            root: {
                padding: '12px 16px 12px 14px',
                wordWrap: 'break-word',
                whiteSpace: 'normal'
            }
        },
        // Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            label: {
                // Some CSS
                fontSize: '.8rem'
            },
            root: {
                fontSize: '.8rem'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '57px !important'
            }
        },
        MuiAppBar: {
            root: {
                zIndex: 12 // still above the scrollbar, but above the MTableHeader (10)
            }
        },
        MuiList: {
            root: {
                background: 'white'
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: '.9rem'
            }
        },
        MuiTypography: {
            h4: {
                fontSize: '1.7rem'
            },
            h5: {
                fontSize: '1.4rem'
            },
            h6: {
                fontSize: '.9rem !important'
            },
            body1: {
                fontSize: '.9rem'
            },
            subtitle2: {
                fontSize: '.75rem'
            }
        },
        MuiInputBase: {
            input: {
                height: '.9rem',
                fontSize: '.9rem'
            }
        },
        MuiFormControl: {
            root: {
                width: '100%'
            }
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: 'ignore',
                width: '355px',
                position: 'absolute',
                margin: '90px 0px',
                zIndex: 15
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: 'ignore'
            }
        },
        MuiDialog: {
            paper: {
                backgroundColor: '#FFFFFF'
            }
        },
        MuiTablePagination: {
            root: {
                visibility: 'hidden',
                height: '0px'
            }
        },
        // Make Branding Experience dropzone preview not squished
        MuiGrid: {
            'grid-xs-4': {
                maxWidth: '100%',
                flexBasis: '100%',
                textAlignLast: 'left'
            }
        }
    }
});

export default themeOverride;
