import React, { FunctionComponent } from 'react';
import { IHandleFieldChange } from '../../store/manager/types';
import { Paper, Tabs, Tab } from '@material-ui/core';
import useStyles from '../../components/layout/styles';
import { SEARCH_TAB_LABELS } from '../../store/manager/types';

interface IDealerManagerTabsProps {
    currentTab: SEARCH_TAB_LABELS;
    onTabChange: IHandleFieldChange<any>;
    currentTabValue: any;
    showUtilities: boolean;
    showdealxgClientSetup: boolean;
}

const SearchContainerTabs: FunctionComponent<IDealerManagerTabsProps> = ({
    onTabChange,
    children,
    currentTab,
    currentTabValue,
    showUtilities,
    showdealxgClientSetup
}) => {
    const classes = useStyles({});
    return (
        <div>
            <Paper className={`${classes.tabs} dealerManagerTabs`}>
                <Tabs value={currentTabValue} onChange={onTabChange} indicatorColor="primary" textColor="primary" centered>
                    <Tab
                        id="Dealer Search"
                        label="Dealer Search"
                        selected={currentTab === SEARCH_TAB_LABELS.DEALER_SEARCH}
                        value={SEARCH_TAB_LABELS.DEALER_SEARCH}
                    />
                    <Tab
                        id="Dealer Metrics"
                        label="Dealer Metrics"
                        selected={currentTab === SEARCH_TAB_LABELS.DEALER_METRICS}
                        value={SEARCH_TAB_LABELS.DEALER_METRICS}
                    />
                    {showUtilities && (
                        <Tab
                            id="Utilities"
                            label="Utilities"
                            selected={currentTab === SEARCH_TAB_LABELS.UTILITIES}
                            value={SEARCH_TAB_LABELS.UTILITIES}
                        />
                    )}
                    {showdealxgClientSetup && (
                        <Tab
                            id="DealXG Client Setup"
                            label="DealXG Client Setup"
                            selected={currentTab === SEARCH_TAB_LABELS.DEALXG_CLIENT_SETUP}
                            value={SEARCH_TAB_LABELS.DEALXG_CLIENT_SETUP}
                        />
                    )}
                </Tabs>
            </Paper>
            {children}
        </div>
    );
};

export default SearchContainerTabs;
