/* eslint-disable max-len */
import fileSaver from 'file-saver';

import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from '@material-ui/icons';
import { Icons } from 'material-table';
import React, { forwardRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const selectn = require('selectn');

export const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export function correctPercentage(value: string) {
    if (!value) {
        return '';
    }

    if (parseInt(value, 10) >= 100) {
        return '100';
    } else {
        return value;
    }
}
export function validateIsNumber(stringValue: string, maxLength?: number, type?: string) {
    let isValid = null;
    if (type === '%') {
        isValid = stringValue.match('(^$)|(^[0-9]*[0-9][0-9]*$)');
        if (isValid) {
            return correctPercentage(stringValue);
        }
    }
    if (!maxLength) {
        return stringValue.match('(^$)|(^[0-9]*[0-9][0-9]*$)');
    } else {
        const regexString = `(^$)|(^[0-9]{0,${maxLength}}$)`;
        return stringValue.match(regexString);
    }
}

export function validatePhoneNumber(value: string, isRequired?: boolean | true) {
    if (isRequired) {
        if (value === undefined || value === '') {
            return false;
        }
        return value.length === 12;
    } else {
        if (value === undefined || value === '') {
            return true;
        }
        return value.length === 12 || value.length === 0;
    }
}

export function formatPhoneNumber(value: string) {
    if (value === undefined || value === '') {
        return value;
    }
    const oldValue = value;
    let val = oldValue.replace(/\D/g, '');
    const length = val.length;
    if (length >= 3) {
        if (oldValue.length !== 3) {
            val = `${val.substring(0, 3)}-${val.substring(3)}`;
        }
    }
    if (length >= 6) {
        if (oldValue.length !== 7) {
            val = `${val.substring(0, 7)}-${val.substring(7)}`;
        }
    }
    if (val !== oldValue) {
        value = val;
    }
    if (length > 10) {
        // removes all characters after 10 digits
        value = val.slice(0, -length + 10);
    }
    return value;
}

export function validateURL(value: string) {
    // eslint-disable-next-line max-len
    const regExp = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
    return regExp.test(value.trim());
}

export function validateZipCode(value: string) {
    if (value === undefined || value === '') {
        return false;
    }
    return value.length === 5;
}

export function validateDNAAccountID(value: string) {
    const regExp = /^[a-z0-9]*$/i;
    return regExp.test(value);
}

export function validateEsntialPartnerId(value: string) {
    if (value === undefined || value === '') {
        return true;
    }

    const regExp = /^[a-z0-9]{3}$/i;
    return regExp.test(value);
}

export function validateRouteOneDealerId(value: string) {
    if (value === '' || value === undefined) return true;

    const regExp = /^[A-Z]{2}[0-9][A-Z]{2}$/;
    return regExp.test(value);
}

export function validateCommonOrgID(value: string) {
    const regExp = /^[a-z0-9_-]*$/i;
    return regExp.test(value);
}

export const convertToCamelCase = (str: string) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number): string => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
};

export const getDealerKeys = (obj: any, prefix = ''): any =>
    Object.keys(obj).reduce((res: any, el) => {
        if (Array.isArray(obj[el])) {
            return res;
        } else if (typeof obj[el] === 'object' && obj[el] !== null) {
            return [...res, ...getDealerKeys(obj[el], `${prefix + el}.`)];
        } else {
            return [...res, prefix + el];
        }
    }, []);

export const filterDealerKeys = (keys: string[], dealer: any) => {
    return keys.filter((key: string) => {
        return typeof selectn(key, dealer) === 'boolean';
    });
};

export const getShortToggleName = (longToggleName: string) => {
    const toggleKeyArr = longToggleName.split('.');
    return toggleKeyArr[toggleKeyArr.length - 1];
};

export const createDealerKeyToggleMap = (keys: string[]) => {
    return keys.reduce((keyMap: any, key) => {
        keyMap[getShortToggleName(key)] = {
            fullKeyPath: key,
            toggleState: true,
            selected: false,
            useBoolean: true,
            toggleValue: null
        };
        return keyMap;
    }, {});
};
export const getLongKeyList = (keyToggleMap: any) => {
    return Object.keys(keyToggleMap).map((key) => {
        return keyToggleMap[key].fullKeyPath;
    });
};

export const getDealerKeyToggleMap = (dealer: any) => {
    const dealerKeys = getDealerKeys(dealer);
    const filteredKeys = filterDealerKeys(dealerKeys, dealer);
    return createDealerKeyToggleMap(filteredKeys);
};

export const filterDealerKeyToggleMap = (dealerToggleMap: any) => {
    const filteredKeys: string[] = Object.keys(dealerToggleMap).filter((key) => {
        return dealerToggleMap[key].selected;
    });
    return filteredKeys.reduce((map: any, key: string) => {
        const { fullKeyPath, toggleState, toggleValue, useBoolean } = dealerToggleMap[key];
        map[fullKeyPath] = useBoolean ? toggleState : toggleValue;
        return map;
    }, {});
};

export const saveFile = (fileText: string, fileName: string) => {
    const blob = new Blob([fileText], {
        type: 'application/csv;charset=utf8'
    });
    fileSaver.saveAs(blob, fileName);
};

export const generateDealerMetricsCsv = (matchedDealers: any, partnersData: any[]): string => {
    const headerFields = [
        'Dealer ID',
        'Dealer Name',
        'Dealer Track ID',
        'DNA Account ID',
        'Dealer Track MMD Override ID',
        'GearBox Owner ID',
        'Live Person Account Id',
        'Ebiz Account ID',
        'Partner ID',
        'PD Profile ID',
        'RouteOne ID',
        'Vin Solutions ID',
        'FNI Version',
        'Active Engage MMD ID'
    ];

    const partners: string[] = [];

    partnersData.map((partnerData) => {
        partnerData.code !== 'TST' && partners.push(partnerData.code);
    });

    headerFields.push(...partners);

    const csvHeader = `${headerFields.join()}\n`;
    const csvBody: string = matchedDealers
        .map((dealer: any) => {
            const NOT_AVAILABLE = 'N/A';
            const {
                dealerId = NOT_AVAILABLE,
                dealerTrackID = NOT_AVAILABLE,
                dnaAccountId = NOT_AVAILABLE,
                name = NOT_AVAILABLE,
                ownerId = NOT_AVAILABLE,
                overrideDRSAccountId = NOT_AVAILABLE,
                partnerId = NOT_AVAILABLE,
                pdProfileId = NOT_AVAILABLE,
                routeOnePartyId = NOT_AVAILABLE,
                useFnI2,
                partnerMappings
            } = dealer;
            const livePersonAccountId = dealer?.contactOptions?.livePersonAccountId
                ? dealer?.contactOptions?.livePersonAccountId
                : NOT_AVAILABLE;
            const activEngageMmdId = dealer?.contactOptions?.activEngageMmdId
                ? dealer?.contactOptions?.activEngageMmdId
                : NOT_AVAILABLE;
            const vinSolutionsAccountId = dealer?.crmIntegration?.vinSolutionsAccountId
                ? dealer?.crmIntegration?.vinSolutionsAccountId
                : NOT_AVAILABLE;
            const dealerTrackMMDOverrideId = dealer?.leadRoutingOverrideSettings?.dealertrackOverrideId
                ? dealer?.leadRoutingOverrideSettings?.dealertrackOverrideId
                : NOT_AVAILABLE;
            const fniVersion = useFnI2 ? '2.0' : '1.0';

            const headerValues = [
                dealerId,
                `"${name}"`,
                dealerTrackID,
                dnaAccountId,
                dealerTrackMMDOverrideId,
                ownerId,
                livePersonAccountId,
                overrideDRSAccountId,
                partnerId,
                pdProfileId,
                routeOnePartyId,
                vinSolutionsAccountId,
                fniVersion,
                activEngageMmdId
            ];

            // dynamic partnerMappings selection and verification
            delete dealer.partnerMappings;
            partners.map((partner) => {
                if (!partnerMappings) {
                    headerValues.push(NOT_AVAILABLE);
                } else {
                    const matchingIndex = partnerMappings.findIndex((partnerMapping: { name: string; value: string }) => {
                        return partnerMapping.name === partner;
                    });
                    headerValues.push(matchingIndex === -1 ? NOT_AVAILABLE : partnerMappings[matchingIndex].value);
                }
            });

            return `${headerValues.join(',')}\n`;
        })
        .join('');
    return csvHeader + csvBody;
};

export const ErrorMessages = {
    MISSING_FIELD: 'This field is required',
    MISSING_WARNING: 'This value is only available on "Reservation Type" "Paid Deposit"',
    AMOUNT_WARNING: '"Reservation Amount" defaults to 0 on "Reservation Type" "Vehicle Hold"',
    ROUTE_ONE_INVALID: 'The value is not a valid RouteOne Party Id.'
};

export const Disclaimers = {
    ROUTE_ONE:
        'Disclaimer: The RouteOne Integration form must be completed with setup confirmation from RouteOne before using this functionality.'
};

export const getNewLineText = (decompose: any): string => {
    // eslint-disable-next-line react/jsx-key
    return decompose.split('\n').map((str: string) => <p>{str}</p>);
};

export const uploadFileToS3 = async (presignedPostData: any, file: any, progressCb: (progress: number) => void) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach((key) => {
            formData.append(key, presignedPostData.fields[key]);
        });
        formData.append('Content-Type', file.type);
        formData.append('file', file);

        const xhr = new XMLHttpRequest();
        let lastProgress = 0;
        let progress = 0;
        xhr.upload.addEventListener(
            'progress',
            function (evt) {
                progress = Math.round((evt.loaded * 100.0) / (evt.total || 1));

                if (lastProgress !== progress) {
                    lastProgress = progress;
                    progressCb(progress);
                }
            },
            false
        );
        xhr.addEventListener(
            'load',
            function () {
                if (this.status.toString().indexOf('2') !== 0) {
                    reject(`Failed to upload : ${this.status}`);
                    return;
                }
                resolve();
            },
            false
        );
        xhr.addEventListener(
            'error',
            function () {
                reject('Error uploading file');
            },
            false
        );
        xhr.addEventListener(
            'abort',
            function () {
                reject('Upload aborted');
            },
            false
        );
        xhr.open('POST', presignedPostData.url, true);
        xhr.send(formData);
    });
};

export const getDateWithoutTime = (date: Date): Date => {
    return new Date(date.toDateString());
};
