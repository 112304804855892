import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3TriButtonField } from '../common/DR3TriButtonField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const applyTaxesAndFeesNewOptions = value;
        const newDealer = {
            ...oldDealer,
            applyTaxesAndFeesNewOptions
        };
        updateDealerCallback(null, newDealer);
    };
}

const ApplyTaxesAndFeesNewOptions: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.applyTaxesAndFeesNewOptions || 'excludeTaxesAndFees'; // default to false value

    return (
        <DR3TriButtonField
            trueOption="Include Taxes and State Fees"
            falseOption="Include Taxes but Exclude State Fees"
            thirdOption="Exclude Taxes and State Fees"
            label={ToggleLabelMap.applyTaxesAndFees}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            dealer={props.dealer}
        />
    );
};
export default ApplyTaxesAndFeesNewOptions;
