import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3TextAreaField } from '../common/DR3TextAreaField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const shortCustomDisclaimer = value;
        updateDealerCallback(null, {
            ...oldDealer,
            shortCustomDisclaimer
        });
    };
}
const tooltip = 'Formatting will not be applied on live site.';
const ShortCustomDisclaimer: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }
    const value = props.dealer.shortCustomDisclaimer;
    return (
        <DR3TextAreaField
            label="Global Short Dealer Disclaimer"
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            multiline={true}
            rowsMax={5}
            isRequired={false}
            tooltip={tooltip}
        />
    );
};
export default ShortCustomDisclaimer;
