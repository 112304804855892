import { IDealerLeadRoutingOverrideSettings } from '../types';
import { asSafeBoolean, asSafeNumber } from './selectorUtils';

export function asSafeLeadRoutingOverrideSettings(
    dealerOverride?: IDealerLeadRoutingOverrideSettings
): IDealerLeadRoutingOverrideSettings {
    if (!dealerOverride) {
        const defaultDealerSettings: IDealerLeadRoutingOverrideSettings = {
            leadRoutingOverrideEnabled: asSafeBoolean(),
            dealertrackOverrideId: asSafeNumber()
        };
        return defaultDealerSettings;
    }
    const overrideSettings: IDealerLeadRoutingOverrideSettings = {
        leadRoutingOverrideEnabled: asSafeBoolean(dealerOverride.leadRoutingOverrideEnabled),
        dealertrackOverrideId: asSafeNumber(dealerOverride.dealertrackOverrideId)
    };
    return overrideSettings;
}
