import { IDealerCash } from '../types';
import { asSafeBoolean } from './selectorUtils';

export function asSafeDealerCash(dealerCash?: IDealerCash): IDealerCash {
    if (!dealerCash) {
        const defaultCash: IDealerCash = {
            calculateOnMSRP: asSafeBoolean()
        };
        return defaultCash;
    }
    const cash: IDealerCash = {
        calculateOnMSRP: asSafeBoolean(dealerCash.calculateOnMSRP)
    };
    return cash;
}
