import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { ErrorMessages } from '../common/utils';
import { DR3NullableNumberTextField } from '../common/DR3NullableNumberTextField';
import { VinSolutionsCRMToggle } from '../../../src/constants/AdminConstants';

export function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: number): void => {
        const vinSolutionsAccountId = value ? value : undefined;
        const crmIntegration = {
            ...oldDealer.crmIntegration,
            vinSolutionsAccountId
        };
        const newDealer = {
            ...oldDealer,
            crmIntegration
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip = 'This is VinSolutions unique Dealer identifier, this value is a number, i.e: 6210';

const VinSolutionsCRMIntegrationId: FunctionComponent<ICommonManagerFieldProps> = (props: any) => {
    const vinSolutionsToggle = props.dealer?.crmIntegration?.vinSolutionsCrmToggle;
    if (!props.dealer || vinSolutionsToggle === undefined || vinSolutionsToggle === VinSolutionsCRMToggle.OFF) {
        return null;
    }

    const value = props.dealer.crmIntegration?.vinSolutionsAccountId;

    let errorText = '';
    if (!value) {
        errorText = ErrorMessages.MISSING_FIELD;
    }
    return (
        <DR3NullableNumberTextField
            label="VinSolutions Account ID"
            sublabel="required"
            value={value}
            isRequired={true}
            errorText={errorText}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltip={tooltip}
        />
    );
};
export default VinSolutionsCRMIntegrationId;
