import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const testDriveServiceProvider = value;
        updateDealerCallback(null, {
            ...oldDealer,
            testDriveServiceProvider
        });
    };
}

const themeOptions: IDropdownOptions[] = [
    { optionValue: 'Default', optionLabel: 'Default Test Drive Experience' },
    { optionValue: 'Sonic', optionLabel: 'Sonic/SIMS Test Drive Experience' }
];

const TestDriveServiceProvider: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    return (
        <DR3DropdownField
            label="Test Drive Service Provider"
            value={props.dealer.testDriveServiceProvider}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={themeOptions}
        />
    );
};
export default TestDriveServiceProvider;
